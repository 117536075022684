<div class="app-container" (onResize)="handleResize($event)">
  @if (data$ | async) {
    <router-outlet></router-outlet>
  }

  @if(isLoading$ | async) {
    <ng-template #loadingDialog>
      <mat-dialog-content>
        <div style="display: flex; justify-content: center; align-items: center; padding: 24px 48px;">
          <md-circular-progress indeterminate></md-circular-progress>
        </div>
      </mat-dialog-content>
    </ng-template>
  }
</div>
