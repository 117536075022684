<div class="summary-container">
    <div class="summary-core">
        <div class="summary-header">
            <span>{{orderVerboseName}} Summary</span>
        </div>
    </div>
    <table class="mat-table mat-elevation-z0">

      <tr>
        <td>Order Item Subtotal</td>
        <td>
          {{ (orderTotals.itemsTotals?.subTotal ?? 0) | number: '1.0-2' | currency:'USD':'symbol' }}
        </td>
      </tr>

      <tr class="color-green">
        <td>Total {{orderVerboseName}} Discount Savings</td>
        <td>
          -{{ (orderTotals.itemsTotals?.discountTotal ?? 0) | number: '1.0-2' | currency:'USD':'symbol' }}
        </td>
      </tr>

      <tr>
        <td>Order Subtotal</td>
        <td>
          {{
            (orderTotals.itemsTotals.subTotal ?? 0)
            - (orderTotals.itemsTotals.discountTotal ?? 0) | number: '1.0-2' | currency:'USD':'symbol'
          }}
        </td>
      </tr>

      @for (discount of orderTotals.itemsTotals.couponDiscounts; track discount.entityId) {
        <tr class="color-green">
          <td>
            <span>Coupon: </span>
            <a href="javascript:void(0);"
              (click)="isProcessing ? null : clickCoupon.emit()"
              [ngClass]="{'disabled': isProcessing}"
            >{{discount?.formattedDescription ?? ''}}</a>
          </td>

          <td>
            -{{(discount?.amount ?? 0) | number: '1.0-2' | currency:'USD':'symbol' }}
          </td>
        </tr>
      }

      <tr>
        <td>Order Total</td>
        <td>
          {{ (orderTotals.subTotal ?? 0) | number: '1.0-2' | currency:'USD':'symbol' }}
        </td>
      </tr>

      @for (tax of orderTotals.taxTotals.taxes; track tax.entityId) {
        <tr>
          <td>
            Estimated Tax: ({{tax.description}})
          </td>

          <td>
            {{ tax.amount | number: '1.0-2' | currency:'USD':'symbol' }}
          </td>
        </tr>
      }

      <tr>
        <td>
          Shipping: {{ shippingDescription ? '(' + shippingDescription + ')' : '' }}
          @if (shippingPostcode) {
            to
            <a href="javascript:void(0);"
              (click)="isProcessing ? null : clickShipping.emit()"
              [ngClass]="{'disabled': isProcessing}">
              {{shippingPostcode}}

              @if((isActive || isProcessing) && useNextime && order.estimatedDeliveryDate) {
                on {{ order.estimatedDeliveryDate | dateFormat: 'MMM d' : siteSettings.utcOffset }}
              }
            </a>
          }
        </td>
        <td>
          {{ orderTotals.shippingTotals.subTotal?.amount ?? 0 | number: '1.0-2' | currency:'USD':'symbol' }}
        </td>
      </tr>

      @for (discount of orderTotals.shippingTotals.couponDiscounts; track discount.entityId) {
        <tr class="color-green">
          <td>
            <span>Shipping Coupon:</span>
            <a href="javascript:void(0);"
              (click)="isProcessing ? null : clickCoupon.emit()"
              [ngClass]="{'disabled': isProcessing}" >
              {{discount?.formattedDescription ?? ''}}
            </a>
          </td>

          <td>
            -{{(discount?.amount ?? 0) | number: '1.0-2' | currency:'USD':'symbol' }}
          </td>
        </tr>
      }

      <tr class="bold">
        <td>
          <div>Total</div>
          <a href='javascript:void(0);'
            (click)="isProcessing ? null : clickPayment.emit()"
            [ngClass]="{'disabled': isProcessing}">
            {{ order.paymentMethod?.description ?? '' }}
          </a>
        </td>
        <td>
          {{ (orderTotals.total ?? 0) | number: '1.0-2' | currency:'USD':'symbol' }}
        </td>
      </tr>

      <!-- <tr  *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.class ?? undefined"></tr> -->

    </table>
</div>
