import '@material/web/button/text-button';
import '@material/web/icon/icon';
import '@material/web/iconbutton/icon-button';

import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import {
  catchError,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { errorSnackBar, RemappedOmit, successSnackBar } from '../utils';
import { SiteSettingsService } from './../services/site-settings.service';
import { DeliveryService } from '../services/delivery.service';
import { AddProductCardComponent } from '../web-components/add-product-card/add-product-card.component';
import { QPilotScheduledOrder } from '../web-components/types/scheduled-order';
import { QPilotProduct } from '../web-components/types/product';
import {
  AddProductEvent,
  UpdateOrderItemQuantityEvent,
  ChangeCyclesEvent,
} from '../web-components/types/events';
import { QPilotScheduledOrderItem } from '../web-components/types/scheduled-order-item';

@Component({
  selector: 'app-add-product',
  standalone: true,
  imports: [AsyncPipe, AddProductCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './add-product.component.html',
  styleUrl: './add-product.component.scss',
})
export class AddProductComponent {
  order$: Observable<QPilotScheduledOrder>;
  product$: Observable<QPilotProduct>;
  quantityToAdd = 1;
  orderVerboseName: string;

  @Input() productId: string;

  constructor(
    private qPilotCloudApiService: QPilotCloudApiService,
    private siteSettingsService: SiteSettingsService,
    private deliveryService: DeliveryService,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.order$ = this.dataService.getCurrentOrder();
    this.product$ = this.dataService.getProduct(this.productId);
    this.orderVerboseName = this.siteSettingsService.orderVerboseName;
  }

  onAdd({ orderId, quantityToAdd }: AddProductEvent, product: QPilotProduct) {
    const orderItem: RemappedOmit<QPilotScheduledOrderItem, 'id'> = {
      scheduledOrderId: orderId,
      productId: parseInt(product.id),
      price: product.price,
      salePrice: product.salePrice,
      quantity: quantityToAdd,
    };

    this.qPilotCloudApiService
      .createScheduledOrderItem(orderItem)
      .pipe(
        tap((data) => {
          if (this.siteSettingsService.useNextime) {
            this.deliveryService.setShouldFetchNextOccurrenceDate(false);
          }

          this.dataService.addItemToOrder(
            orderId,
            data[0],
            this.siteSettingsService.useNextime
          );
        }),
        tap(() => {
          successSnackBar(this.snackBar, 'Product added');
          this.dialog.closeAll();
        }),
        catchError(() => {
          errorSnackBar(this.snackBar, 'Failed: Error adding Product');
          return of(null);
        })
      )
      .subscribe();
  }

  onAddOneTime(
    { orderId, quantityToAdd }: AddProductEvent,
    product: QPilotProduct
  ) {
    const orderItem: Omit<QPilotScheduledOrderItem, 'id'> = {
      scheduledOrderId: orderId,
      productId: parseInt(product.id),
      price: product.price,
      salePrice: product.salePrice,
      quantity: quantityToAdd,
      minCycles: 0,
      maxCycles: 1,
    };

    this.qPilotCloudApiService
      .createScheduledOrderItem(orderItem)
      .pipe(
        tap((data) => {
          if (this.siteSettingsService.useNextime) {
            this.deliveryService.setShouldFetchNextOccurrenceDate(false);
          }

          this.dataService.addItemToOrder(
            orderId,
            data[0],
            this.siteSettingsService.useNextime
          );
        }),
        tap(() => {
          successSnackBar(this.snackBar, 'Product added as One-Time Only');
          this.dialog.closeAll();
        }),
        catchError(() => {
          errorSnackBar(this.snackBar, 'Failed: Error adding Product');
          return of(null);
        })
      )
      .subscribe();
  }

  onUpdate({
    orderId,
    orderItemId,
    quantityToUpdate,
  }: UpdateOrderItemQuantityEvent) {
    this.order$
      .pipe(
        take(1),
        map((order: QPilotScheduledOrder) =>
          order.scheduledOrderItems?.find(
            (item: QPilotScheduledOrderItem) => item.id == orderItemId
          )
        ),
        map((item) => {
          const updateData: QPilotScheduledOrderItem = {
            ...JSON.parse(JSON.stringify(item)),
            quantity: quantityToUpdate,
          };
          return updateData;
        }),
        switchMap((updateData) =>
          this.qPilotCloudApiService.updateScheduledOrderItem(updateData).pipe(
            tap(() => {
              if (this.siteSettingsService.useNextime) {
                this.deliveryService.setShouldFetchNextOccurrenceDate(false);
              }

              this.dataService.updateItemOfOrder(
                orderId,
                updateData,
                this.siteSettingsService.useNextime
              );
            }),
            tap(() => {
              successSnackBar(this.snackBar, 'Product updated');
              this.dialog.closeAll();
            }),
            catchError(() => {
              errorSnackBar(this.snackBar, 'Failed: Error updating Product');
              return of(null);
            })
          )
        )
      )
      .subscribe();
  }

  onChangeCycles({ orderId, orderItemId, cycles }: ChangeCyclesEvent) {
    let orderItem: QPilotScheduledOrderItem | undefined;
    this.order$
      .pipe(
        tap(
          (order: QPilotScheduledOrder) =>
            (orderItem = order.scheduledOrderItems?.find(
              (item: QPilotScheduledOrderItem) => item.id == orderItemId
            ))
        )
      )
      .subscribe();

    const updateData: QPilotScheduledOrderItem = {
      ...JSON.parse(JSON.stringify(orderItem)),
      ...(cycles === 'OneTime'
        ? { minCycles: 0, maxCycles: 1 }
        : { minCycles: null, maxCycles: null }),
    };

    this.qPilotCloudApiService
      .updateScheduledOrderItem(updateData)
      .pipe(
        map(() => updateData),
        tap((orderItem) =>
          this.dataService.updateItemOfOrder(orderId, orderItem)
        ),
        tap(() => {
          successSnackBar(
            this.snackBar,
            cycles === 'OneTime'
              ? 'Changed to One-Time Only'
              : 'Changed to Recurring'
          );
        }),
        catchError(() => {
          errorSnackBar(this.snackBar, 'Failed: Error updating cycle');
          return of(null);
        }),
        finalize(() => this.dialog.closeAll())
      )
      .subscribe();
  }

  goBack() {
    this.router.navigate([{ outlets: { dialog: ['add-products'] } }], {
      skipLocationChange: true,
    });
  }
}
