import { Overlay } from '@angular/cdk/overlay';
import { DecimalPipe, NgClass } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { LoaderDotsComponent } from '../loader-dots/loader-dots.component';
import { NextimeWrapperComponent } from '../nextime-wrapper/nextime-wrapper.component';
import { DateFormatPipe } from '../pipes/date-format-pipe';
import {
  ScheduledOrderDetailsStatusComponent,
} from '../scheduled-order-details-status/scheduled-order-details-status.component';
import {
  getAddressDisplayName,
  getChangeLimitDate,
  getFailedReason,
  getStatusDisplayName,
  QPilotScheduledOrder,
  QPilotScheduledOrderTotals,
} from '../types/scheduled-order';
import { ProcessNowDialogComponent } from './../process-now-dialog/process-now-dialog.component';
import { QPilotSiteSettings } from './../types/site';

@Component({
  selector: 'lib-scheduled-order-details',
  standalone: true,
  imports: [
    DecimalPipe,
    DateFormatPipe,
    NgClass,
    MatIcon,
    MatDialogModule,
    ProcessNowDialogComponent,
    NextimeWrapperComponent,
    LoaderDotsComponent,
    ScheduledOrderDetailsStatusComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scheduled-order-details.component.html',
  styleUrl: './scheduled-order-details.component.scss',
})
export class ScheduledOrderDetailsComponent implements OnChanges {
  @Input() order: QPilotScheduledOrder & { closestNextOccurrenceDate?: string };
  @Input() orderTotals$: Observable<QPilotScheduledOrderTotals>;
  @Input() orderVerboseName: string;
  @Input() orderVerboseNamePlural: string;
  @Input() nextOccurrenceVerboseName: string;
  @Input() alignActionsToRight = true;
  @Input() nextimeToken: string;
  @Input() nextimeSiteId: number;
  @Input() loadingTimeline: boolean;
  @Input() useNextime: boolean;
  @Input() attachNextimeDialogTo: string | undefined;
  @Input() siteSettings: QPilotSiteSettings;
  @Input() closestNextOccurrenceDate: string | undefined;
  @Input() closestDeliveryDateUtc: string | undefined;

  @Output() pause = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() resume = new EventEmitter();
  @Output() rescheduleAndResume = new EventEmitter();
  @Output() clickReschedule = new EventEmitter();
  @Output() processOrder = new EventEmitter();
  @Output() changeFrequency = new EventEmitter();
  @Output() clickAddProducts = new EventEmitter();
  @Output() clickPaymentMethod = new EventEmitter();
  @Output() clickShippingAddress = new EventEmitter();
  @Output() deliveryDateUpdate = new EventEmitter();
  @Output() processNowDeliveryDateFetched = new EventEmitter();

  @ViewChild(NextimeWrapperComponent) nextime: NextimeWrapperComponent;

  dialogRef: MatDialogRef<any>;
  processingStatusList = ['processing', 'queued'];
  isProcessing: boolean;
  statusDisplayName: string;
  timelineJustifyCenter = false;

  constructor(private dialog: MatDialog, private overlay: Overlay) {}

  ngOnChanges() {
    const { status, processingErrorCode } = this.order;
    const isFailed = status === 'failed';
    const statusDisplayName = getStatusDisplayName(this.order);

    this.statusDisplayName = isFailed
      ? `${statusDisplayName} (${getFailedReason(processingErrorCode)})`
      : statusDisplayName;

    this.isProcessing = this.processingStatusList.includes(status);

    this.timelineJustifyCenter = this.isProcessing || [
      !!this.order.lastOccurrenceUtc,
      !!this.order.nextOccurrenceUtc,
      this.useNextime && !!this.order.estimatedDeliveryDate
    ].filter(x => x).length == 1;
  }

  onPause() {
    this.pause.emit({
      orderId: this.order.id,
    });
  }

  onCancel() {
    this.cancel.emit({
      orderId: this.order.id,
    });
  }

  onResume() {
    this.resume.emit({
      orderId: this.order.id,
    });
  }

  onRescheduleAndResume() {
    this.rescheduleAndResume.emit({
      orderId: this.order.id,
    });
  }

  onClickAddProducts() {
    this.clickAddProducts.emit();
  }

  onClickReschedule() {
    this.clickReschedule.emit();
  }

  onClickShippingAddress() {
    this.clickShippingAddress.emit();
  }

  onChangeFrequency() {
    this.changeFrequency.emit({
      orderId: this.order.id,
    });
  }

  onClickPaymentMethod() {
    this.clickPaymentMethod.emit();
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(templateRef, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
  }

  onProcessOrder() {
    this.processOrder.emit({
      orderId: this.order.id,
    });
    this.dialogRef.close();
  }

  onProcessNowDeliveryDateFetched(event: Event) {
    this.processNowDeliveryDateFetched.emit(event)
  }

  async openDeliveryDateDialog() {
    await this.nextime.openDeliveryDateDialog();
  }

  onDeliveryDateUpdate(event: Event) {
    this.deliveryDateUpdate.emit(event);
  }

  get changeLimitDate() {
    return getChangeLimitDate(this.order);
  }

  get addressDisplayName() {
    return getAddressDisplayName(this.order);
  }

  get hasDeliveryDate() {
    return !!this.order.estimatedDeliveryDate?.trim();
  }
}
