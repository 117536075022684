import { CdkColumnDef } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SafeHtml } from '@angular/platform-browser';

import { DateFormatPipe } from '../pipes/date-format-pipe';
import { QPilotScheduledOrder, QPilotScheduledOrderTotals } from '../types/scheduled-order';
import { QPilotSiteSettings } from './../types/site';

export interface SummaryViewData {
  slug: string;
  description: string | SafeHtml;
  value: string;
  class?: string;
}

@Component({
  selector: 'lib-scheduled-order-summary',
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    CdkColumnDef,
    DateFormatPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scheduled-order-summary.component.html',
  styleUrl: './scheduled-order-summary.component.scss',
})
export class ScheduledOrderSummaryComponent implements OnChanges {
  @Input() order: QPilotScheduledOrder;
  @Input() orderTotals: QPilotScheduledOrderTotals;
  @Input() orderVerboseName: string;
  @Input() useNextime: boolean;
  @Input() siteSettings: QPilotSiteSettings;
  @Output() updateTotals = new EventEmitter();
  @Output() clickShipping = new EventEmitter();
  @Output() clickCoupon = new EventEmitter();
  @Output() clickPayment = new EventEmitter();

  displayedColumns: string[];
  dataSource: SummaryViewData[];
  isProcessing: boolean;
  isActive: boolean;
  shippingDescription: string | undefined;
  shippingPostcode: string | undefined;

  constructor() {}

  ngOnChanges() {
    this.isProcessing = ['processing', 'queued'].includes(this.order.status);
    this.isActive = this.order.status == 'active';
    this.shippingDescription = this.orderTotals?.shippingTotals?.subTotal?.description;
    this.shippingPostcode = this.order.shippingPostcode;
  }
}
