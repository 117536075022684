@if (viewPortService.viewPort$ | async; as viewPort) {
<div
  [ngClass]="{
    details: true,
    'medium-screen': viewPort == 'medium',
    'large-screen': viewPort == 'large'
  }"
>
  @if(order$ | async; as order) { @if(isProcessing$ | async; as isProcessing) {
  <div class="screen-control left">
    <div class="navigation">
      <md-icon-button (click)="goBack()" slot="trailing-icon">
        <md-icon>arrow_back</md-icon>
      </md-icon-button>
      <span> Back </span>
    </div>

    <div class="order-details-core">
      <lib-scheduled-order-details
        [order]="order"
        [orderTotals$]="orderTotals$"
        [orderVerboseName]="orderVerboseName"
        [orderVerboseNamePlural]="orderVerboseNamePlural"
        [nextOccurrenceVerboseName]="nextOccurrenceVerboseName"
        [alignActionsToRight]="alignActionsToRight"
        [useNextime]="
          !deliveryService.isIgnored(order.id) && siteSettingsService.useNextime
        "
        [nextimeToken]="siteSettingsService.nextimeToken"
        [nextimeSiteId]="siteSettingsService.nextimeSiteId"
        [loadingTimeline]="deliveryService.isLoading"
        [siteSettings]="siteSettingsService.siteSettings"
        [closestNextOccurrenceDate]="closestNextOccurrenceDate"
        [closestDeliveryDateUtc]="order.closestNextDeliveryDate"
        (pause)="openDialog('pause-order', true)"
        (cancel)="openDialog('cancel-order', true)"
        (resume)="onResumeOrder($event)"
        (processOrder)="onProcessOrder(order)"
        (changeFrequency)="openDialog('change-frequency', false)"
        (clickAddProducts)="openDialogAddProducts()"
        (clickReschedule)="openDialog('reschedule-order', false)"
        (rescheduleAndResume)="
          openDialog('reschedule-order', true, { resumeAfter: true })
        "
        (clickPaymentMethod)="openDialog('update-payment', true)"
        (clickShippingAddress)="openDialog('shipping-address', true)"
        (deliveryDateUpdate)="
          deliveryService.onDeliveryDateUpdate($event, order)
        "
        (processNowDeliveryDateFetched)="
          updateClosestDeliveryDate($event, order)
        "
      />
    </div>

    <div class="order-details-products">
      <div class="products-header">
        <span class="items-count">
          @if (itemsCount$ | async; as itemsCount) { ({{ itemsCount }})
          {{ itemsCount === 1 ? "Item" : "Items" }}
          }
        </span>

        <a
          href="javascript:void(0);"
          [ngClass]="{ disabled: isProcessing.value }"
          (click)="isProcessing.value ? null : openDialogAddProducts()"
        >
          Add Product
        </a>
      </div>

      @for (item of order.scheduledOrderItems; track item.id) {
      <lib-scheduled-order-details-product
        [orderItem]="item"
        [orderVerboseName]="orderVerboseName"
        [orderStatus]="order.status"
        [siteSettings]="siteSettingsService.siteSettings"
        (updateQuantity)="onUpdateQuantity($event, item)"
        (deleteOrderItem)="onDeleteOrderItem($event)"
        (editOrderItem)="onEditOrderItem(item)"
        (changeQuantity)="onChangeQuantity($event)"
      />

      <div class="separator"></div>
      }

      <div class="products-actions">
        <md-outlined-button
          [disabled]="isProcessing.value"
          (click)="isProcessing.value ? null : openDialogAddProducts()"
        >
          <span slot="icon" class="btn-icon material-symbols-outlined"
            >add</span
          >
          Add Product
        </md-outlined-button>
      </div>
    </div>
  </div>

  <div class="screen-control right">
    <div class="order-details-payment-method">
      <div class="payment-method-header">
        <span class="title">My Payment Methods</span>
        <a
          href="javascript:void(0);"
          [ngClass]="{ disabled: isProcessing.value }"
          (click)="
            isProcessing.value ? null : openDialog('update-payment', true)
          "
        >
          Update Payment
        </a>
      </div>

      <div class="payment-method-content">
        @if(order.paymentMethod) {
        <lib-scheduled-order-payment-method
          [orderStatus]="order.status"
          [paymentMethod]="order.paymentMethod"
          [scheduledOrderId]="order.id"
          [currentPaymentMethod]="true"
          (changePayment)="openDialog('update-payment', true)"
        />
        }
      </div>
    </div>

    <div class="order-details-shipping">
      <lib-scheduled-order-shipping
        [order]="order"
        [useNextime]="
          !deliveryService.isIgnored(order.id) && siteSettingsService.useNextime
        "
        [siteSettings]="siteSettingsService.siteSettings"
        (changeShippingAddress)="openDialog('shipping-address', true)"
        (changeDeliveryDate)="openDeliveryDateDialog()"
      />
    </div>

    @if(orderTotals$ | async; as orderTotals) { @if (showCoupons) {
    @if(discounts$ | async; as discounts) {
    <div class="order-details-coupons">
      <div class="coupons-header">
        <span class="title">Coupons</span>
        <a
          href="javascript:void(0);"
          [ngClass]="{ disabled: isProcessing.value }"
          (click)="
            isProcessing.value
              ? null
              : openDialog('update-coupons', true, { discounts: discounts })
          "
        >
          Add Coupon
        </a>
      </div>

      <div class="coupons-list">
        @for (coupon of discounts; track coupon.entityId) {
        <lib-scheduled-order-coupon
          class="coupon"
          [coupon]="coupon"
          [orderVerboseName]="orderVerboseName"
          (deleteCoupon)="onDeleteCoupon($event, order)"
        />
        }
      </div>
    </div>
    } }

    <div class="order-details-summary">
      <lib-scheduled-order-summary
        [order]="order"
        [orderTotals]="orderTotals"
        [orderVerboseName]="orderVerboseName"
        [useNextime]="
          !deliveryService.isIgnored(order.id) && siteSettingsService.useNextime
        "
        [siteSettings]="siteSettingsService.siteSettings"
        (clickCoupon)="openDialog('update-coupons', true)"
        (clickShipping)="openDialog('shipping-address', true)"
        (clickPayment)="openDialog('update-payment', true)"
      />
    </div>
    }
  </div>
  } }
</div>
}
