import { CurrencyPipe, DecimalPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { QPilotScheduledOrder } from '../types/scheduled-order';
import { DateFormatPipe } from '../pipes/date-format-pipe';
import { QPilotSiteSettings } from '../types/site';
import { BarTagComponent } from '../bar-tag/bar-tag.component';

@Component({
  selector: 'lib-scheduled-order-shipping',
  standalone: true,
  imports: [
    NgClass,
    DateFormatPipe,
    DecimalPipe,
    CurrencyPipe,
    BarTagComponent,
    DateFormatPipe,
  ],
  providers: [
    DecimalPipe,
    CurrencyPipe,
    DateFormatPipe,
  ],
  templateUrl: './scheduled-order-shipping.component.html',
  styleUrl: './scheduled-order-shipping.component.scss'
})
export class ScheduledOrderShippingComponent implements OnChanges {

  @Input() order: QPilotScheduledOrder;
  @Input() useNextime: boolean;
  @Input() siteSettings: QPilotSiteSettings;
  @Output() changeShippingAddress = new EventEmitter();
  @Output() changeDeliveryDate = new EventEmitter();

  processingStatusList = ['processing', 'queued'];
  isProcessing: boolean;
  isActive: boolean;
  shippingTotal: string | null | undefined;
  shippingTag: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private dateFormatPipe: DateFormatPipe,
  ) { }

  ngOnChanges() {
    this.isProcessing = this.processingStatusList.includes(this.order.status);
    this.isActive = this.order.status == 'active';
    const decimalShipping = this.decimalPipe.transform(this.order.shippingTotal, '1.0-2');
    this.shippingTotal = this.currencyPipe.transform(decimalShipping, 'USD', 'symbol');

    const deliveryDate = this.dateFormatPipe.transform(
      this.order.estimatedDeliveryDate,
      'MMM d',
      this.siteSettings.utcOffset
    );
    this.shippingTag = `${this.order.shippingRateName ?? ''} ${
      deliveryDate ? '(Delivery ' + deliveryDate + ')' : ''
    }`.trim();
  }

  onChangeShippingAddress() {
    this.changeShippingAddress.emit();
  }

  onChangeDeliveryDate() {
    this.changeDeliveryDate.emit();
  }
}
