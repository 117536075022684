<div class="add-product-wrapper">

  <div class="navigation">
    <md-icon-button (click)="goBack()" slot="trailing-icon">
      <md-icon>arrow_back</md-icon>
    </md-icon-button>
    <span> Back </span>
  </div>

  @if (order$ | async; as order) {
    @if (product$ | async; as product) {
    <lib-add-product-card
      [product]="product"
      [order]="order"
      [scheduledOrderVerboseName]="orderVerboseName"
      (add)="onAdd($event, product)"
      (addOneTime)="onAddOneTime($event, product)"
      (update)="onUpdate($event)"
      (changeCycles)="onChangeCycles($event)"
    />
    }
  }
</div>
