<lib-bar-tag
  [orderStatus]="orderStatus"
  [className]="currentPaymentMethod ? 'payment active' : 'payment'"
  [icon]="'credit_card'"
  [fillIcon]="false"
  [text]="paymentMethod == null ? 'No Payment Method' : paymentMethod.description ?? ''"
  [actionName]="actionName"
  [isActionClickable]="actionCallback != undefined ? true : false"
  (clickedAction)="actionCallback != undefined ? actionCallback() : null"
/>

<ng-template #applyDialog>
    <h2 matDialogTitle>Update your payment method</h2>
    <mat-dialog-content>
        <p>Apply {{ paymentMethod?.description }} as your payment method to your {{ orderVerboseName }}?</p>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: flex-end;">
        <md-text-button form="form-id" value="cancel" matDialogClose>Cancel</md-text-button>
        <md-text-button form="form-id" value="process" (click)="applyAndProcess()">
          {{ orderStatus == 'failed' ? 'Update and Retry Processing' : 'Update and Process Now' }}
        </md-text-button>
        <md-text-button form="form-id" value="confirm" (click)="onApplyPayment()">Confirm</md-text-button>
    </mat-dialog-actions>
</ng-template>
