import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { QPilotScheduledOrder } from '../types/scheduled-order';
import { AddressHelper } from '../country-select/addressHelper';

@Component({
  selector: 'lib-shipping-address',
  standalone: true,
  imports: [
    MatCardModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './shipping-address.component.html',
  styleUrl: './shipping-address.component.scss'
})
export class ShippingAddressComponent {

  @Input() order: QPilotScheduledOrder;
  @Output() editAddress = new EventEmitter();

  constructor(
    private addressHelper: AddressHelper,
  ) { }

  get countryDisplayName() {
    return this.addressHelper.countries.find(
      country => country.alpha2 === this.order.shippingCountry
    )?.name ?? this.order.shippingCountry;
  }

  get fullName() {
    let fullName = '';

    if (this.order.shippingFirstName) fullName += this.order.shippingFirstName;
    if (this.order.shippingLastName) fullName += ` ${this.order.shippingLastName}`;

    return fullName.trim();
  }

  onEditAddress() {
    this.editAddress.emit();
  }
}
