@if (formIsValid$ | async; as formIsValid) {
  <div class="survey-wrapper">
    <h3>{{ survey.question }}</h3>

    <div class="choices" [formGroup]="form">
      <mat-radio-group formControlName="chosenAnswerId">
        @for (answer of survey.answers; track answer.id) {
          <mat-radio-button [value]="answer.id">
            {{answer.answer}}
          </mat-radio-button>

          @if (form.value.chosenAnswerId == answer.id && answer.type) {
            <div class="comment-container">
              <mat-form-field>
                <mat-label>{{ answer.type == 2 ? 'Optional: ' : ''}} Help us improve</mat-label>
                <textarea
                  matInput rows="3"
                  formControlName="answerComment"
                ></textarea>
              </mat-form-field>
            </div>
          }
        }
      </mat-radio-group>
    </div>

    <div class="action-buttons">
      <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
      <md-filled-button
        [disabled]="!formIsValid.value"
        (click)="onConfirm()">Confirm</md-filled-button>
    </div>
  </div>
}