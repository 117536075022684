<div class="bar {{className}}">
  <span class="material-symbols-outlined" [ngStyle]="iconStyles">
    {{ icon }}
  </span>
  <span [ngStyle]="spanStyle" [ngClass]="{'one-line-ellipsis': oneLineEllipsis}" [title]="text">
    {{ text }}
  </span>

  @if (actionName) {
    @if (isActionClickable) {
      <a href="javascript:void(0);"
        [ngClass]="{'action': true, 'disabled': isProcessing}"
        (click)="isProcessing ? null : clickedAction.emit()"
      >
        {{ actionName }}
      </a>
    } @else {
      <span class="action">
        {{ actionName }}
      </span>
    }
  }
</div>
