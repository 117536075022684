import { MatSnackBar } from '@angular/material/snack-bar';

export type RemappedOmit<T, K extends PropertyKey> = {
  [P in keyof T as P extends K ? never : P]: T[P];
};

export type DeliveryDateUpdateEvent = Event & {
  detail: {
    deliveryDate: string;
    shippingLine: {
      nextOrderDate: string;
      shippingMethod: string;
      total: number;
      name: string;
    };
  };
};

const createSnackBar = (
  snackBar: MatSnackBar,
  message: string,
  panelClass: string[],
  duration: number | null
) =>
  snackBar.open(message, 'Dismiss', {
    duration: duration ?? undefined,
    panelClass,
  });

export const successSnackBar = (
  snackBar: MatSnackBar,
  message: string,
  duration: number | null = 3000
) => createSnackBar(snackBar, message, ['snackbar-success'], duration);

export const errorSnackBar = (
  snackBar: MatSnackBar,
  message: string,
  duration: number = 3000
) => createSnackBar(snackBar, message, ['snackbar-error'], duration);

export const sortedStatuses = [
  'processing',
  'queued',
  'failed',
  'paused',
  'active',
  'deleted',
];
