import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OrderStatus } from '../types/scheduled-order';

@Component({
  selector: 'lib-bar-tag',
  standalone: true,
  imports: [NgClass, NgStyle],
  templateUrl: './bar-tag.component.html',
  styleUrl: './bar-tag.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BarTagComponent {
  @Input() orderStatus: OrderStatus
  @Input() icon: string;
  @Input() fillIcon = true;
  @Input() text: string;
  @Input() oneLineEllipsis = true;
  @Input() className: string;
  @Input() spanStyle: {[klass: string]: string};
  @Input() actionName: string | null | undefined;
  @Input() isActionClickable: boolean;

  @Output() clickedAction = new EventEmitter();

  iconStyles: { [property: string]: string };
  isProcessing: boolean;

  constructor() {}

  ngOnChanges() {
    this.isProcessing = ['processing', 'queued'].includes(this.orderStatus);
    this.iconStyles = {
      'font-variation-settings': `
        'FILL' ${this.fillIcon ? 1 : 0},
        'wght' 400,
        'GRAD' 0,
        'opsz' 20
      `,
    };
  }
}
