import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs';

import {
  ScheduledOrderPaymentMethodComponent,
} from '../scheduled-order-payment-method/scheduled-order-payment-method.component';
import { ChangePaymentAndProcessNowEvent, ChangePaymentEvent } from '../types/events';
import { QPilotPaymentMethod } from '../types/payment-method';
import { OrderStatus, QPilotScheduledOrder, QPilotScheduledOrderTotals } from '../types/scheduled-order';

@Component({
  selector: 'lib-change-payment-method',
  standalone: true,
  imports: [
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIcon,
    AsyncPipe,
    ScheduledOrderPaymentMethodComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './change-payment-method.component.html',
  styleUrl: './change-payment-method.component.scss'
})
export class ChangePaymentMethodComponent implements OnInit, DoCheck {
  @Input() orderStatus: OrderStatus;
  @Input() paymentMethods: QPilotPaymentMethod[];
  @Input() scheduledOrder: QPilotScheduledOrder & { getOrderTotals: () => Observable<QPilotScheduledOrderTotals> };
  @Input() currentPaymentMethodId?: number | undefined;
  @Input() paymentMethodsUrl: string;
  @Input() orderVerboseName: string;
  @Output() apply = new EventEmitter<ChangePaymentEvent>();
  @Output() applyAndProcess = new EventEmitter<ChangePaymentAndProcessNowEvent>();
  @Output() cancel = new EventEmitter();
  selectedPaymentMethodId?: number | undefined;
  orderTotals$: Observable<QPilotScheduledOrderTotals>;

  constructor() { }

  ngOnInit() {
    this.selectedPaymentMethodId = this.currentPaymentMethodId;
    this.orderPaymentMethods();
  }

  ngDoCheck() {
    this.orderTotals$ = this.scheduledOrder.getOrderTotals();
  }

  onAdd() {
    window.open(this.paymentMethodsUrl, '_blank');
  }

  onCancel() {
    this.selectedPaymentMethodId = this.currentPaymentMethodId;
    this.cancel.emit();
  }

  onApply($event: ChangePaymentEvent) {
    this.selectedPaymentMethodId = $event.paymentMethodId;
    this.apply.emit({
      scheduledOrderId: this.scheduledOrder.id,
      paymentMethodId: this.selectedPaymentMethodId
    });
    this.orderPaymentMethods();
  }

  onApplyPaymentAndProcessNow($event: ChangePaymentAndProcessNowEvent) {
    this.selectedPaymentMethodId = $event.paymentMethodId;
    this.applyAndProcess.emit({
      scheduledOrderId: this.scheduledOrder.id,
      paymentMethodId: this.selectedPaymentMethodId
    });
    this.orderPaymentMethods();
  }

  orderPaymentMethods() {
    this.paymentMethods = this.paymentMethods.sort((a, b) => Number(b.id == this.selectedPaymentMethodId) - Number(a.id == this.selectedPaymentMethodId));
  }
}
