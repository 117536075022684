@switch (order.status) {
  @case ("processing") {
    <p class="order-hint">
      Your {{orderVerboseName}} is currently processing.
      Please wait to make changes until after your {{orderVerboseName}} is processed.
    </p>
  }
  @case ("active") {
    @if (useNextime && hasDeliveryDate) {
      <p>
        Your next {{orderVerboseName}} will be
        <a (click)="emitOpenDeliveryDateDialog()" href="javascript:void(0);">delivered on {{order.estimatedDeliveryDate| dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset }}</a>.
        Changes can be made before 11:59pm on {{changeLimitDate | dateFormat: 'MMM d' : siteSettings.utcOffset}}.
      </p>
    } @else {
      <p class="order-hint">
        Your next {{orderVerboseName}} will process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}}.
        Changes to your {{orderVerboseName}} can be made before 11:59pm on {{changeLimitDate | dateFormat: 'MMM d' : siteSettings.utcOffset}}.
      </p>
    }
  }
  @case ("paused") {
    <p class="order-hint">
      Your {{orderVerboseName}} was scheduled to process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}} and is now Paused.
      You can <a href="javascript:void(0);" (click)="emitRescheduleAndResume()">change your Next Occurrence Date</a>
      or select <a href="javascript:void(0);" (click)="emitResume()">Resume</a> to un-pause.
    </p>
  }
  @case ("failed") {
    @switch (order.processingErrorCode) {
      @case (99) {
        <p class="order-hint">
          Your {{orderVerboseName}} failed to process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}} due to a technical error.
        </p>
      }
      @case (1000) {
        <p class="order-hint">
          Your {{orderVerboseName}} failed to process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}} because it is empty. Please add product to resume.
        </p>
      }
      @case(1001) {
        <p class="order-hint">
          Your {{orderVerboseName}} failed to process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}} because no items were available or in stock to ship.
        </p>
      }
      @case (1002) {
       <p class="order-hint">
          Your {{orderVerboseName}} failed to process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}} due to a shipping issue.
        </p>
      }
      @case (1003) {
        <p class="order-hint">
          Your {{orderVerboseName}} failed due to an unsupported payment type on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}}.
        </p>
      }
      @case (1004) {
        <p class="order-hint">
          Your {{orderVerboseName}} failed due to no payment method on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}}. Please update your payment to resume.
        </p>
      }
      @case (2000) {
        <p class="order-hint">
          Your {{orderVerboseName}} failed due to payment on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}}. Please update your payment to resume.
        </p>
      }
      @default {
        <p class="order-hint">
          Your {{orderVerboseName}} failed to process on {{order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}} due to an order processing error ({{order.processingErrorCode}}).
        </p>
      }
    }
    @if (order.dunning && order.dunning.isEnabled) {
      <p>{{order.dunning.message}}</p>
    }
  }
  @case ("deleted") {
    <p>
      Your {{orderVerboseName}} was canceled on {{order.lastChangeToDeleted | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset}},
      <a href="javascript:void(0);" (click)="emitResume()">Would you like to resume?</a>
    </p>
  }
}
