import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  OrderStatus,
  QPilotScheduledOrder,
  ShippingAddressFormComponent,
  UpdateShippingAddressEvent,
} from '@qpilot/subscriber-portal-web-component';
import { catchError, finalize, mergeMap, Observable, of, tap } from 'rxjs';

import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { errorSnackBar, successSnackBar } from '../utils';

@Component({
  selector: 'app-change-shipping',
  standalone: true,
  imports: [
    ShippingAddressFormComponent,
    AsyncPipe,
  ],
  templateUrl: './change-shipping.component.html',
  styleUrl: './change-shipping.component.scss',
})
export class ChangeShippingComponent implements OnInit {
  order$: Observable<QPilotScheduledOrder>;

  constructor(
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit() {
    this.order$ = this.dataService.getCurrentOrder();
  }

  onUpdate(
    { address }: UpdateShippingAddressEvent,
    order: QPilotScheduledOrder
  ) {
    this.qPilotCloudApiService.getScheduledOrderById(order.id)
      .pipe(
        mergeMap(data => {
          const [firstName, ...rest] = address.fullName!.trim().split(' ');
          const lastName = rest[rest.length - 1];
          const updateData: QPilotScheduledOrder = {
            ...data,
            shippingCountry: address.country,
            shippingState: address.state,
            shippingCity: address.city,
            shippingStreet1: address.street1,
            shippingStreet2: address.street2,
            shippingPostcode: address.postalCode,
            shippingFirstName: firstName,
            shippingLastName: lastName,
            phoneNumber: address.phoneNumber,
          };
          return this.qPilotCloudApiService.updateScheduledOrder(updateData)
        }),
        tap(updatedOrder => this.dataService.updateOrder({
          ...updatedOrder,
          status: updatedOrder.status.toLowerCase() as OrderStatus,
          estimatedDeliveryDate: undefined,
        })),
        tap(() => successSnackBar(this.snackBar, "Update: Shipping Address Changed")),
        catchError(() => {
          errorSnackBar(this.snackBar, "Update Failed: Error Changing Shipping Address");
          return of(null);
        }),
        finalize(() => this.dialog.closeAll())
      ).subscribe();
  }

  onCancel() {
    this.router.navigate(
      [
        { outlets: { dialog: ['shipping-address'] } }
      ],
      { skipLocationChange: true }
    );
  }
}
