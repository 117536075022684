import '@material/web/dialog/dialog';

import { Overlay } from '@angular/cdk/overlay';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, NgZone, Output, TemplateRef } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

import { BarTagComponent } from '../bar-tag/bar-tag.component';
import { DeleteCouponEvent } from '../types/events';
import { OrderStatus, QPilotScheduledOrderAmountDetail } from '../types/scheduled-order';

@Component({
  selector: 'lib-scheduled-order-coupon',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatIcon,
    MatDialogModule,
    BarTagComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scheduled-order-coupon.component.html',
  styleUrl: './scheduled-order-coupon.component.scss'
})
export class ScheduledOrderCouponComponent {
  @Input() orderStatus: OrderStatus;
  @Input() coupon: QPilotScheduledOrderAmountDetail;
  @Input() orderVerboseName: string;
  @Output() deleteCoupon = new EventEmitter<DeleteCouponEvent>();

  dialogRef: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private zone: NgZone,
  ) { }

  onDeleteCoupon() {
    this.deleteCoupon.emit({
      couponCode: this.coupon.description,
    } as DeleteCouponEvent);
    this.dialogRef.close();
  }

  actionCallback(templateRef: TemplateRef<any>) {
    return () => this.openDialogWithRef(templateRef);
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.zone.run(() => {
      this.dialogRef = this.dialog.open(templateRef, {
        scrollStrategy: this.overlay.scrollStrategies.noop()
      });
    })
  }
}
