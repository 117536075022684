<div class="payment-methods-wrapper">
    <h2>My Payment Methods</h2>

    <div class="order-payment-methods">
      @for (paymentMethod of paymentMethods; track paymentMethod.id) {
        <lib-scheduled-order-payment-method
          class="payment-method"
          [orderTotals$]="orderTotals$"
          [orderStatus]="orderStatus"
          [orderVerboseName]="orderVerboseName"
          [paymentMethod]="paymentMethod"
          [enableSelection]="true"
          [currentPaymentMethod]="paymentMethod.id == selectedPaymentMethodId"
          [orderVerboseName]="orderVerboseName"
          (applyPayment)="onApply($event)"
          (applyPaymentAndProcessNow)="onApplyPaymentAndProcessNow($event)"
        />
      }
    </div>

    <div class="action-buttons">
        <md-outlined-button (click)="onCancel()" >Cancel</md-outlined-button>
        <md-outlined-button (click)="onAdd()">
            <span class="material-symbols-outlined">add</span>
            <span>Add New</span>
        </md-outlined-button>
    </div>
  </div>
