import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QPilotScheduledOrder } from '../types/scheduled-order';
import { ShippingAddressForm } from '../types/scheduled-order-shipping';
import { CountrySelectComponent } from '../country-select/country-select.component';

@Component({
  selector: 'lib-shipping-address-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CountrySelectComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './shipping-address-form.component.html',
  styleUrl: './shipping-address-form.component.scss'
})
export class ShippingAddressFormComponent {

  @Input() order: QPilotScheduledOrder;
  @Output() cancel = new EventEmitter();
  @Output() update = new EventEmitter();

  addressFormGroup = new FormGroup({
    country: new FormControl<string>('', [Validators.required]),
    city: new FormControl<string>('', [Validators.required]),
    state: new FormControl<string>('', [Validators.required]),
    street1: new FormControl<string>('', [Validators.required]),
    street2: new FormControl<string>('', []),
    postalCode: new FormControl<string>('', [Validators.required]),
    fullName: new FormControl<string | undefined>('', [Validators.required]),
    phoneNumber: new FormControl<string>('', []),
  });

  constructor() { }

  ngOnInit() {
    let fullName = '';
    if (this.order.shippingFirstName) fullName += this.order.shippingFirstName;
    if (this.order.shippingLastName) fullName += ` ${this.order.shippingLastName}`;
  
    const addressForm: ShippingAddressForm = {
      country: this.order.shippingCountry ?? '',
      fullName: `${fullName.trim()}`,
      street1: this.order.shippingStreet1 ?? '',
      street2: this.order.shippingStreet2 ?? '',
      postalCode: this.order.shippingPostcode ?? '',
      state: this.order.shippingState ?? '',
      city: this.order.shippingCity ?? '',
      phoneNumber: this.order.phoneNumber ?? '',
    };

    this.addressFormGroup.setValue({ ...addressForm });
  }

  onCancel() {
    this.cancel.emit();
  }

  onUpdate() {
    this.update.emit({
      address: this.addressFormGroup.value,
    });
  }
}
