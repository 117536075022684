import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NodPickerConfig } from '../types/site';

@Component({
  selector: 'lib-nod-date-picker',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './nod-date-picker.component.html',
  styleUrl: './nod-date-picker.component.scss'
})
export class NodDatePickerComponent implements OnInit, OnChanges {
  @ViewChild(
    'picker',
    { read: undefined, static: false }
  ) datePicker: MatDatepicker<Date>;

  
  public selectedNodUtc: Date | null;
  public isNodValid: boolean;
  public selectedDate: Date;

  private initialSelectedDate: Date;
  private processingOffset: number = 0;
  private userTimezoneOffset: number;
  public minimumNod: Date;

  @Input() set date(value: Date) {
    if (value) {
      this.initialSelectedDate = value;
      this.selectedDate = value;
      this.selectedNodUtc = value;
    }
  }

  @Input() config: NodPickerConfig;
  @Output() dateChanged = new EventEmitter<Date | null>();

  constructor() {
    this.minimumNod = new Date();
  }
  
  ngOnInit() {
    if (this.config.siteProcessingOffset) this.processingOffset = this.config.siteProcessingOffset;
    if (this.config.scheduledOrderProcessingOffset) this.processingOffset = this.config.scheduledOrderProcessingOffset;
    this.userTimezoneOffset = -1 * (new Date().getTimezoneOffset() / 60);

    if (this.config.supportsProcessingWindow) {
      if (this.processingOffset > 0 || (this.config.lockedDays != null && this.config.lockedDays > 0)) {
        this.minimumNod = this.getFutureUtcDate(this.minimumNod, this.userTimezoneOffset);

        let lockedDaysAndProcessingOffset = this.config.lockedDays ? this.config.lockedDays : 0;
        lockedDaysAndProcessingOffset += this.processingOffset ? this.processingOffset : 0;

        this.minimumNod.setDate(this.minimumNod.getDate() + lockedDaysAndProcessingOffset);

        if (this.config.processingStartTime) {
          let startHour = parseInt(this.config.processingStartTime.split(':')[0]);

          if (this.minimumNod.getHours() > startHour) {
            this.minimumNod.setDate(this.minimumNod.getDate() + 1);
            let minimumDateTimestamp = Date.UTC(this.minimumNod.getFullYear(), this.minimumNod.getMonth(), this.minimumNod.getDate(), startHour, 0, 0);
            this.minimumNod = new Date(minimumDateTimestamp);
          }
        }
      }
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  public selectDate(date: Date): void {
    if (!date) {
      this.dateChanged.next(null)
      return;
    }

    if (date.getFullYear() == this.initialSelectedDate.getFullYear() &&
        date.getUTCMonth() == this.initialSelectedDate.getUTCMonth() &&
        date.getUTCDate() == this.initialSelectedDate.getUTCDate()) {
      this.selectedNodUtc = this.initialSelectedDate;
    } else {
      if (this.config.supportsProcessingWindow) {
        const today = new Date()
        if (date.getUTCDate() == today.getUTCDate() && date.getUTCMonth() == today.getUTCMonth() && date.getUTCFullYear() == today.getUTCFullYear()) {
          let isTimeValid = this.isCurrentHourWithinProcessingWindow(this.config.processingStartTime!, this.config.processingEndTime!)
          if (isTimeValid) {
            let timestamp = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), today.getUTCHours(), today.getUTCMinutes() + 1, today.getUTCMilliseconds());
            this.selectedNodUtc = new Date(timestamp);
          } else {
            this.selectedNodUtc = this.fitHourWithinProcessingWindow(date, this.config.processingStartTime!);
          }
        } else {
          this.selectedNodUtc = this.fitHourWithinProcessingWindow(date, this.config.processingStartTime!);
        }
      } else {
        this.selectedNodUtc = date;
        this.isNodValid = true;
      }
    }

    this.dateChanged.next(this.selectedNodUtc)
  }

  private getFutureUtcDate(date: Date, offset: number): Date {
    if (!date) return new Date();

    let newDay = Math.floor((date.getHours() + offset) / 24);
    let newHours = (date.getHours() + offset) % 24;

    let timestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate() + newDay, newHours, 0, 0);
    let result = new Date(timestamp);

    return result;
  }

  private isCurrentHourWithinProcessingWindow(processingStartTime: string, processingEndTime: string): boolean {
    if (!processingStartTime || !processingEndTime) return false;

    let startHour = parseInt(processingStartTime.split(':')[0]);
    let endHour = parseInt(processingEndTime.split(':')[0]);
    let currentHour = new Date().getUTCHours();

    if (startHour > endHour && (currentHour < startHour || currentHour > endHour)) {
      return false;
    } else if (startHour > currentHour || endHour <= currentHour) {
      return false;
    }

    return true;
  }

  private fitHourWithinProcessingWindow(selectedDate: Date, processingStartTime: string): Date | null {
    if (!processingStartTime) {
      return selectedDate
    }

    let startHour = parseInt(processingStartTime.split(':')[0]);
    if (!selectedDate || startHour == null) return null;

    let timestamp = Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), startHour, 0, 0);

    return new Date(timestamp);
}
}
