import '@material/web/progress/circular-progress';

import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import {
  OrderStatus,
  QPilotAccessToken,
  QPilotScheduledOrder,
  QPilotSiteSettings,
} from '@qpilot/subscriber-portal-web-component';
import { NgxResizeObserverModule } from 'ngx-resize-observer';
import { delay, Observable, switchMap, tap } from 'rxjs';

import { OrdersSummaryViewComponent } from './orders-summary-view/orders-summary-view.component';
import { DataService } from './services/data.service';
import { LoadingService } from './services/loading.service';
import { QPilotCloudApiService } from './services/qpilot-cloud-api.service';
import { SiteSettingsService } from './services/site-settings.service';
import { ViewportService } from './services/viewport.service';
import { sortedStatuses } from './utils';

@Component({
  selector: 'app-subscriber-portal',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterModule,
    OrdersSummaryViewComponent,
    AsyncPipe,
    MatDialogModule,
    NgxResizeObserverModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class AppComponent implements OnInit {
  title = 'subscriber-portal';
  isLoading$: Observable<boolean>;
  data$: Observable<any>;
  loadingDialogRef: MatDialogRef<any>;
  protected accessToken: QPilotAccessToken;

  @Input() siteId: string;
  @Input() customerId: string;
  @Input() token: string;

  @ViewChild('loadingDialog') set loadingDialog(content: TemplateRef<any>) {
    if (content) {
      this.loadingDialogRef = this.dialog.open(content, {
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        disableClose: true,
      });
    } else {
      this.loadingDialogRef?.close();
    }
  }

  constructor(
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    private loadingService: LoadingService,
    private siteSettingsService: SiteSettingsService,
    private viewportService: ViewportService,
    private elementRef: ElementRef,
    private router: Router,
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    this.viewportService.setAppElementRef(this.elementRef);

    const siteId = parseInt(
      this.siteId ?? this.elementRef.nativeElement.getAttribute('siteId')
    );
    const customerId = parseInt(
      this.customerId ??
        this.elementRef.nativeElement.getAttribute('customerId')
    );
    const token =
      this.token ?? this.elementRef.nativeElement.getAttribute('token');

    this.qPilotCloudApiService.setSiteId(siteId);
    this.qPilotCloudApiService.setCustomerId(customerId);
    this.qPilotCloudApiService.setAccessToken(token);

    this.data$ = this.fetchSiteSettings().pipe(
      switchMap(() => this.fetchScheduledOrders()),
    );

    this.isLoading$ = this.loadingService.isLoading$.pipe(delay(0));
    this.router.navigate(['orders'], { skipLocationChange: true });
  }

  handleResize(event: ResizeObserverEntry) {
    this.viewportService.setViewport(this.elementRef.nativeElement.clientWidth);
  }

  private addingStyle(element: any, styles: string) {
    const style = document.createElement('style');
    style.innerText = styles;
    element.appendChild(style);
  }

  private fetchScheduledOrders() {
    return this.qPilotCloudApiService.getScheduledOrdersForCustomer().pipe(
      tap((scheduledOrders) => {
        const orders = scheduledOrders
          .map(this.orderWithStatus)
          .sort(this.sortOrders);
        this.dataService.setOrders(orders);
      })
    );
  }

  private fetchSiteSettings() {
    return this.qPilotCloudApiService.getSiteSettings().pipe(
      tap((siteSettings: QPilotSiteSettings & { nextimeSiteId?: string }) => {
        this.siteSettingsService.setSiteSettings(siteSettings);

        if (siteSettings.themingStyles) {
          this.addingStyle(
            this.elementRef.nativeElement.shadowRoot,
            siteSettings.themingStyles
          );
          this.addingStyle(document.body, siteSettings.themingStyles);
        }

        if (siteSettings.styles) {
          this.addingStyle(
            this.elementRef.nativeElement.shadowRoot,
            siteSettings.styles.customStyles
          );
          this.addingStyle(document.body, siteSettings.styles.customStyles);

          const nextimeStyling = `:host, html { --theme-primary: ${siteSettings.styles.primaryColor} }`;
          this.addingStyle(document.body, nextimeStyling);
        }

        this.addingStyle(document.body, ".mdc-dialog-scroll-lock { overflow: hidden }");
      })
    );
  }

  private orderWithStatus(order: QPilotScheduledOrder): QPilotScheduledOrder {
    return {
      ...order,
      status: order.status.toLocaleLowerCase() as OrderStatus,
    };
  }

  private sortOrders(a: QPilotScheduledOrder, b: QPilotScheduledOrder) {
    const [statusA, statusB] = [
      a.status.toLocaleLowerCase(),
      b.status.toLocaleLowerCase(),
    ];
    const sortStatus =
      sortedStatuses.indexOf(statusA) - sortedStatuses.indexOf(statusB);
    if (sortStatus == 0) {
      const [dateA, dateB] = [
        new Date(a.nextOccurrenceUtc),
        new Date(b.nextOccurrenceUtc),
      ];
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    }
    return sortStatus;
  }
}
