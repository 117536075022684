<mat-card [ngClass]="{
  'mat-elevation-z0': true,
  'selected': selectedOption == value,
  'hasConfirmationButton': showConfirmButton
}">
  <div class="radio-wrapper">
    <mat-radio-button
      class="radio-button"
      [value]="value"
      [aria-label]="ariaLabel" />
    <ng-content></ng-content>
  </div>
  @if (showConfirmButton && (selectedOption == value)) {
    <div class="action-buttons">
      <md-filled-button (click)="onConfirm()">Confirm</md-filled-button>
    </div>
  }
</mat-card>
