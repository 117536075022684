<div>
  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>{{ product.title }}</mat-card-title>
    </mat-card-header>
    <img [src]="getProductImageUrl()" class="img-product" />
    <mat-card-content>
      <div>
        <span class="product-price">
          Price:
          {{ product.price | number : "1.0-2" | currency : "USD" : "symbol" }}
        </span>
        <span class="product-availability">
          {{ product.availability }}
        </span>
        <div class="add-product-quantity">
          <a href="javascript:void(0);" (click)="openQuantitySelector()"
            >Quantity: {{ quantity }}</a
          >

          @if (showQuantitySelector) {
          <div class="quantity-selector">
            <md-outlined-icon-button
              (click)="decrementNewQuantityToAdd()"
              aria-label="Decrement quantity"
            >
              <md-icon>remove</md-icon>
            </md-outlined-icon-button>
            <span>{{ quantityToAdd }}</span>
            <md-outlined-icon-button
              (click)="incrementNewQuantityToAdd()"
              aria-label="Increment quantity"
            >
              <md-icon>add</md-icon>
            </md-outlined-icon-button>
          </div>
          }
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      @if (productIsInOrder) {
      <div class="action-buttons">
        @if (isRecurring) {
        <md-outlined-button (click)="openDialogWithRef(chanceCyclesDialog)"
          >Change to One-Time</md-outlined-button
        >
        } @else {
        <md-outlined-button (click)="openDialogWithRef(chanceCyclesDialog)"
          >Change to Recurring</md-outlined-button
        >
        }
        <md-filled-button (click)="onUpdate()">Update</md-filled-button>
      </div>
      } @else {
      <md-outlined-button (click)="onAddOneTime()"
        >Add One Time</md-outlined-button
      >
      <md-filled-button (click)="onAdd()">Add</md-filled-button>
      }
    </mat-card-actions>
  </mat-card>

  <ng-template #chanceCyclesDialog>
    @if (isRecurring) {
    <h2 matDialogTitle>Change to One-Time Only?</h2>
    <mat-dialog-content>
      <p>
        ({{ quantity }}) {{ product.title }} will only be including with your
        next (1) {{ scheduledOrderVerboseName }}
      </p>
    </mat-dialog-content>
    } @else {
    <h2 matDialogTitle>Change to Recurring?</h2>
    <mat-dialog-content>
      <p>
        ({{ quantity }}) {{ product.title }} will be recurring
        {{ quantity > 1 ? "items" : "item" }}
      </p>
    </mat-dialog-content>
    }
    <mat-dialog-actions style="justify-content: flex-end">
      <md-text-button form="form-id" value="cancel" matDialogClose
        >Cancel</md-text-button
      >
      <md-text-button form="form-id" value="confirm" (click)="onChangeCycles()"
        >Confirm</md-text-button
      >
    </mat-dialog-actions>
  </ng-template>
</div>
