@if (survey$ | async; as survey) {
  <div class="cancel-order-dialog-wrapper">

  @if (survey.id) {
    <lib-survey-and-offer
      [survey]="survey"
      [orderVerboseName]="orderVerboseName"
      [nextOccurrenceVerboseName]="nextOccurrenceVerboseName"
      [actionTrigger]="actionTrigger"
      [navigationText]="'Cancel'"
      (confirm)="onAnswerOffer($event)"
      (goBack)="resetSurveyAndOffer()"
      (cancel)="onCancel()" />
  } @else {
    <div class="cancel-header">
      <h2>Cancel</h2>
    </div>

    <h3 class="cancel-order-subtitle">I want to cancel my {{ orderVerboseName }}</h3>

    <mat-radio-group class="radio-group" [(ngModel)]="selectedOption">
      <lib-card-with-radio-button
        [value]="'cancel'"
        [selectedOption]="selectedOption"
        [showConfirmButton]="true"
        [ariaLabel]="cancelOptionTitle"
        (confirm)="onConfirm()">
        <div class="option">
          <p class="title">{{ cancelOptionTitle }}</p>
          @if (selectedOption == 'cancel') {
            <p class="subtitle">{{ cancelOptionSubtitle }}</p>
          }
        </div>
      </lib-card-with-radio-button>
    </mat-radio-group>

    <div class="dialog-action-buttons">
      <md-outlined-button class="cancel-action" (click)="onCancel()">Cancel</md-outlined-button>
    </div>
  }
  </div>
}
