import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QPilotScheduledOrder } from '@qpilot/subscriber-portal-web-component';
import moment from 'moment';
import { finalize, map, shareReplay, switchMap, take, tap } from 'rxjs';

import { DeliveryDateUpdateEvent, successSnackBar } from '../utils';
import { DataService } from './data.service';
import { QPilotCloudApiService } from './qpilot-cloud-api.service';
import { SiteSettingsService } from './site-settings.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  private shouldFetchNextOccurrenceDate = true;
  private eventsThatNextimeShouldIgnore: Array<number> = [];
  private waitingOrders: Array<number> = [];
  isLoading = false;

  constructor(
    private qPilotCloudApiService: QPilotCloudApiService,
    private dataService: DataService,
    private siteSettingsService: SiteSettingsService,
    private snackBar: MatSnackBar,
  ) { }

  onDeliveryDateUpdate(
    event: DeliveryDateUpdateEvent,
    order: QPilotScheduledOrder
  ) {
    if (order.status != 'active') return;
    if (this.isIgnored(order.id)) return;
    const detail = event.detail;
    if (!detail) return;
    const { deliveryDate, shippingLine } = detail;
    if (!deliveryDate) return;

    const { shippingMethod, total, name } = shippingLine;
    const deliveryDateUtc = moment(deliveryDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const nextOrderDate = moment(deliveryDate).utc().add(-1, 'd').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    this.isLoading = true;
    const currentOrder$ = this.dataService.getCurrentOrder();

    currentOrder$.pipe(
      take(1),
      switchMap((currentOrder) => {
        const nextOrderDateUtc = this.shouldFetchNextOccurrenceDate
        ? moment(nextOrderDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : currentOrder.nextOccurrenceUtc;
        return this.qPilotCloudApiService.updateScheduledOrderDeliveryInfo(
          order.id,
          deliveryDateUtc,
          this.shouldFetchNextOccurrenceDate ? nextOrderDateUtc : currentOrder.nextOccurrenceUtc,
          {
            shippingMethod: shippingMethod,
            total: total,
            name: name,
          }
        ).pipe(map(() => nextOrderDateUtc))
      }),
      tap(nextOrderDateUtc => (
        this.dataService.updateOrderNextOccurrenceAndDeliveryInfo(
          order.id,
          nextOrderDateUtc,
          deliveryDateUtc,
          shippingMethod,
          total,
        )
      )),
      tap(() => successSnackBar(this.snackBar, "Delivery Date updated!")),
      finalize(() => {
        this.isLoading = false;
        this.waitingOrders = this.waitingOrders.filter(x => x != order.id);
        this.setShouldFetchNextOccurrenceDate(true);
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ).subscribe();
  }

  setShouldFetchNextOccurrenceDate(value: boolean) {
    this.shouldFetchNextOccurrenceDate = value;
  }

  isIgnored(orderId: number) {
    return this.eventsThatNextimeShouldIgnore.includes(orderId);
  }

  ignoreEvent(orderId: number) {
    if (this.isIgnored(orderId)) return;
    this.eventsThatNextimeShouldIgnore.push(orderId);
  }

  trackOrder(orderId: number) {
    if (this.waitingOrders.includes(orderId)) return;

    this.waitingOrders.push(orderId);

    setTimeout(() => {
      if (this.waitingOrders.includes(orderId)) {
        this.siteSettingsService.useNextime = false;
        this.isLoading = false;
      }
    }, 45000);
  }
}
