import { AsyncPipe } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChangePaymentAndProcessNowEvent,
  ChangePaymentEvent,
  ChangePaymentMethodComponent,
  OrderStatus,
  QPilotPaymentMethod,
  QPilotScheduledOrder,
  QPilotScheduledOrderTotals,
} from '@qpilot/subscriber-portal-web-component';
import {
  catchError,
  combineLatestWith,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { errorSnackBar, successSnackBar } from '../utils';

type ScheduledOrderWithTotalsCallback = QPilotScheduledOrder & {
  getOrderTotals: () => Observable<QPilotScheduledOrderTotals>;
};

@Component({
  selector: 'app-update-payment',
  standalone: true,
  imports: [AsyncPipe, ChangePaymentMethodComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './update-payment.component.html',
  styleUrl: './update-payment.component.scss',
})
export class UpdatePaymentComponent implements OnInit {
  order$: Observable<ScheduledOrderWithTotalsCallback>;
  orderVerboseName: string;
  paymentMethods$: Observable<QPilotPaymentMethod[]>;
  paymentMethodsUrl: string;

  constructor(
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    private siteSettingsService: SiteSettingsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: Observable<ScheduledOrderWithTotalsCallback>;
    }
  ) {}

  ngOnInit() {
    this.order$ = this.data.order;
    this.orderVerboseName = this.siteSettingsService.orderVerboseName;
    this.paymentMethodsUrl = this.siteSettingsService.paymentMethodsPageUrl;
    this.paymentMethods$ =
      this.qPilotCloudApiService.getPaymentMethodsForCustomer();
  }

  onCancel() {
    this.dialog.closeAll();
  }

  onApply({ scheduledOrderId: orderId, paymentMethodId }: ChangePaymentEvent) {
    this.qPilotCloudApiService
      .updateScheduledOrderPaymentMethod(orderId, paymentMethodId)
      .pipe(
        tap((result) =>
          this.dataService.updateOrderPayment(result.id, result.paymentMethod)
        ),
        tap(() => successSnackBar(this.snackBar, 'Payment updated')),
        tap(() => this.onCancel()),
        catchError(() => {
          errorSnackBar(this.snackBar, 'Failed: Error updating Payment');
          return of(null);
        })
      ).subscribe();
  }

  onApplyAndProcess({
    scheduledOrderId: orderId,
    paymentMethodId,
  }: ChangePaymentAndProcessNowEvent) {
    this.order$
      .pipe(
        take(1),
        combineLatestWith(
          this.qPilotCloudApiService.updateScheduledOrderPaymentMethod(
            orderId,
            paymentMethodId
          )
        ),
        catchError(() => {
          errorSnackBar(this.snackBar, 'Failed: Error updating payment');
          return of();
        }),
        switchMap(([order, result]) =>
          this.qPilotCloudApiService.retryProcessing(result.id).pipe(
            tap((updatedOrder) =>
              this.dataService.updateOrder({
                ...order,
                paymentMethod: result.paymentMethod,
                paymentMethodId: result.paymentMethod?.id,
                status: updatedOrder.status.toLowerCase() as OrderStatus,
                lastOccurrenceUtc: updatedOrder.lastOccurrenceUtc,
                nextOccurrenceUtc: updatedOrder.nextOccurrenceUtc,
              })
            ),
            catchError(() => {
              errorSnackBar(this.snackBar, 'Failed: Error processing');
              return of();
            })
          )
        ),
        tap(() => {
          this.dialog.closeAll();
          successSnackBar(
            this.snackBar,
            'Payment updated and processing retried'
          );
        })
      ).subscribe();
  }
}
