<div class="shipping-dialog-wrapper">
  <h2>Change Shipping</h2>

  <h3>Shipping Addresses</h3>

  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <div class="shipping-info">
        <span class="recipient-name">{{ fullName }}</span>
        <span>{{ order.shippingStreet1 + ' ' + (order.shippingStreet2 ?? '') }}</span>
        <span>{{ order.shippingCity }}</span>
        <span>{{ order.shippingState }}</span>
        <span>{{ order.shippingPostcode }}</span>
        <span>{{ countryDisplayName }}</span>
        @if (order.phoneNumber) {
          <span>Phone number: {{ order.phoneNumber }}</span>
        }
      </div>
    </mat-card-content>
    <mat-card-actions>
      <md-outlined-button (click)="onEditAddress()">Edit Address</md-outlined-button>
    </mat-card-actions>
  </mat-card>

</div>
