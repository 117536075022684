import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ReplaySubject } from 'rxjs';
import { Country } from '../types/address';
import { AddressHelper } from './addressHelper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@Component({
  selector: 'lib-country-select',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    AsyncPipe,
    NgxMatSelectSearchModule,
  ],
  templateUrl: './country-select.component.html',
  styleUrl: './country-select.component.scss'
})
export class CountrySelectComponent implements OnInit {
  @Input() value: string;
  @Input() placeholder: string;
  @Input() inputFormControl: FormControl;

  countries: Country[];
  filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);

  constructor(public addressHelper: AddressHelper) { }

  ngOnInit() {
    if (null == this.inputFormControl) {
      this.inputFormControl = new FormControl();
    }
    this.countries = this.addressHelper.countries;
    this.filteredCountries.next(this.countries.slice());
  }

  filterCountries(search: string) {
    if (!this.countries) return;

    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCountries.next(
      this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
