<div class="details-container">
  <div class="details-core">
    <div class="details-header">
      <div class="order-status">
        <span>{{ orderVerboseName }} Status: </span>
        <span [ngClass]="[order.status, 'highlight-status']">
          {{ statusDisplayName }}
        </span>
      </div>
    </div>

    <div class="order-info">
      @if (useNextime && loadingTimeline) {
      <div class="timeline-loader">
        <lib-loader-dots />
      </div>
      }

      <lib-scheduled-order-details-status
        [order]="order"
        [orderVerboseName]="orderVerboseName"
        [changeLimitDate]="changeLimitDate"
        [useNextime]="useNextime"
        [hasDeliveryDate]="hasDeliveryDate"
        [siteSettings]="siteSettings"
        (openDeliveryDateDialog)="openDeliveryDateDialog()"
        (rescheduleAndResume)="onRescheduleAndResume()"
        (resume)="onResume()"
      />

      <div
        class="order-timeline"
        [ngClass]="{ 'justify-center': timelineJustifyCenter }"
      >
        @if (isProcessing) {
        <div class="order-timeline-item">
          <span class="date-label">Processing...</span>
        </div>
        } @else { @if (order.lastOccurrenceUtc) {
        <div class="order-timeline-item">
          <span>
            {{
              order.lastOccurrenceUtc
                | dateFormat : "MMM d" : siteSettings.utcOffset
            }}
          </span>
          <span class="date-label"> Last Order Date </span>
        </div>

        <div class="separator">
          <span class="material-symbols-outlined">chevron_right</span>
        </div>
        }

        <div class="order-timeline-item">
          <span>
            {{
              order.nextOccurrenceUtc
                | dateFormat : "MMM d" : siteSettings.utcOffset
            }}
          </span>
          @if (['active', 'failed', 'paused'].includes(order.status)) {
          <a
            class="date-label"
            href="javascript:void(0);"
            (click)="onClickReschedule()"
          >
            {{ nextOccurrenceVerboseName }} Date
          </a>
          } @else {
          <span class="date-label">{{ nextOccurrenceVerboseName }} Date </span>
          }
        </div>

        @if (useNextime && order.estimatedDeliveryDate && order.status ==
        'active') {
        <div class="separator">
          <span class="material-symbols-outlined">chevron_right</span>
        </div>

        <div class="order-timeline-item">
          <span>
            {{
              order.estimatedDeliveryDate
                | dateFormat : "MMM d" : siteSettings.utcOffset
            }}
          </span>
          <a
            class="date-label"
            href="javascript:void(0);"
            (click)="onClickShippingAddress()"
          >
            Delivers to {{ order.shippingPostcode }}
          </a>
        </div>
        } }
      </div>

      @if (!['processing', 'queued', 'deleted'].includes(order.status)) {
      <div>
        Change By Date:
        <span
          >before 11:59pm on
          {{
            changeLimitDate | dateFormat : "MMM d" : siteSettings.utcOffset
          }}</span
        >
      </div>
      }
    </div>

    @if (order.status != 'processing' && order.status != 'queued') {
    <div
      class="order-actions"
      [ngClass]="{
        'align-to-right': alignActionsToRight,
        'stacked-buttons': siteSettings.isStackedButtons
      }"
    >
      @if (order.status == 'active') {
      <md-filled-button (click)="openDialogWithRef(processNowDialog)"
        >Process Now</md-filled-button
      >
      <md-outlined-button (click)="onClickReschedule()">
        <span class="material-symbols-outlined">edit_calendar</span>
        <span>Reschedule</span>
      </md-outlined-button>
      <md-outlined-button (click)="onPause()">
        <span class="material-symbols-outlined pause">pause_circle</span>
        <span>Pause</span>
      </md-outlined-button>
      <md-outlined-button (click)="onCancel()">
        <span class="material-symbols-outlined cancel">cancel</span>
        <span>Cancel</span>
      </md-outlined-button>
      } @else if (order.status == 'paused') {
      <md-outlined-button (click)="onClickReschedule()">
        <span class="material-symbols-outlined">edit_calendar</span>
        <span>Reschedule</span>
      </md-outlined-button>
      <md-outlined-button (click)="onResume()">
        <span>Resume</span>
      </md-outlined-button>
      <md-outlined-button (click)="onCancel()">
        <span class="material-symbols-outlined cancel">cancel</span>
        <span>Cancel</span>
      </md-outlined-button>
      } @else if (order.status == 'failed') { @switch
      (order.processingErrorCode) { @case(1000) {
      <md-filled-button (click)="onClickAddProducts()"
        >Add Products</md-filled-button
      >
      <md-outlined-button (click)="onClickReschedule()">
        <span class="material-symbols-outlined">edit_calendar</span>
        <span>Reschedule</span>
      </md-outlined-button>
      <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
        >Retry Processing</md-outlined-button
      >
      <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
      } @case(1001) {
      <md-outlined-button (click)="onClickReschedule()">
        <span class="material-symbols-outlined">edit_calendar</span>
        <span>Reschedule</span>
      </md-outlined-button>
      <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
        >Retry Processing</md-outlined-button
      >
      <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
      } @case (1002) {
      <md-outlined-button (click)="onClickReschedule()">
        <span class="material-symbols-outlined">edit_calendar</span>
        <span>Reschedule</span>
      </md-outlined-button>
      <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
        >Retry Processing</md-outlined-button
      >
      } @case (1003) {
      <md-filled-button (click)="onClickPaymentMethod()"
        >Update Payment</md-filled-button
      >
      <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
        >Retry Processing</md-outlined-button
      >
      } @case (1004) {
      <md-filled-button (click)="onClickPaymentMethod()"
        >Update Payment</md-filled-button
      >
      <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
        >Retry Processing</md-outlined-button
      >
      } @case (2000) {
      <md-filled-button (click)="onClickPaymentMethod()"
        >Update Payment</md-filled-button
      >
      <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
        >Retry Processing</md-outlined-button
      >
      } @default {
      <md-outlined-button (click)="onClickReschedule()">
        <span class="material-symbols-outlined">edit_calendar</span>
        <span>Reschedule</span>
      </md-outlined-button>
      <md-outlined-button (click)="onPause()">Pause</md-outlined-button>
      <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
      } } } @else if (order.status == 'deleted') {
      <md-outlined-button (click)="onResume()">
        <span>Resume</span>
      </md-outlined-button>
      }
    </div>
    }

    <div class="order-data">
      <div class="frequency">
        Frequency:
        <a
          href="javascript:void(0);"
          [ngClass]="{ 'one-line-ellipsis': true, disabled: isProcessing }"
          (click)="isProcessing ? null : onChangeFrequency()"
        >
          <span>{{ order.frequencyDisplayName }}</span>
        </a>
        @if (!isProcessing) {
        <a
          href="javascript:void(0)"
          [ngClass]="{ disabled: isProcessing }"
          (click)="isProcessing ? null : onChangeFrequency()"
        >
          <span class="material-symbols-outlined">open_in_new</span>
        </a>
        }
      </div>

      <div class="payment-method">
        <span>Payment Method:</span>
        <a
          href="javascript:void(0)"
          [ngClass]="{ 'one-line-ellipsis': true, disabled: isProcessing }"
          (click)="isProcessing ? null : onClickPaymentMethod()"
          [title]="order.paymentMethod?.description"
        >
          <span>{{ order.paymentMethod?.description }}</span>
        </a>
        @if (!isProcessing) {
        <a
          href="javascript:void(0)"
          [ngClass]="{ disabled: isProcessing }"
          (click)="isProcessing ? null : onClickPaymentMethod()"
        >
          <span class="material-symbols-outlined">open_in_new</span>
        </a>
        }
      </div>

      <div class="shipping-address">
        <span>Shipping Address:</span>
        <a
          href="javascript:void(0);"
          [ngClass]="{ 'one-line-ellipsis': true, disabled: isProcessing }"
          (click)="isProcessing ? null : onClickShippingAddress()"
          [title]="addressDisplayName"
        >
          <span>{{ addressDisplayName }}</span>
        </a>
        @if (!isProcessing) {
        <a
          href="javascript:void(0);"
          [ngClass]="{ disabled: isProcessing }"
          (click)="isProcessing ? null : onClickShippingAddress()"
        >
          <span class="material-symbols-outlined">open_in_new</span>
        </a>
        }
      </div>
    </div>
  </div>

  <ng-template #processNowDialog>
    <lib-process-now-dialog
      [order]="order"
      [orderTotals$]="orderTotals$"
      [orderVerboseName]="orderVerboseName"
      [isRetry]="order.status == 'failed'"
      [useNextime]="useNextime"
      [nextimeToken]="nextimeToken"
      [nextimeSiteId]="nextimeSiteId"
      [siteSettings]="siteSettings"
      (confirm)="onProcessOrder()"
      [closestNextOccurrenceDate]="closestNextOccurrenceDate"
      [closestDeliveryDateUtc]="closestDeliveryDateUtc"
      (processNowDeliveryDateFetched)="onProcessNowDeliveryDateFetched($event)"
    />
  </ng-template>

  @if (useNextime && order.status == 'active') {
  <lib-nextime-wrapper
    [apiReadonlyToken]="nextimeToken"
    [order]="order"
    [nextimeSiteId]="nextimeSiteId"
    (dateUpdate)="onDeliveryDateUpdate($event)"
  />
  }
</div>
