import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NodDatePickerComponent } from '../nod-date-picker/nod-date-picker.component';
import { NodPickerConfig, QPilotSiteSettings } from '../types/site';
import { DateFormatPipe } from '../pipes/date-format-pipe';

@Component({
  selector: 'lib-reschedule-order',
  standalone: true,
  imports: [
    DateFormatPipe,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    NodDatePickerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideNativeDateAdapter()],
  templateUrl: './reschedule-order.component.html',
  styleUrl: './reschedule-order.component.scss',
})
export class RescheduledOrderLibComponent implements OnInit {
  @Input() orderId: number;
  @Input() nextOccurrenceUtc: string;
  @Input() nextOccurrenceOffset?: number;
  @Input() siteSettings: QPilotSiteSettings;
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  selectedNextOrderDate: Date;
  config: NodPickerConfig;
  date: Date;

  @ViewChild('picker', { read: undefined, static: false })
  datePicker: MatDatepicker<Date>;

  constructor() {}

  ngOnInit() {
    const utcOffset = this.siteSettings.utcOffset ?? 0;
    this.selectedNextOrderDate = new Date(this.nextOccurrenceUtc);
    this.date = this.getDateFromUtcOffsetForDisplay(
      this.selectedNextOrderDate,
      utcOffset
    );

    this.config = {
      lockedDays: this.siteSettings.lockWindow,
      supportsProcessingWindow: this.siteSettings.supportsProcessingWindow,
      processingStartTime: this.siteSettings.orderProcessingStartTime,
      processingEndTime: this.siteSettings.orderProcessingEndTime,
      siteProcessingOffset: this.siteSettings.orderProcessingOffset,
      offset: utcOffset,
      scheduledOrderProcessingOffset: this.nextOccurrenceOffset,
    };
  }

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    const utcOffset = this.siteSettings.utcOffset ?? 0;
    const adjustedDate = this.getDateFromUtcOffsetForDisplay(
      this.selectedNextOrderDate,
      -utcOffset
    );
    this.confirm.emit({
      orderId: this.orderId,
      nextOccurrenceUtc: adjustedDate,
    });
  }

  closeDatepicker() {
    this.datePicker.close();
  }

  onDateChanged($event: Date | null): void {
    if ($event) {
      const utcOffset = this.siteSettings.utcOffset ?? 0;
      this.selectedNextOrderDate = this.getDateFromUtcOffsetForDisplay(
        $event,
        -utcOffset
      );
    }
  }

  private getDateFromUtcOffsetForDisplay(date: Date, offset: number): Date {
    const adjustedDate = new Date(date.getTime());
    offset += new Date().getTimezoneOffset() / 60;
    adjustedDate.setTime(adjustedDate.getTime() + offset * 60 * 60 * 1000);
    return adjustedDate;
  }
}
