
<nextime-delivery-date
  [siteId]="nextimeSiteId"
  [apiReadonlyToken]="apiReadonlyToken"
  [orderDate]="orderDate"
  [currentDeliveryDate]="deliveryDate"
  [shippingAddress]="shippingAddress | json"
  [showCard]="false"
  [order]="orderObj | json"
  (onDateUpdate)="handleDateUpdate($event)"
/>

@if (dialogData) {
  <nextime-delivery-date
    #forDialog
    [siteId]="nextimeSiteId"
    [apiReadonlyToken]="apiReadonlyToken"
    [orderDate]="dialogData.orderDate"
    [currentDeliveryDate]="dialogData.deliveryDate"
    [shippingAddress]="shippingAddress | json"
    [showCard]="false"
    [order]="dialogData.orderObj| json"
    (onDateUpdate)="handleDateUpdate($event)"
  />
}

