import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date, format: string, utcOffset?: number): string | null;
  transform(value: string | undefined, format: string, utcOffset?: number): string | null;
  transform(value: Date | string | undefined, format: string, utcOffset?: number) {
      if (!value) return;
      const datePipe = new DatePipe("en-US");

      return datePipe.transform(
        value,
        format,
        utcOffset ? "GMT" + (utcOffset > 0 ? '+' + utcOffset.toString() : utcOffset.toString()) : "UTC"
      );
  }
}
