import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ChangeFrequencyComponent as ChangeFrequencyLibComponent,
  ChangeFrequencyEvent,
  QPilotScheduledOrder,
} from '@qpilot/subscriber-portal-web-component';
import { catchError, Observable, of, tap } from 'rxjs';

import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { errorSnackBar, successSnackBar } from '../utils';

@Component({
  selector: 'app-change-frequency',
  standalone: true,
  imports: [
    AsyncPipe,
    ChangeFrequencyLibComponent,
  ],
  templateUrl: './change-frequency.component.html',
  styleUrl: './change-frequency.component.scss'
})
export class ChangeFrequencyComponent implements OnInit {

  order$: Observable<QPilotScheduledOrder>;

  constructor(
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: {
      order: Observable<QPilotScheduledOrder>,
    }
  ) { }

  ngOnInit() {
    this.order$ = this.data.order ?? this.dataService.getCurrentOrder();
  }

  onCancel() {
    this.dialog.closeAll();
  }

  onConfirm({ orderId, frequency, frequencyType }: ChangeFrequencyEvent) {
    this.dialog.closeAll();
    this.qPilotCloudApiService
      .updateScheduledOrderFrequency(orderId, frequency, frequencyType)
      .pipe(
        tap(order => (
          this.dataService.updateOrderFrequency(
            order.id,
            order.frequency,
            order.frequencyType,
            order.frequencyDisplayName
          ))),
        tap(() => successSnackBar(this.snackBar, "Update: Frequency Changed")),
        catchError(() => {
          errorSnackBar(this.snackBar, "Update Failed: Error Updating Frequency");
          return of(null);
        }),
      ).subscribe()
  }
}
