<div class="change-frequency-wrapper">
  <h2>Change Frequency</h2>

  <h3>Order Frequency: {{ orderFrequencyDisplayName }}</h3>

  <div class="field-with-left-icon">
    <md-icon>cycle</md-icon>
    <mat-form-field>
      <mat-label>Frequency Type</mat-label>
      <mat-select
        [(value)]="selectedFrequencyType"
        (selectionChange)="getFormGroup()"
        name="selectedFrequencyType">
        @for (option of FREQUENCY_TYPE_OPTIONS; track option.value) {
          <mat-option [value]="option.value"> {{ option.viewValue }} </mat-option>
        }
      </mat-select>
      <mat-hint>Number of Days, Weeks, Months, Day of the Week or Day of the Month</mat-hint>
    </mat-form-field>
  </div>

  <form [formGroup]="frequencyFormGroup">
    <div class="field-with-left-icon">
      <md-icon>cycle</md-icon>
      <mat-form-field>
        <mat-label>Frequency</mat-label>
        @if(selectedFrequencyType === "DayOfTheWeek") {
          <mat-select [formControl]="frequencyFormGroup.controls.frequency" name="selectedFrequency">
            @for (option of WEEK_DAYS; track option; let i = $index) {
              <mat-option [value]="i + 1"> {{ option }} </mat-option>
            }
          </mat-select>
        } @else {
          <input matInput [formControl]="frequencyFormGroup.controls.frequency">
        }
        <mat-hint>{{ FREQUENCY_TYPE_HINTS[selectedFrequencyType]}}</mat-hint>
      </mat-form-field>
    </div>
  </form>

  <div class="action-buttons">
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    <md-filled-button
      [disabled]="frequencyFormGroup.invalid"
      (click)="onConfirm()">Confirm</md-filled-button>
  </div>
</div>
