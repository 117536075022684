import '@material/web/button/text-button';

import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { DateFormatPipe } from '../pipes/date-format-pipe';
import { QPilotScheduledOrder, QPilotScheduledOrderTotals } from '../types/scheduled-order';
import { QPilotSiteSettings } from './../types/site';

@Component({
  selector: 'lib-resume-dialog',
  standalone: true,
  imports: [
    DecimalPipe,
    CurrencyPipe,
    MatDialogModule,
    AsyncPipe,
    DateFormatPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './resume-dialog.component.html',
  styleUrl: './resume-dialog.component.scss'
})
export class ResumeDialogComponent implements OnInit {

  @Input() order: QPilotScheduledOrder;
  @Input() orderTotals$: Observable<QPilotScheduledOrderTotals>;
  @Input() orderVerboseName: string;
  @Input() siteSettings: QPilotSiteSettings;

  @Output() resumeOrder = new EventEmitter();
  @Output() processNow = new EventEmitter();

  savings$: Observable<{value: number}>;

  constructor() { }

  ngOnInit() {
    this.savings$ = this.orderTotals$.pipe(
      map(totals => ({ value: totals.itemsTotals.subTotal - totals.itemsTotals.total })),
    );
  }

  onResumeOrder() {
    this.resumeOrder.emit();
  }

  onProcessNow() {
    this.processNow.emit();
  }
}
