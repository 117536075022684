import { Component } from '@angular/core';
import { DataService } from '../services/data.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { AsyncPipe } from '@angular/common';
import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';
import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { ShippingAddressComponent } from '../web-components/shipping-address/shipping-address.component';
import { QPilotScheduledOrder } from '../web-components/types/scheduled-order';

@Component({
  selector: 'app-shipping-view',
  standalone: true,
  imports: [ShippingAddressComponent, MatDialogModule, AsyncPipe],
  templateUrl: './shipping-view.component.html',
  styleUrl: './shipping-view.component.scss',
})
export class ShippingViewComponent {
  constructor(
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {}

  order$: Observable<QPilotScheduledOrder>;
  dialogRef: MatDialogRef<DialogWrapperComponent>;

  ngOnInit() {
    this.order$ = this.dataService.getCurrentOrder();
  }

  operateAddress(orderId: number) {
    this.router.navigate(
      [{ outlets: { dialog: ['shipping-address', orderId] } }],
      { skipLocationChange: true }
    );
  }

  onCancel() {
    this.dialog.closeAll();
  }
}
