<lib-bar-tag
  [orderStatus]="orderStatus"
  [className]="'coupon'"
  [icon]="'sell'"
  [text]="coupon.formattedDescription ?? ''"
  [actionName]="'Remove'"
  [isActionClickable]="true"
  (clickedAction)="openDialogWithRef(removeDialog)"
/>

<ng-template #removeDialog>
  <h2 matDialogTitle>Remove Coupon?</h2>
  <mat-dialog-content>
      <p>{{ coupon.description }} will be removed from your {{orderVerboseName}}.</p>
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: flex-end;">
      <md-text-button form="form-id" value="cancel" matDialogClose>Cancel</md-text-button>
      <md-text-button form="form-id" value="delete" (click)="onDeleteCoupon()">Confirm</md-text-button>
  </mat-dialog-actions>
</ng-template>
