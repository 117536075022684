import '@material/web/button/filled-button';
import '@material/web/button/outlined-button';

import { CurrencyPipe, DecimalPipe, NgStyle } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import { QPilotProduct } from '../types/product';
import { QPilotScheduledOrder } from '../types/scheduled-order';

@Component({
  selector: 'lib-add-product-card',
  standalone: true,
  imports: [DecimalPipe, MatCardModule, NgStyle, MatDialogModule, CurrencyPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './add-product-card.component.html',
  styleUrl: './add-product-card.component.scss',
})
export class AddProductCardComponent implements OnInit, OnChanges {
  orderItemId: number;
  showQuantitySelector = false;
  productIsInOrder: boolean;
  quantity: number;
  quantityToAdd: number;
  isRecurring = true;
  dialogRef: MatDialogRef<any>;

  @Input() product: QPilotProduct;
  @Input() order: QPilotScheduledOrder;
  @Input() scheduledOrderVerboseName: string;
  @Output() add = new EventEmitter();
  @Output() addOneTime = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() changeCycles = new EventEmitter();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.orderItemId =
      this.order.scheduledOrderItems?.find(
        (item) => item.product?.id === this.product.id
      )?.id ?? 0;
  }

  ngOnChanges() {
    const productInOrder = this.order.scheduledOrderItems?.find(
      (item) => item.product?.id === this.product.id
    );

    this.productIsInOrder = productInOrder != null;
    this.quantity = productInOrder?.quantity ?? 1;
    this.quantityToAdd = this.quantity;
    this.isRecurring = productInOrder?.maxCycles !== 1;
  }

  onAdd() {
    this.add.emit({
      orderId: this.order.id,
      quantityToAdd: this.quantityToAdd,
    });
  }

  onAddOneTime() {
    this.addOneTime.emit({
      orderId: this.order.id,
      quantityToAdd: this.quantityToAdd,
    });
  }

  onUpdate() {
    this.update.emit({
      orderId: this.order.id,
      orderItemId: this.orderItemId,
      quantityToUpdate: this.quantityToAdd,
    });
  }

  onChangeCycles() {
    this.changeCycles.emit({
      orderId: this.order.id,
      orderItemId: this.orderItemId,
      cycles: this.isRecurring ? 'OneTime' : 'Recurring',
    });
    this.dialogRef.close();
  }

  openQuantitySelector() {
    this.showQuantitySelector = true;
  }

  closeQuantitySelector() {
    this.showQuantitySelector = false;
  }

  decrementNewQuantityToAdd() {
    if (this.quantityToAdd <= 1) return;
    this.quantityToAdd--;
  }

  incrementNewQuantityToAdd() {
    this.quantityToAdd++;
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(templateRef);
  }

  protected getProductImageUrl() {
    return (
      this.product?.imageUrl ??
      this.product?.metadata?.imageUrl ??
      this.product?.metadata?.imageThumbUrl ??
      ''
    );
  }
}
