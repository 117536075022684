<div class="reschedule-order-wrapper">
  <h2>Change Next Order Date</h2>

  <h3>
    Next Order Date:
    {{
      nextOccurrenceUtc | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
    }}
  </h3>

  <div class="field-with-left-icon">
    <md-icon>calendar_month</md-icon>
    <lib-nod-date-picker
      [date]="date"
      [config]="config"
      (dateChanged)="onDateChanged($event)"
    />
  </div>

  <div class="action-buttons">
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    <md-filled-button (click)="onConfirm()">Confirm</md-filled-button>
  </div>
</div>
