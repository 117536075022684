<div class="order-details-shipping-wrapper">
  <div class="shipping-header">
    <span class="shipping-header-title">
      Shipping & Delivery
    </span>

    @if ((isActive || isProcessing) && useNextime) {
      <a href="javascript:void(0);"
        [ngClass]="{'disabled': isProcessing}"
        (click)="isProcessing ? null : onChangeDeliveryDate()"
        >Change Delivery Date</a>
    } @else {
      <a href="javascript:void(0);"
      [ngClass]="{'disabled': isProcessing}"
      (click)="isProcessing ? null : onChangeShippingAddress()"
      >Change Shipping</a>
    }
  </div>

  <div class="shipping-info">
    <div class="address">
      <span>Shipping Address:</span>
      <span>{{ (order.shippingStreet1 ?? '') + ' ' + (order.shippingStreet2 ?? '') }}</span>
      <span>{{ order.shippingCity + ', ' + order.shippingState + ' ' + order.shippingPostcode }}</span>
      @if ((isActive || isProcessing) && useNextime) {
        <span>
          <a href="javascript:void(0);"
            [ngClass]="{'disabled': isProcessing}"
            (click)="isProcessing ? null : onChangeShippingAddress()"
          >Change Address</a>
        </span>
      }
    </div>

    @if((isActive || isProcessing) && useNextime && order.estimatedDeliveryDate) {
      <div class="delivery">
        <span>Next Delivery Date:</span>
        <div [ngClass]="{'date-info': true, 'processing': isProcessing}"
          (click)="isProcessing ? null : onChangeDeliveryDate()">
          <span class="weekday">{{ order.estimatedDeliveryDate | dateFormat: 'EEEE' : siteSettings.utcOffset }}</span>
          <span class="date">{{ order.estimatedDeliveryDate | dateFormat: 'MMM d' : siteSettings.utcOffset }}</span>
        </div>
      </div>
    }
  </div>

  @if ((isActive || isProcessing) && useNextime && order.shippingRateName) {
    <lib-bar-tag
      [orderStatus]="order.status"
      [text]="shippingTag"
      [className]="'shipping'"
      [icon]="'local_shipping'"
      [fillIcon]="true"
      [actionName]="shippingTotal"
      [isActionClickable]="false"
    />
  }
</div>
