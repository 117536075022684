import '@material/web/dialog/dialog';

import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { OrderFrequencyType } from '../types/scheduled-order';

@Component({
  selector: 'lib-change-frequency',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIcon,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './change-frequency.component.html',
  styleUrl: './change-frequency.component.scss',
})
export class ChangeFrequencyLibComponent implements OnInit {
  @Input() orderId: number;
  @Input() orderFrequency: number;
  @Input() orderFrequencyType: OrderFrequencyType;
  @Input() orderFrequencyDisplayName: string;

  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  selectedFrequencyType: OrderFrequencyType;

  frequencyFormGroup = new FormGroup({
    frequency: new FormControl<number | undefined>(undefined, []),
  });

  readonly FREQUENCY_TYPE_OPTIONS = [
    { value: 'Days', viewValue: 'Every (Number) of Days' },
    { value: 'Weeks', viewValue: 'Every (Number) of Weeks' },
    { value: 'Months', viewValue: 'Every (Number) of Months' },
    { value: 'DayOfTheWeek', viewValue: 'Every (Day) of the Week' },
    { value: 'DayOfTheMonth', viewValue: 'Every (Day) of the Month' },
  ];

  readonly FREQUENCY_TYPE_HINTS = {
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
    DayOfTheWeek: 'Day of the Week',
    DayOfTheMonth: 'Day of the Month',
  };

  readonly WEEK_DAYS = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  constructor() {}

  ngOnInit() {
    this.selectedFrequencyType = this.orderFrequencyType;
    this.getFormGroup();
  }

  getFormGroup() {
    const validators = [
      Validators.required,
      Validators.min(1),
      ...(this.selectedFrequencyType === 'DayOfTheWeek'
        ? [Validators.max(7)]
        : []),
      ...(this.selectedFrequencyType === 'DayOfTheMonth'
        ? [Validators.max(31)]
        : []),
    ];

    this.frequencyFormGroup = new FormGroup({
      frequency: new FormControl<number | undefined>(
        this.selectedFrequencyType === this.orderFrequencyType
          ? this.orderFrequency
          : undefined,
        [...validators]
      ),
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    this.confirm.emit({
      orderId: this.orderId,
      frequency: this.frequencyFormGroup.value.frequency,
      frequencyType: this.selectedFrequencyType,
    });
  }
}
