import { QPilotUser } from "./qpilot-user";

export class QPilotAccessToken {
  Id: number;
  UserId: number;
  TokenAuth: string;
  TokenBearerAuth: string;
  CreatedUtc: Date;
  UpdatedUtc: Date;
  ExpiresUtc: Date;
  User: QPilotUser;
  FirstLogin: number;
}