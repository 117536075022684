import '@material/web/button/filled-button';
import '@material/web/button/outlined-button';

import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';

import { QPilotSurvey } from '../types/surveys';
import { concat, defer, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'lib-survey',
  standalone: true,
  imports: [
    AsyncPipe,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    TextFieldModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './survey.component.html',
  styleUrl: './survey.component.scss'
})
export class SurveyComponent implements OnInit {

  @Input() survey: QPilotSurvey;

  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();

  chosenAnswerId: string;
  commentIsRequired: boolean;
  commentIsRequired$: Observable<{ value: boolean }>;
  answerComment: string | undefined;
  form = new FormGroup({
    chosenAnswerId: new FormControl<string | undefined>(undefined, [Validators.required]),
    answerComment: new FormControl<string | undefined>(undefined, {
      validators: [],
      updateOn: 'change',
    }),
  });
  formIsValid$: Observable<{ value: boolean }>;

  constructor() {}

  ngOnInit() {

    const formValue$ = concat(
      defer(() => of(this.form.value)),
      this.form.valueChanges
    );

    this.commentIsRequired$ = formValue$.pipe(
      map(x => x.chosenAnswerId),
      map(x => {
        if (x == null) return { value: false };
        const answer = this.survey.answers.find(a => a.id == x);
        return { value: answer?.type == 1 };
      }),
    );

    this.formIsValid$ = formValue$.pipe(
      map(x => x.answerComment?.trim().length ?? 0 ),
      switchMap(length => this.commentIsRequired$.pipe(
        map(isRequired => {
          return { value: this.form.valid && (!isRequired.value || (length > 0)) }
        },)
      )),
    );
  }

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    const chosenAnswerId = this.form.value.chosenAnswerId;
    const answerComment = this.form.value.answerComment;
    this.confirm.emit({ chosenAnswerId, answerComment });
  }
}
