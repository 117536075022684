import { NgStyle } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {
  QPilotScheduledOrder,
  QPilotScheduledOrderAmountDetail,
  QPilotScheduledOrderTotals,
} from '@qpilot/subscriber-portal-web-component';
import { Observable } from 'rxjs';

import { SiteSettingsService } from '../services/site-settings.service';

@Component({
  selector: 'app-dialog-wrapper',
  standalone: true,
  imports: [
    MatDialogModule,
    NgStyle,
    RouterModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './dialog-wrapper.component.html',
  styleUrl: './dialog-wrapper.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class DialogWrapperComponent implements OnInit {

  showCloseButton = false;

  constructor(
    private siteSettingsService: SiteSettingsService,
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<DialogWrapperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      showCloseButton: boolean,
      orderId?: number,
      order?: Observable<QPilotScheduledOrder>,
      orderTotals?: Observable<QPilotScheduledOrderTotals>,
      discounts?: Observable<QPilotScheduledOrderAmountDetail[]>,
    },
  ) { }

  ngOnInit() {
    this.showCloseButton = this.data.showCloseButton;

    const { styles: { customStyles } = {} } = this.siteSettingsService.siteSettings;
    if (customStyles) {
      const shadowRoot = this.elementRef.nativeElement.shadowRoot;
      const style = document.createElement('style');
      style.innerText = customStyles;
      shadowRoot.appendChild(style);
    }
  }

  onClose() {
    this.dialogRef.close();
  }
}
