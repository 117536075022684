<mat-card [ngClass]="{ 'mat-elevation-z0': true, collapsed: collapsed }">
  <mat-card-header (click)="toggleCollapse()">
    <div mat-card-avatar [ngClass]="order.status">
      <span class="material-symbols-outlined">{{ statusIcon }}</span>
    </div>
    <mat-card-title>
      {{ collapsed ? cardCollapsedDisplayName : cardExpandedDisplayName }}
    </mat-card-title>

    <mat-card-subtitle>
      <span>
        {{ itemsCount }}
        {{ itemsCount > 1 ? "Items" : "Item" }}
        Ship to {{ addressDisplayName }}
      </span>
    </mat-card-subtitle>
    <div class="card-header-product-thumbnail" [ngStyle]="thumbnailStyle"></div>
    <mat-icon
      class="menu-icon"
      aria-hidden="false"
      aria-label="Options"
      fontIcon="more_vert"
    />
  </mat-card-header>

  @if (!collapsed) {
  <div class="product-wrapper">
    @if (imageObject.length > 1) {

    <div
      style="
        width: 100%;
        height: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
      "
    >
      <ng-image-slider
        #nav
        [images]="imageObject"
        [infinite]="true"
        [autoSlide]="1"
        [manageImageRatio]="true"
        [sliderImageSize]="{ width: '100%', height: '100%' }"
        [imageSize]="{ width: '100%', height: '60%' }"
        slideImage="1"
      ></ng-image-slider>
    </div>

    } @else {
    <img [src]="getThumbnail()" class="img-product" />}
  </div>
  <mat-card-content>
    <div class="order-status">
      <span>{{ orderVerboseName }} Status: </span>
      <span [ngClass]="[order.status, 'highlight-status']">{{
        statusDisplayName
      }}</span>
    </div>

    @switch (order.status) { @case ("processing") {
    <p>
      Your {{ orderVerboseName }} is currently processing. Please wait to make
      changes until after your {{ orderVerboseName }} is processed.
    </p>
    } @case ("active") { @if (useNextime && hasDeliveryDate) {
    <p>
      Your next {{ orderVerboseName }} will be
      <a (click)="openDeliveryDateDialog()" href="javascript:void(0);"
        >delivered on
        {{
          order.estimatedDeliveryDate
            | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
        }}</a
      >. Changes can be made before 11:59pm on
      {{ changeLimitDate | dateFormat : "MMM d" : siteSettings.utcOffset }}.
    </p>
    } @else {
    <p>
      Your next {{ orderVerboseName }} will process on
      {{
        order.nextOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}. Changes to your {{ orderVerboseName }} can be made before 11:59pm on
      {{ changeLimitDate | dateFormat : "MMM d" : siteSettings.utcOffset }}.
    </p>
    } } @case ("paused") { Your {{ orderVerboseName }} was scheduled to process
    on
    {{
      order.nextOccurrenceUtc
        | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
    }}
    and is now Paused. You can
    <a href="javascript:void(0);" (click)="onRescheduleAndResume()"
      >change your Next Occurrence Date</a
    >
    or select
    <a href="javascript:void(0);" (click)="openDialogWithRef(resumeDialog)"
      >Resume</a
    >
    to un-pause. } @case ("failed") { @if ([1003, 1004,
    2000].includes(order.processingErrorCode)) {
    <lib-scheduled-order-payment-method
      [orderStatus]="order.status"
      [paymentMethod]="order.paymentMethod"
      [scheduledOrderId]="order.id"
      (changePayment)="onUpdatePayment()"
    />
    } @switch (order.processingErrorCode) { @case (99) {
    <p>
      Your {{ orderVerboseName }} failed to process on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}
      due to a technical error.
    </p>
    } @case (1000) {
    <p>
      Your {{ orderVerboseName }} failed to process on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}
      because it is empty. Please add product to resume.
    </p>
    } @case(1001) {
    <p>
      Your {{ orderVerboseName }} failed to process on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}
      because no items were available or in stock to ship.
    </p>
    } @case (1002) {
    <p>
      Your {{ orderVerboseName }} failed to process on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}
      due to a shipping issue.
    </p>
    } @case (1003) {
    <p>
      Your {{ orderVerboseName }} failed due to an unsupported payment type on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}.
    </p>
    } @case (1004) {
    <p>
      Your {{ orderVerboseName }} failed due to no payment method on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}. Please update your payment to resume.
    </p>
    } @case (2000) {
    <p>
      Your {{ orderVerboseName }} failed due to payment on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}. Please update your payment to resume.
    </p>
    } @default {
    <p>
      Your {{ orderVerboseName }} failed to process on
      {{
        order.lastOccurrenceUtc
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }}
      due to an order processing error ({{ order.processingErrorCode }}).
    </p>
    } } @if (order.dunning && order.dunning.isEnabled) {
    <p>{{ order.dunning.message }}</p>
    } } @case ("deleted") {
    <p>
      Your {{ orderVerboseName }} was canceled on
      {{
        order.lastChangeToDeleted
          | dateFormat : "MMM d, YYYY" : siteSettings.utcOffset
      }},
      <a href="javascript:void(0);" (click)="openDialogWithRef(resumeDialog)"
        >Would you like to resume?</a
      >
    </p>
    } }
  </mat-card-content>

  <mat-card-actions>
    @switch (order.status) { @case ("processing") {
    <md-filled-button (click)="onDetail()">View Details</md-filled-button>
    } @case ("active") {
    <md-filled-button (click)="onDetail()">Make Changes</md-filled-button>
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Process Now</md-outlined-button
    >
    <md-outlined-button (click)="onPause()">Pause</md-outlined-button>
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    } @case ("paused") {
    <md-filled-button (click)="openDialogWithRef(resumeDialog)"
      >Resume</md-filled-button
    >
    <md-outlined-button (click)="onDetail()">Make Changes</md-outlined-button>
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    } @case ("failed") { @switch (order.processingErrorCode) { @case (1000) {
    <md-filled-button (click)="onDetail()">Add Products</md-filled-button>
    <md-outlined-button (click)="onDetail()">Make Changes</md-outlined-button>
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Retry Processing</md-outlined-button
    >
    } @case (1001) {
    <md-filled-button (click)="onDetail()">Make Changes</md-filled-button>
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Retry Processing</md-outlined-button
    >
    <md-outlined-button (click)="onReschedule()">Reschedule</md-outlined-button>
    } @case (1002) {
    <md-filled-button (click)="onDetail()">Make Changes</md-filled-button>
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Retry Processing</md-outlined-button
    >
    } @case (1003) {
    <md-filled-button (click)="onUpdatePayment()"
      >Update Payment</md-filled-button
    >
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Retry Processing</md-outlined-button
    >
    } @case (1004) {
    <md-filled-button (click)="onUpdatePayment()"
      >Update Payment</md-filled-button
    >
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Retry Processing</md-outlined-button
    >
    } @case (2000) {
    <md-filled-button (click)="onUpdatePayment()"
      >Update Payment</md-filled-button
    >
    <md-outlined-button (click)="openDialogWithRef(processNowDialog)"
      >Retry Processing</md-outlined-button
    >
    } @default {
    <md-filled-button (click)="onDetail()">Make Changes</md-filled-button>
    <md-outlined-button (click)="onPause()">Pause</md-outlined-button>
    } }
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    } @case ("deleted") {
    <md-filled-button (click)="onDetail()">View Details</md-filled-button>
    } }
  </mat-card-actions>
  }
</mat-card>

<ng-template #processNowDialog>
  <lib-process-now-dialog
    [order]="order"
    [orderTotals$]="orderTotals$"
    [orderVerboseName]="orderVerboseName"
    [isRetry]="order.status == 'failed'"
    [useNextime]="useNextime"
    [nextimeToken]="nextimeToken"
    [nextimeSiteId]="nextimeSiteId"
    [siteSettings]="siteSettings"
    [closestNextOccurrenceDate]="order.closestNextOccurrenceDate"
    [closestDeliveryDateUtc]="closestDeliveryDateUtc"
    (confirm)="onProcessOrder()"
    (processNowDeliveryDateFetched)="onProcessNowDeliveryDateFetched($event)"
  />
</ng-template>

<ng-template #resumeDialog>
  <lib-resume-dialog
    [order]="order"
    [orderTotals$]="orderTotals$"
    [orderVerboseName]="orderVerboseName"
    [siteSettings]="siteSettings"
    (resumeOrder)="onResume()"
    (processNow)="onProcessOrder()"
  />
</ng-template>

@if (useNextime && order.status == 'active') {
<lib-nextime-wrapper
  [apiReadonlyToken]="nextimeToken"
  [order]="order"
  [nextimeSiteId]="nextimeSiteId"
  (dateUpdate)="onDeliveryDateUpdate($event)"
/>
}
