@if (order$ | async; as order) {
  <div>
    <lib-change-frequency
      #changeFrequency
      [orderId]="order.id"
      [orderFrequency]="order.frequency"
      [orderFrequencyType]="order.frequencyType"
      [orderFrequencyDisplayName]="order.frequencyDisplayName"
      (cancel)="onCancel()"
      (confirm)="onConfirm($event)"
    />
  </div>
}
