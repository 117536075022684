@if (savings$ | async; as savings) {
  <h2 matDialogTitle>Resume your {{ orderVerboseName }} now?</h2>
  <mat-dialog-content>
    <p>
      Your {{ orderVerboseName }} will resume with a Next Occurrence Date of {{ order.nextOccurrenceUtc | dateFormat: 'MMM d, YYYY' : siteSettings.utcOffset }}.
    </p>

    <p>
      You can also choose to “Process Now” if you'd like to resume and process your {{ orderVerboseName }} immediately.
    </p>

    @if (savings.value > 0) {
      <p>
        You are saving a total of {{ savings.value | number: '1.0-2' | currency:'USD':'symbol' }} with your {{ orderVerboseName }}.
      </p>
    }
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: flex-end;">
    <md-text-button form="form-id" value="cancel" matDialogClose>Cancel</md-text-button>
    <md-text-button form="form-id" value="process-now" (click)="onProcessNow()">Process Now</md-text-button>
    <md-text-button form="form-id" value="confirm" (click)="onResumeOrder()">Resume</md-text-button>
  </mat-dialog-actions>
}
