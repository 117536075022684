<div>
  <lib-pause-order
    [orderVerboseName]="siteSettingsService.orderVerboseName"
    [nextOccurrenceVerboseName]="siteSettingsService.nextOccurrenceVerboseName"
    [pauseSurvey$]="pauseSurvey$"
    (cancel)="onCancel()"
    (confirm)="onConfirm()"
    (answerOffer)="onAnswerOffer($event)"
  />
</div>

