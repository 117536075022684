import { ElementRef, Injectable } from '@angular/core';
import { auditTime, BehaviorSubject, distinctUntilChanged, fromEvent, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {

  private appElementRef = new BehaviorSubject<ElementRef | undefined>(undefined);
  appElementRef$ = this.appElementRef.asObservable();

  private viewPort = new BehaviorSubject<'small' | 'medium' | 'large'>('small');
  viewPort$ = this.viewPort.asObservable().pipe(
    distinctUntilChanged(),
  );

  constructor() { }

  setAppElementRef(ref: ElementRef) {
    this.appElementRef.next(ref);
  }

  setViewport(width: number) {
    if (width < 600 ) {
      this.viewPort.next('small');
    } else if (width < 768) {
      this.viewPort.next('medium');
    } else {
      this.viewPort.next('large');
    }
  }
}
