@if (order$ | async; as order) {
  <div>
    <lib-reschedule-order
      #rescheduleOrder
      [orderId]="order.id"
      [nextOccurrenceUtc]="order.nextOccurrenceUtc"
      [nextOccurrenceOffset]="order.nextOccurrenceOffset"
      (cancel)="onCancel()"
      (confirm)="onConfirm($event)"
      [siteSettings]="siteSettings"
    />
  </div>
}
