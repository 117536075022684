<div id="dialog-overlay">

  @if (showCloseButton) {
    <div (click)="onClose()" class="close-button">
      <mat-icon>close</mat-icon>
    </div>
  }

  <router-outlet name="dialog"></router-outlet>
</div>
