<div class="add-coupons-wrapper">
  <h2>Update Coupon</h2>

  <div class="order-coupons">
    <div class="coupons-header">
      <span class="title">Coupons</span>
    </div>
    <div class="coupons-bars">
      @for (coupon of discounts; track coupon.entityId) {
        <lib-scheduled-order-coupon
          class="coupon-bar"
          [coupon]="coupon"
          [orderVerboseName]="orderVerboseName"
          (deleteCoupon)="onDeleteCoupon($event)"
        />
      }
    </div>

    <mat-form-field class="coupon-form">
      <mat-label>Coupon Code</mat-label>
      <input matInput type="text" [(ngModel)]="couponCode" (keydown.enter)="tryToAddCoupon()">
      @if (couponCode) {
      <button matSuffix mat-icon-button aria-label="Coupon Code" (click)="tryToAddCoupon()">
        <mat-icon>send</mat-icon>
      </button>
      }
    </mat-form-field>
  </div>

  <div class="action-buttons">
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    <md-filled-button (click)="onCancel()">Confirm</md-filled-button>
  </div>
</div>
