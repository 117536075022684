export type QPilotSurveyResponse = {
  surveys: QPilotSurvey[];
  errors: any[];
}

export type QPilotSurvey = {
  id: number;
  siteId: number;
  name: string;
  isEnabled: boolean;
  isDeleted: boolean;
  actionTrigger: QPilotActionTriggerTypes;
  question: string;
  answers: QPilotSurveyAnswer[];
}

export type QPilotSurveyAnswer = {
  id: string;
  answer: string;
  offerApplicableItem: QPilotOfferApplicableItem;
  type: 0 | 1 | 2;
}

export type QPilotOfferApplicableItem = {
  id: string;
  offerId: string;
  itemId?: string;
  itemType: QPilotOfferTypes;
  phrase: string;
  tooltip: string;
}

export enum QPilotActionTriggerTypes {
  Pause = 0,
  Snooze = 1,
  Resume = 2,
  Cancel = 3
}

export enum QPilotOfferTypes {
  FreeProduct = 0,
  Discount = 1,
  Snooze = 2,
  ChangeFrequency = 3,
  NoOffer = 4,
  Skip = 5,
}

export enum QPilotOfferStatusTypes {
  Accepted = 0,
  Rejected = 1,
  Pending = 2
}

export type QPilotOffer = {
  id: string;
  phrase: string;
  siteId: number;
  status: QPilotOfferStatusTypes;
  items: QPilotOfferApplicableItem[];
  actionTrigger: QPilotActionTriggerTypes;
}
