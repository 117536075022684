import '@material/web/button/filled-button';
import '@material/web/button/outlined-button';

import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';

import { CardWithRadioButtonComponent } from '../card-with-radio-button/card-with-radio-button.component';
import { QPilotActionTriggerTypes, QPilotOfferApplicableItem, QPilotOfferTypes } from '../types/surveys';

@Component({
  selector: 'lib-offer',
  standalone: true,
  imports: [
    AsyncPipe,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    NgStyle,
    CardWithRadioButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './offer.component.html',
  styleUrl: './offer.component.scss'
})
export class OfferComponent implements OnInit {

  @Input() actionTrigger: QPilotActionTriggerTypes;
  @Input() offer: QPilotOfferApplicableItem;
  @Input() orderVerboseName: string;
  @Input() nextOccurrenceVerboseName: string;

  @Output() confirm = new EventEmitter<'accept'| 'deny'>();

  heading: string;
  actionName: string;
  offerTitle: string;
  offerSubtitle: string;
  selectedOption: 'accept' | 'deny';

  readonly QPilotOfferTypes = QPilotOfferTypes;

  ngOnInit() {
    this.heading = this.getHeading(this.offer.itemType);
    this.actionName = this.getActionName();

    const [offerTitle, offerSubtitle] = this.getOfferTitleAndSubtitle();

    this.offerTitle = offerTitle;
    this.offerSubtitle = offerSubtitle;
  }

  private getHeading(offerType: QPilotOfferTypes) {
    const actionsMap = new Map<QPilotActionTriggerTypes, string>([
      [QPilotActionTriggerTypes.Pause, 'pausing'],
      [QPilotActionTriggerTypes.Cancel, 'canceling'],
    ])

    if (offerType == QPilotOfferTypes.Discount)
        return `Instead of ${actionsMap.get(this.actionTrigger)}, we can offer you:`;

    return `Instead of ${actionsMap.get(this.actionTrigger)}, may we suggest:`;
  }

  private getActionName() {
    const actionsMap = new Map<QPilotActionTriggerTypes, string>([
      [QPilotActionTriggerTypes.Pause, 'Pause'],
      [QPilotActionTriggerTypes.Cancel, 'Cancel'],
    ])

    return actionsMap.get(this.actionTrigger) ?? '';
  }

  private getOfferTitleAndSubtitle() {
    if ([
      QPilotOfferTypes.FreeProduct,
      QPilotOfferTypes.Discount
    ].includes(this.offer.itemType))
      return [this.offer.phrase, this.offer.tooltip];

    if (this.offer.itemType == QPilotOfferTypes.Skip)
      return [
        'Skip this Cycle',
        `Your ${this.orderVerboseName}'s ${this.nextOccurrenceVerboseName} Date will be skipped`
      ];

    if (this.offer.itemType == QPilotOfferTypes.ChangeFrequency)
      return [
        'Change frequency',
        `You can selected a new frequency for your ${this.orderVerboseName}`,
      ];

    return '';
  }

  onConfirm() {
    this.confirm.emit(this.selectedOption);
  }
}
