
    <h3> {{heading}} </h3>

  <mat-radio-group class="radio-group" [(ngModel)]="selectedOption">

    <lib-card-with-radio-button
      [value]="'accept'"
      [selectedOption]="selectedOption">
      <div class="option">
        <div class="option-title">
          <p class="title"> {{ this.offerTitle }} </p>
          @if (selectedOption == 'accept') {
            <p class="subtitle"> {{ this.offerSubtitle }} </p>
          }
        </div>
        @if (offer.itemType == QPilotOfferTypes.Discount) {
          <span> Coupon </span>
        } @else if (offer.itemType == QPilotOfferTypes.Skip) {
          <span> Skip </span>
        }
      </div>
    </lib-card-with-radio-button>

    <lib-card-with-radio-button
      [value]="'deny'"
      [selectedOption]="selectedOption">
      <div class="option">
        <p> No. Just {{actionName}} my {{orderVerboseName}} </p>
        <span> {{actionName}} </span>
      </div>

    </lib-card-with-radio-button>
  </mat-radio-group>

  <div class="action-buttons">
    <md-filled-button (click)="onConfirm()">Confirm</md-filled-button>
  </div>
