import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import {
  DetailOrderEvent,
  OrderStatus,
  QPilotCalculateTotalsRequest,
  QPilotLocalizationKeys,
  QPilotScheduledOrder,
  QPilotScheduledOrderTotals,
  ResumeOrderEvent,
  ScheduledOrderCardComponent,
} from '@qpilot/subscriber-portal-web-component';
import moment from 'moment';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';
import { DataService } from '../services/data.service';
import { DeliveryService } from '../services/delivery.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { DeliveryDateUpdateEvent, errorSnackBar, successSnackBar } from '../utils';

type Order = QPilotScheduledOrder & {
  getOrderTotals: () => Observable<QPilotScheduledOrderTotals>;
  closestNextOccurrenceDate?: string;
  closestNextDeliveryDate?: string;
};

@Component({
  selector: 'app-orders-summary-view',
  standalone: true,
  imports: [
    ScheduledOrderCardComponent,
    AsyncPipe,
    MatDialogModule,
    RouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './orders-summary-view.component.html',
  styleUrl: './orders-summary-view.component.scss',
})
export class OrdersSummaryViewComponent implements OnInit {
  orders$: Observable<Order[]>;

  localizationKeys: QPilotLocalizationKeys;
  dialogRef: MatDialogRef<DialogWrapperComponent>;

  constructor(
    private qPilotCloudApiService: QPilotCloudApiService,
    protected siteSettingsService: SiteSettingsService,
    private router: Router,
    private dialog: MatDialog,
    private overlay: Overlay,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    protected deliveryService: DeliveryService,
  ) { }

  ngOnInit() {
    this.orders$ = this.dataService.orders$.pipe(
      map((orders) =>
        orders.map((order) => {
          const calculateTotalsRequest: QPilotCalculateTotalsRequest = {
            scheduledOrderId: order.id,
            customer: order.customer,
            scheduledOrderItems: order.scheduledOrderItems,
            scheduledOrderCoupons: order.couponsHistory,
            coupons: order.coupons,
            shippingCity: order.shippingCity,
            shippingCountry: order.shippingCountry,
            shippingPostcode: order.shippingPostcode,
            shippingState: order.shippingState,
            shippingStreet1: order.shippingStreet1,
            shippingStreet2: order.shippingStreet2,
            currencyIso: order.currencyIso,
            cycles: order.cycles!,
            total: order.total,
            preferredShippingRateOption: order.preferredShippingRateOption
          };
          const useNextime = this.siteSettingsService.useNextime;

          return {
            ...order,
            status: order.locked ? 'processing' : order.status,
            closestNextOccurrenceDate: useNextime ? moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : undefined,
            getOrderTotals: () =>
              this.qPilotCloudApiService.calculateScheduledOrderTotals(
                calculateTotalsRequest
              ),
          };
        })
      )
    );
  }

  onResume({ orderId }: ResumeOrderEvent) {
    this.qPilotCloudApiService.safeActivateScheduledOrder(orderId).pipe(
      tap(() => this.dataService.resumeOrder(orderId)),
      tap(() => {
        successSnackBar(this.snackBar, `Resumed ${this.siteSettingsService.orderVerboseName}`);
        this.dialog.closeAll();
      }),
      catchError(() => {
        errorSnackBar(this.snackBar, `Failed: Error resuming ${this.siteSettingsService.orderVerboseName}`);
        return of(null);
      })
    ).subscribe();
  }

  onDetail({ orderId }: DetailOrderEvent, closestNextOccurrenceDate?: string) {
    this.router.navigate([`order-detail/${orderId}`], {
      skipLocationChange: true,
      ...(closestNextOccurrenceDate
          ? { state: { closestNextOccurrenceDate } }
          : {}
        )
    });
  }

  onProcessOrder(order: QPilotScheduledOrder) {
    const { orderVerboseName } = this.siteSettingsService;
    this.qPilotCloudApiService
      .retryProcessing(order.id)
      .pipe(
        tap((updatedOrder) =>
          this.dataService.updateOrder({
            ...order,
            status: updatedOrder.status.toLowerCase() as OrderStatus,
            lastOccurrenceUtc: updatedOrder.lastOccurrenceUtc,
            nextOccurrenceUtc: updatedOrder.nextOccurrenceUtc,
          })
        ),
        tap(() => successSnackBar(this.snackBar, `${orderVerboseName} is Processing`)),
        catchError(() => {
          errorSnackBar(this.snackBar, `Failed: Error trying to process ${orderVerboseName}`)
          return of(null);
        })
      ).subscribe();
  }

  updateClosestDeliveryDate(event: DeliveryDateUpdateEvent, order: Order) {
    const detail = event.detail;
    if (!detail) return;
    const { deliveryDate } = detail;
    if (!deliveryDate) return;

    const deliveryDateUtc = moment(deliveryDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    order.closestNextDeliveryDate = deliveryDateUtc;
  }

  openDialog(
    routeName: string,
    orderId: number,
    args?: { resumeAfter?: boolean }
  ) {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const isMobileView = vw < 600;
    const config = isMobileView
      ? {
          maxWidth: '100vw',
          width: '100%',
          maxHeight: '100vh',
          height: '100%',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
        }
      : {
          maxWidth: `min(calc(100vw - 100px), 700px)`,
          maxHeight: `calc(100vh - 150px)`,
          width: '80%',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
        };

    this.dialogRef = this.dialog.open(DialogWrapperComponent, {
      ...config,
      data: {
        showCloseButton: true,
        order: this.orders$.pipe(
          map((orders) => orders.find((order) => order.id === orderId))
        ),
        orderId: orderId,
        resumeAfter: args?.resumeAfter,
      },
    });

    this.dialogRef
      .afterOpened()
      .pipe(
        tap(() => {
          this.router.navigate([{ outlets: { dialog: [routeName] } }], {
            skipLocationChange: true,
            state: { orderId },
          });
        })
      ).subscribe();

    this.dialogRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.router.navigate([{ outlets: { dialog: null } }], {
            skipLocationChange: true,
          });
        })
      ).subscribe();
  }
}
