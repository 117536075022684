<div class="survey-and-offer-header">
  <md-icon-button (click)="onGoBack()" slot="trailing-icon">
    <md-icon>arrow_back</md-icon>
  </md-icon-button>
  <h2> {{ navigationText }} </h2>
</div>

@if (showOffers) {
  <lib-offer
    [offer]="chosenAnswer.offerApplicableItem"
    [actionTrigger]="actionTrigger"
    [orderVerboseName]="orderVerboseName"
    [nextOccurrenceVerboseName]="nextOccurrenceVerboseName"
    (confirm)="onConfirm($event)"
  />
} @else {
  <lib-survey
    [survey]="survey"
    (confirm)="answerSurvey($event)"
    (cancel)="onCancel()"
  />
}
