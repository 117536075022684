import { Injectable } from '@angular/core';
import {
  QPilotStyleSettings,
  QPilotSiteSettings,
} from '../web-components/types/site';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService {
  orderVerboseName = 'Scheduled Order';
  orderVerboseNamePlural = 'Scheduled Orders';
  nextOccurrenceVerboseName = 'Next Occurrence';
  styles?: QPilotStyleSettings;
  paymentMethodsPageUrl: string;
  showCoupons: boolean;
  siteSettings: QPilotSiteSettings;
  useNextime = false;
  nextimeSiteId: number;
  nextimeToken = 'a1sd34f4fg5';

  constructor() {}

  setSiteSettings(
    siteSettings: QPilotSiteSettings & { nextimeSiteId?: string }
  ) {
    this.siteSettings = siteSettings;

    this.orderVerboseName =
      siteSettings.localization?.scheduledOrder ?? this.orderVerboseName;
    this.orderVerboseNamePlural =
      siteSettings.localization?.scheduledOrderPlural ??
      this.orderVerboseNamePlural;
    this.nextOccurrenceVerboseName =
      siteSettings.localization?.nextOccurrence ??
      this.nextOccurrenceVerboseName;

    this.styles = siteSettings.styles;
    this.paymentMethodsPageUrl = siteSettings.paymentMethodsPageUrl;
    this.showCoupons = siteSettings.isShowCoupons;

    if (siteSettings.nextimeSiteId && Number(siteSettings.nextimeSiteId)) {
      this.useNextime = true;
      this.nextimeSiteId = Number(siteSettings.nextimeSiteId);
    }
  }
}
