import '@material/web/dialog/dialog';

import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { QPilotPaymentMethod } from '../types/payment-method';
import { ChangePaymentAndProcessNowEvent, ChangePaymentEvent } from '../types/events';
import { AsyncPipe, CurrencyPipe, DecimalPipe, NgClass, NgStyle } from '@angular/common';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { BarTagComponent } from '../bar-tag/bar-tag.component';
import { OrderStatus, QPilotScheduledOrderTotals } from '../types/scheduled-order';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'lib-scheduled-order-payment-method',
  standalone: true,
  imports: [
    BarTagComponent,
    MatCard,
    MatCardContent,
    MatDialogModule,
    MatIcon,
    NgStyle,
    NgClass,
    AsyncPipe,
    DecimalPipe,
    CurrencyPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scheduled-order-payment-method.component.html',
  styleUrl: './scheduled-order-payment-method.component.scss',
})
export class ScheduledOrderPaymentMethodComponent implements OnChanges, OnInit {
  @Input() orderStatus: OrderStatus;
  @Input() paymentMethod?: QPilotPaymentMethod | undefined;
  @Input() scheduledOrderId: number;
  @Input() enableSelection: boolean = false;
  @Input() currentPaymentMethod: boolean = false;
  @Input() orderVerboseName: string;
  @Input() orderTotals$: Observable<QPilotScheduledOrderTotals>;
  @Output() changePayment = new EventEmitter();
  @Output() applyPayment = new EventEmitter<ChangePaymentEvent>();
  @Output() applyPaymentAndProcessNow = new EventEmitter<ChangePaymentAndProcessNowEvent>();

  result$: Observable<{savings: number, total: number}>;

  @ViewChild('applyDialog') paymentDialog: TemplateRef<any>;

  actionName: string;
  actionCallback: (() => void) | undefined;
  dialogRef: MatDialogRef<any>;

  constructor(private dialog: MatDialog, private overlay: Overlay) {}

  ngOnInit(): void {
     this.result$ = this.orderTotals$?.pipe(
      map(totals => ({ savings: totals.itemsTotals.subTotal - totals.itemsTotals.total, total: totals.total })),
    );
  }

  ngOnChanges() {
    if (this.enableSelection) {
      this.actionName = this.currentPaymentMethod ? 'Selected' : 'Apply';
      this.actionCallback = !this.currentPaymentMethod
        ? () => this.openDialogWithRef(this.paymentDialog)
        : undefined;
    } else {
      this.actionName = 'Change';
      this.actionCallback = () => this.onChangePayment();
    }
  }

  onChangePayment() {
    this.changePayment.emit();
  }

  onApplyPayment() {
    this.applyPayment.emit({
      scheduledOrderId: this.scheduledOrderId,
      paymentMethodId: this.paymentMethod!.id,
    } as ChangePaymentEvent);
    this.dialogRef.close();
  }

  applyAndProcess() {
    this.applyPaymentAndProcessNow.emit({
      scheduledOrderId: this.scheduledOrderId,
      paymentMethodId: this.paymentMethod!.id,
    } as ChangePaymentAndProcessNowEvent);
    this.dialogRef.close();
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(templateRef, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
  }
}
