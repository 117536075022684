@if (savings$ | async; as savings) {

  @if (isRetry) {
    <h2 matDialogTitle>Retry processing</h2>
  } @else {
    <h2 matDialogTitle>Process your {{orderVerboseName}} now?</h2>
  }

  <mat-dialog-content>
    @if (isRetry) {
      <p> Attempt to retry processing your {{orderVerboseName}} now?</p>
    } @else {
      <p> Attempt to process your {{orderVerboseName}} now?</p>
    }

    @if (deliverTo && useNextime) {
      <lib-nextime-wrapper
        [apiReadonlyToken]="nextimeToken"
        [order]="orderToFetchDeliveryDate"
        [nextimeSiteId]="nextimeSiteId"
        (dateUpdate)="onDeliveryDateFetched($event)"
      />

      @if (closestDeliveryDateUtc) {
        <p> Your {{orderVerboseName}} will be delivered to {{ deliverTo }}
          on {{ closestDeliveryDateUtc | dateFormat: 'EEEE, MMM d' : siteSettings.utcOffset }} </p>
      }
    }

    <p>
      Your payment method {{ order.paymentMethod?.description }} will be charged {{ order.total | number: '1.0-2' | currency:'USD':'symbol' }}
    </p>

    @if (savings.value > 0) {
      <p>
        You are saving a total of {{ savings.value | number: '1.0-2' | currency:'USD':'symbol' }} with your {{ orderVerboseName }}.
      </p>
    }
  </mat-dialog-content>
  <mat-dialog-actions style="justify-content: flex-end;">
    <md-text-button form="form-id" value="cancel" matDialogClose>Cancel</md-text-button>
    <md-text-button form="form-id" value="confirm" (click)="onConfirm()">Confirm</md-text-button>
  </mat-dialog-actions>
}
