import { Injectable } from '@angular/core';
import { Country, State } from '../types/address';

@Injectable({ providedIn: 'root' })
export class AddressHelper {

    public usStates: State[] = [
        {
            "name": "Alabama",
            "abbreviation": "AL"
        },
        {
            "name": "Alaska",
            "abbreviation": "AK"
        },
        {
            "name": "American Samoa",
            "abbreviation": "AS"
        },
        {
            "name": "Arizona",
            "abbreviation": "AZ"
        },
        {
            "name": "Arkansas",
            "abbreviation": "AR"
        },
        {
            "name": "California",
            "abbreviation": "CA"
        },
        {
            "name": "Colorado",
            "abbreviation": "CO"
        },
        {
            "name": "Connecticut",
            "abbreviation": "CT"
        },
        {
            "name": "Delaware",
            "abbreviation": "DE"
        },
        {
            "name": "District Of Columbia",
            "abbreviation": "DC"
        },
        {
            "name": "Federated States Of Micronesia",
            "abbreviation": "FM"
        },
        {
            "name": "Florida",
            "abbreviation": "FL"
        },
        {
            "name": "Georgia",
            "abbreviation": "GA"
        },
        {
            "name": "Guam",
            "abbreviation": "GU"
        },
        {
            "name": "Hawaii",
            "abbreviation": "HI"
        },
        {
            "name": "Idaho",
            "abbreviation": "ID"
        },
        {
            "name": "Illinois",
            "abbreviation": "IL"
        },
        {
            "name": "Indiana",
            "abbreviation": "IN"
        },
        {
            "name": "Iowa",
            "abbreviation": "IA"
        },
        {
            "name": "Kansas",
            "abbreviation": "KS"
        },
        {
            "name": "Kentucky",
            "abbreviation": "KY"
        },
        {
            "name": "Louisiana",
            "abbreviation": "LA"
        },
        {
            "name": "Maine",
            "abbreviation": "ME"
        },
        {
            "name": "Marshall Islands",
            "abbreviation": "MH"
        },
        {
            "name": "Maryland",
            "abbreviation": "MD"
        },
        {
            "name": "Massachusetts",
            "abbreviation": "MA"
        },
        {
            "name": "Michigan",
            "abbreviation": "MI"
        },
        {
            "name": "Minnesota",
            "abbreviation": "MN"
        },
        {
            "name": "Mississippi",
            "abbreviation": "MS"
        },
        {
            "name": "Missouri",
            "abbreviation": "MO"
        },
        {
            "name": "Montana",
            "abbreviation": "MT"
        },
        {
            "name": "Nebraska",
            "abbreviation": "NE"
        },
        {
            "name": "Nevada",
            "abbreviation": "NV"
        },
        {
            "name": "New Hampshire",
            "abbreviation": "NH"
        },
        {
            "name": "New Jersey",
            "abbreviation": "NJ"
        },
        {
            "name": "New Mexico",
            "abbreviation": "NM"
        },
        {
            "name": "New York",
            "abbreviation": "NY"
        },
        {
            "name": "North Carolina",
            "abbreviation": "NC"
        },
        {
            "name": "North Dakota",
            "abbreviation": "ND"
        },
        {
            "name": "Northern Mariana Islands",
            "abbreviation": "MP"
        },
        {
            "name": "Ohio",
            "abbreviation": "OH"
        },
        {
            "name": "Oklahoma",
            "abbreviation": "OK"
        },
        {
            "name": "Oregon",
            "abbreviation": "OR"
        },
        {
            "name": "Palau",
            "abbreviation": "PW"
        },
        {
            "name": "Pennsylvania",
            "abbreviation": "PA"
        },
        {
            "name": "Puerto Rico",
            "abbreviation": "PR"
        },
        {
            "name": "Rhode Island",
            "abbreviation": "RI"
        },
        {
            "name": "South Carolina",
            "abbreviation": "SC"
        },
        {
            "name": "South Dakota",
            "abbreviation": "SD"
        },
        {
            "name": "Tennessee",
            "abbreviation": "TN"
        },
        {
            "name": "Texas",
            "abbreviation": "TX"
        },
        {
            "name": "Utah",
            "abbreviation": "UT"
        },
        {
            "name": "Vermont",
            "abbreviation": "VT"
        },
        {
            "name": "Virgin Islands",
            "abbreviation": "VI"
        },
        {
            "name": "Virginia",
            "abbreviation": "VA"
        },
        {
            "name": "Washington",
            "abbreviation": "WA"
        },
        {
            "name": "West Virginia",
            "abbreviation": "WV"
        },
        {
            "name": "Wisconsin",
            "abbreviation": "WI"
        },
        {
            "name": "Wyoming",
            "abbreviation": "WY"
        }
    ];

    public countries: Country[] = [
        {
            "name": "United States",
            "alpha2": "US",
            "countryCode": "840"
        },
        {
            "name": "Afghanistan",
            "alpha2": "AF",
            "countryCode": "004"
        },
        {
            "name": "Åland Islands",
            "alpha2": "AX",
            "countryCode": "248"
        },
        {
            "name": "Albania",
            "alpha2": "AL",
            "countryCode": "008"
        },
        {
            "name": "Algeria",
            "alpha2": "DZ",
            "countryCode": "012"
        },
        {
            "name": "American Samoa",
            "alpha2": "AS",
            "countryCode": "016"
        },
        {
            "name": "Andorra",
            "alpha2": "AD",
            "countryCode": "020"
        },
        {
            "name": "Angola",
            "alpha2": "AO",
            "countryCode": "024"
        },
        {
            "name": "Anguilla",
            "alpha2": "AI",
            "countryCode": "660"
        },
        {
            "name": "Antarctica",
            "alpha2": "AQ",
            "countryCode": "010"
        },
        {
            "name": "Antigua and Barbuda",
            "alpha2": "AG",
            "countryCode": "028"
        },
        {
            "name": "Argentina",
            "alpha2": "AR",
            "countryCode": "032"
        },
        {
            "name": "Armenia",
            "alpha2": "AM",
            "countryCode": "051"
        },
        {
            "name": "Aruba",
            "alpha2": "AW",
            "countryCode": "533"
        },
        {
            "name": "Australia",
            "alpha2": "AU",
            "countryCode": "036"
        },
        {
            "name": "Austria",
            "alpha2": "AT",
            "countryCode": "040"
        },
        {
            "name": "Azerbaijan",
            "alpha2": "AZ",
            "countryCode": "031"
        },
        {
            "name": "Bahamas",
            "alpha2": "BS",
            "countryCode": "044"
        },
        {
            "name": "Bahrain",
            "alpha2": "BH",
            "countryCode": "048"
        },
        {
            "name": "Bangladesh",
            "alpha2": "BD",
            "countryCode": "050"
        },
        {
            "name": "Barbados",
            "alpha2": "BB",
            "countryCode": "052"
        },
        {
            "name": "Belarus",
            "alpha2": "BY",
            "countryCode": "112"
        },
        {
            "name": "Belgium",
            "alpha2": "BE",
            "countryCode": "056"
        },
        {
            "name": "Belize",
            "alpha2": "BZ",
            "countryCode": "084"
        },
        {
            "name": "Benin",
            "alpha2": "BJ",
            "countryCode": "204"
        },
        {
            "name": "Bermuda",
            "alpha2": "BM",
            "countryCode": "060"
        },
        {
            "name": "Bhutan",
            "alpha2": "BT",
            "countryCode": "064"
        },
        {
            "name": "Bolivia (Plurinational State of)",
            "alpha2": "BO",
            "countryCode": "068"
        },
        {
            "name": "Bonaire, Sint Eustatius and Saba",
            "alpha2": "BQ",
            "countryCode": "535"
        },
        {
            "name": "Bosnia and Herzegovina",
            "alpha2": "BA",
            "countryCode": "070"
        },
        {
            "name": "Botswana",
            "alpha2": "BW",
            "countryCode": "072"
        },
        {
            "name": "Bouvet Island",
            "alpha2": "BV",
            "countryCode": "074"
        },
        {
            "name": "Brazil",
            "alpha2": "BR",
            "countryCode": "076"
        },
        {
            "name": "British Indian Ocean Territory",
            "alpha2": "IO",
            "countryCode": "086"
        },
        {
            "name": "Brunei Darussalam",
            "alpha2": "BN",
            "countryCode": "096"
        },
        {
            "name": "Bulgaria",
            "alpha2": "BG",
            "countryCode": "100"
        },
        {
            "name": "Burkina Faso",
            "alpha2": "BF",
            "countryCode": "854"
        },
        {
            "name": "Burundi",
            "alpha2": "BI",
            "countryCode": "108"
        },
        {
            "name": "Cambodia",
            "alpha2": "KH",
            "countryCode": "116"
        },
        {
            "name": "Cameroon",
            "alpha2": "CM",
            "countryCode": "120"
        },
        {
            "name": "Canada",
            "alpha2": "CA",
            "countryCode": "124"
        },
        {
            "name": "Cabo Verde",
            "alpha2": "CV",
            "countryCode": "132"
        },
        {
            "name": "Cayman Islands",
            "alpha2": "KY",
            "countryCode": "136"
        },
        {
            "name": "Central African Republic",
            "alpha2": "CF",
            "countryCode": "140"
        },
        {
            "name": "Chad",
            "alpha2": "TD",
            "countryCode": "148"
        },
        {
            "name": "Chile",
            "alpha2": "CL",
            "countryCode": "152"
        },
        {
            "name": "China",
            "alpha2": "CN",
            "countryCode": "156"
        },
        {
            "name": "Christmas Island",
            "alpha2": "CX",
            "countryCode": "162"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "alpha2": "CC",
            "countryCode": "166"
        },
        {
            "name": "Colombia",
            "alpha2": "CO",
            "countryCode": "170"
        },
        {
            "name": "Comoros",
            "alpha2": "KM",
            "countryCode": "174"
        },
        {
            "name": "Congo",
            "alpha2": "CG",
            "countryCode": "178"
        },
        {
            "name": "Congo (Democratic Republic of the)",
            "alpha2": "CD",
            "countryCode": "180"
        },
        {
            "name": "Cook Islands",
            "alpha2": "CK",
            "countryCode": "184"
        },
        {
            "name": "Costa Rica",
            "alpha2": "CR",
            "countryCode": "188"
        },
        {
            "name": "Côte d'Ivoire",
            "alpha2": "CI",
            "countryCode": "384"
        },
        {
            "name": "Croatia",
            "alpha2": "HR",
            "countryCode": "191"
        },
        {
            "name": "Cuba",
            "alpha2": "CU",
            "countryCode": "192"
        },
        {
            "name": "Curaçao",
            "alpha2": "CW",
            "countryCode": "531"
        },
        {
            "name": "Cyprus",
            "alpha2": "CY",
            "countryCode": "196"
        },
        {
            "name": "Czech Republic",
            "alpha2": "CZ",
            "countryCode": "203"
        },
        {
            "name": "Denmark",
            "alpha2": "DK",
            "countryCode": "208"
        },
        {
            "name": "Djibouti",
            "alpha2": "DJ",
            "countryCode": "262"
        },
        {
            "name": "Dominica",
            "alpha2": "DM",
            "countryCode": "212"
        },
        {
            "name": "Dominican Republic",
            "alpha2": "DO",
            "countryCode": "214"
        },
        {
            "name": "Ecuador",
            "alpha2": "EC",
            "countryCode": "218"
        },
        {
            "name": "Egypt",
            "alpha2": "EG",
            "countryCode": "818"
        },
        {
            "name": "El Salvador",
            "alpha2": "SV",
            "countryCode": "222"
        },
        {
            "name": "Equatorial Guinea",
            "alpha2": "GQ",
            "countryCode": "226"
        },
        {
            "name": "Eritrea",
            "alpha2": "ER",
            "countryCode": "232"
        },
        {
            "name": "Estonia",
            "alpha2": "EE",
            "countryCode": "233"
        },
        {
            "name": "Ethiopia",
            "alpha2": "ET",
            "countryCode": "231"
        },
        {
            "name": "Falkland Islands (Malvinas)",
            "alpha2": "FK",
            "countryCode": "238"
        },
        {
            "name": "Faroe Islands",
            "alpha2": "FO",
            "countryCode": "234"
        },
        {
            "name": "Fiji",
            "alpha2": "FJ",
            "countryCode": "242"
        },
        {
            "name": "Finland",
            "alpha2": "FI",
            "countryCode": "246"
        },
        {
            "name": "France",
            "alpha2": "FR",
            "countryCode": "250"
        },
        {
            "name": "French Guiana",
            "alpha2": "GF",
            "countryCode": "254"
        },
        {
            "name": "French Polynesia",
            "alpha2": "PF",
            "countryCode": "258"
        },
        {
            "name": "French Southern Territories",
            "alpha2": "TF",
            "countryCode": "260"
        },
        {
            "name": "Gabon",
            "alpha2": "GA",
            "countryCode": "266"
        },
        {
            "name": "Gambia",
            "alpha2": "GM",
            "countryCode": "270"
        },
        {
            "name": "Georgia",
            "alpha2": "GE",
            "countryCode": "268"
        },
        {
            "name": "Germany",
            "alpha2": "DE",
            "countryCode": "276"
        },
        {
            "name": "Ghana",
            "alpha2": "GH",
            "countryCode": "288"
        },
        {
            "name": "Gibraltar",
            "alpha2": "GI",
            "countryCode": "292"
        },
        {
            "name": "Greece",
            "alpha2": "GR",
            "countryCode": "300"
        },
        {
            "name": "Greenland",
            "alpha2": "GL",
            "countryCode": "304"
        },
        {
            "name": "Grenada",
            "alpha2": "GD",
            "countryCode": "308"
        },
        {
            "name": "Guadeloupe",
            "alpha2": "GP",
            "countryCode": "312"
        },
        {
            "name": "Guam",
            "alpha2": "GU",
            "countryCode": "316"
        },
        {
            "name": "Guatemala",
            "alpha2": "GT",
            "countryCode": "320"
        },
        {
            "name": "Guernsey",
            "alpha2": "GG",
            "countryCode": "831"
        },
        {
            "name": "Guinea",
            "alpha2": "GN",
            "countryCode": "324"
        },
        {
            "name": "Guinea-Bissau",
            "alpha2": "GW",
            "countryCode": "624"
        },
        {
            "name": "Guyana",
            "alpha2": "GY",
            "countryCode": "328"
        },
        {
            "name": "Haiti",
            "alpha2": "HT",
            "countryCode": "332"
        },
        {
            "name": "Heard Island and McDonald Islands",
            "alpha2": "HM",
            "countryCode": "334"
        },
        {
            "name": "Holy See",
            "alpha2": "VA",
            "countryCode": "336"
        },
        {
            "name": "Honduras",
            "alpha2": "HN",
            "countryCode": "340"
        },
        {
            "name": "Hong Kong",
            "alpha2": "HK",
            "countryCode": "344"
        },
        {
            "name": "Hungary",
            "alpha2": "HU",
            "countryCode": "348"
        },
        {
            "name": "Iceland",
            "alpha2": "IS",
            "countryCode": "352"
        },
        {
            "name": "India",
            "alpha2": "IN",
            "countryCode": "356"
        },
        {
            "name": "Indonesia",
            "alpha2": "ID",
            "countryCode": "360"
        },
        {
            "name": "Iran (Islamic Republic of)",
            "alpha2": "IR",
            "countryCode": "364"
        },
        {
            "name": "Iraq",
            "alpha2": "IQ",
            "countryCode": "368"
        },
        {
            "name": "Ireland",
            "alpha2": "IE",
            "countryCode": "372"
        },
        {
            "name": "Isle of Man",
            "alpha2": "IM",
            "countryCode": "833"
        },
        {
            "name": "Israel",
            "alpha2": "IL",
            "countryCode": "376"
        },
        {
            "name": "Italy",
            "alpha2": "IT",
            "countryCode": "380"
        },
        {
            "name": "Jamaica",
            "alpha2": "JM",
            "countryCode": "388"
        },
        {
            "name": "Japan",
            "alpha2": "JP",
            "countryCode": "392"
        },
        {
            "name": "Jersey",
            "alpha2": "JE",
            "countryCode": "832"
        },
        {
            "name": "Jordan",
            "alpha2": "JO",
            "countryCode": "400"
        },
        {
            "name": "Kazakhstan",
            "alpha2": "KZ",
            "countryCode": "398"
        },
        {
            "name": "Kenya",
            "alpha2": "KE",
            "countryCode": "404"
        },
        {
            "name": "Kiribati",
            "alpha2": "KI",
            "countryCode": "296"
        },
        {
            "name": "Korea (Democratic People's Republic of)",
            "alpha2": "KP",
            "countryCode": "408"
        },
        {
            "name": "Korea (Republic of)",
            "alpha2": "KR",
            "countryCode": "410"
        },
        {
            "name": "Kuwait",
            "alpha2": "KW",
            "countryCode": "414"
        },
        {
            "name": "Kyrgyzstan",
            "alpha2": "KG",
            "countryCode": "417"
        },
        {
            "name": "Lao People's Democratic Republic",
            "alpha2": "LA",
            "countryCode": "418"
        },
        {
            "name": "Latvia",
            "alpha2": "LV",
            "countryCode": "428"
        },
        {
            "name": "Lebanon",
            "alpha2": "LB",
            "countryCode": "422"
        },
        {
            "name": "Lesotho",
            "alpha2": "LS",
            "countryCode": "426"
        },
        {
            "name": "Liberia",
            "alpha2": "LR",
            "countryCode": "430"
        },
        {
            "name": "Libya",
            "alpha2": "LY",
            "countryCode": "434"
        },
        {
            "name": "Liechtenstein",
            "alpha2": "LI",
            "countryCode": "438"
        },
        {
            "name": "Lithuania",
            "alpha2": "LT",
            "countryCode": "440"
        },
        {
            "name": "Luxembourg",
            "alpha2": "LU",
            "countryCode": "442"
        },
        {
            "name": "Macao",
            "alpha2": "MO",
            "countryCode": "446"
        },
        {
            "name": "Macedonia (the former Yugoslav Republic of)",
            "alpha2": "MK",
            "countryCode": "807"
        },
        {
            "name": "Madagascar",
            "alpha2": "MG",
            "countryCode": "450"
        },
        {
            "name": "Malawi",
            "alpha2": "MW",
            "countryCode": "454"
        },
        {
            "name": "Malaysia",
            "alpha2": "MY",
            "countryCode": "458"
        },
        {
            "name": "Maldives",
            "alpha2": "MV",
            "countryCode": "462"
        },
        {
            "name": "Mali",
            "alpha2": "ML",
            "countryCode": "466"
        },
        {
            "name": "Malta",
            "alpha2": "MT",
            "countryCode": "470"
        },
        {
            "name": "Marshall Islands",
            "alpha2": "MH",
            "countryCode": "584"
        },
        {
            "name": "Martinique",
            "alpha2": "MQ",
            "countryCode": "474"
        },
        {
            "name": "Mauritania",
            "alpha2": "MR",
            "countryCode": "478"
        },
        {
            "name": "Mauritius",
            "alpha2": "MU",
            "countryCode": "480"
        },
        {
            "name": "Mayotte",
            "alpha2": "YT",
            "countryCode": "175"
        },
        {
            "name": "Mexico",
            "alpha2": "MX",
            "countryCode": "484"
        },
        {
            "name": "Micronesia (Federated States of)",
            "alpha2": "FM",
            "countryCode": "583"
        },
        {
            "name": "Moldova (Republic of)",
            "alpha2": "MD",
            "countryCode": "498"
        },
        {
            "name": "Monaco",
            "alpha2": "MC",
            "countryCode": "492"
        },
        {
            "name": "Mongolia",
            "alpha2": "MN",
            "countryCode": "496"
        },
        {
            "name": "Montenegro",
            "alpha2": "ME",
            "countryCode": "499"
        },
        {
            "name": "Montserrat",
            "alpha2": "MS",
            "countryCode": "500"
        },
        {
            "name": "Morocco",
            "alpha2": "MA",
            "countryCode": "504"
        },
        {
            "name": "Mozambique",
            "alpha2": "MZ",
            "countryCode": "508"
        },
        {
            "name": "Myanmar",
            "alpha2": "MM",
            "countryCode": "104"
        },
        {
            "name": "Namibia",
            "alpha2": "NA",
            "countryCode": "516"
        },
        {
            "name": "Nauru",
            "alpha2": "NR",
            "countryCode": "520"
        },
        {
            "name": "Nepal",
            "alpha2": "NP",
            "countryCode": "524"
        },
        {
            "name": "Netherlands",
            "alpha2": "NL",
            "countryCode": "528"
        },
        {
            "name": "New Caledonia",
            "alpha2": "NC",
            "countryCode": "540"
        },
        {
            "name": "New Zealand",
            "alpha2": "NZ",
            "countryCode": "554"
        },
        {
            "name": "Nicaragua",
            "alpha2": "NI",
            "countryCode": "558"
        },
        {
            "name": "Niger",
            "alpha2": "NE",
            "countryCode": "562"
        },
        {
            "name": "Nigeria",
            "alpha2": "NG",
            "countryCode": "566"
        },
        {
            "name": "Niue",
            "alpha2": "NU",
            "countryCode": "570"
        },
        {
            "name": "Norfolk Island",
            "alpha2": "NF",
            "countryCode": "574"
        },
        {
            "name": "Northern Mariana Islands",
            "alpha2": "MP",
            "countryCode": "580"
        },
        {
            "name": "Norway",
            "alpha2": "NO",
            "countryCode": "578"
        },
        {
            "name": "Oman",
            "alpha2": "OM",
            "countryCode": "512"
        },
        {
            "name": "Pakistan",
            "alpha2": "PK",
            "countryCode": "586"
        },
        {
            "name": "Palau",
            "alpha2": "PW",
            "countryCode": "585"
        },
        {
            "name": "Palestine, State of",
            "alpha2": "PS",
            "countryCode": "275"
        },
        {
            "name": "Panama",
            "alpha2": "PA",
            "countryCode": "591"
        },
        {
            "name": "Papua New Guinea",
            "alpha2": "PG",
            "countryCode": "598"
        },
        {
            "name": "Paraguay",
            "alpha2": "PY",
            "countryCode": "600"
        },
        {
            "name": "Peru",
            "alpha2": "PE",
            "countryCode": "604"
        },
        {
            "name": "Philippines",
            "alpha2": "PH",
            "countryCode": "608"
        },
        {
            "name": "Pitcairn",
            "alpha2": "PN",
            "countryCode": "612"
        },
        {
            "name": "Poland",
            "alpha2": "PL",
            "countryCode": "616"
        },
        {
            "name": "Portugal",
            "alpha2": "PT",
            "countryCode": "620"
        },
        {
            "name": "Puerto Rico",
            "alpha2": "PR",
            "countryCode": "630"
        },
        {
            "name": "Qatar",
            "alpha2": "QA",
            "countryCode": "634"
        },
        {
            "name": "Réunion",
            "alpha2": "RE",
            "countryCode": "638"
        },
        {
            "name": "Romania",
            "alpha2": "RO",
            "countryCode": "642"
        },
        {
            "name": "Russian Federation",
            "alpha2": "RU",
            "countryCode": "643"
        },
        {
            "name": "Rwanda",
            "alpha2": "RW",
            "countryCode": "646"
        },
        {
            "name": "Saint Barthélemy",
            "alpha2": "BL",
            "countryCode": "652"
        },
        {
            "name": "Saint Helena, Ascension and Tristan da Cunha",
            "alpha2": "SH",
            "countryCode": "654"
        },
        {
            "name": "Saint Kitts and Nevis",
            "alpha2": "KN",
            "countryCode": "659"
        },
        {
            "name": "Saint Lucia",
            "alpha2": "LC",
            "countryCode": "662"
        },
        {
            "name": "Saint Martin (French part)",
            "alpha2": "MF",
            "countryCode": "663"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "alpha2": "PM",
            "countryCode": "666"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "alpha2": "VC",
            "countryCode": "670"
        },
        {
            "name": "Samoa",
            "alpha2": "WS",
            "countryCode": "882"
        },
        {
            "name": "San Marino",
            "alpha2": "SM",
            "countryCode": "674"
        },
        {
            "name": "Sao Tome and Principe",
            "alpha2": "ST",
            "countryCode": "678"
        },
        {
            "name": "Saudi Arabia",
            "alpha2": "SA",
            "countryCode": "682"
        },
        {
            "name": "Senegal",
            "alpha2": "SN",
            "countryCode": "686"
        },
        {
            "name": "Serbia",
            "alpha2": "RS",
            "countryCode": "688"
        },
        {
            "name": "Seychelles",
            "alpha2": "SC",
            "countryCode": "690"
        },
        {
            "name": "Sierra Leone",
            "alpha2": "SL",
            "countryCode": "694"
        },
        {
            "name": "Singapore",
            "alpha2": "SG",
            "countryCode": "702"
        },
        {
            "name": "Sint Maarten (Dutch part)",
            "alpha2": "SX",
            "countryCode": "534"
        },
        {
            "name": "Slovakia",
            "alpha2": "SK",
            "countryCode": "703"
        },
        {
            "name": "Slovenia",
            "alpha2": "SI",
            "countryCode": "705"
        },
        {
            "name": "Solomon Islands",
            "alpha2": "SB",
            "countryCode": "090"
        },
        {
            "name": "Somalia",
            "alpha2": "SO",
            "countryCode": "706"
        },
        {
            "name": "South Africa",
            "alpha2": "ZA",
            "countryCode": "710"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "alpha2": "GS",
            "countryCode": "239"
        },
        {
            "name": "South Sudan",
            "alpha2": "SS",
            "countryCode": "728"
        },
        {
            "name": "Spain",
            "alpha2": "ES",
            "countryCode": "724"
        },
        {
            "name": "Sri Lanka",
            "alpha2": "LK",
            "countryCode": "144"
        },
        {
            "name": "Sudan",
            "alpha2": "SD",
            "countryCode": "729"
        },
        {
            "name": "Suriname",
            "alpha2": "SR",
            "countryCode": "740"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "alpha2": "SJ",
            "countryCode": "744"
        },
        {
            "name": "Swaziland",
            "alpha2": "SZ",
            "countryCode": "748"
        },
        {
            "name": "Sweden",
            "alpha2": "SE",
            "countryCode": "752"
        },
        {
            "name": "Switzerland",
            "alpha2": "CH",
            "countryCode": "756"
        },
        {
            "name": "Syrian Arab Republic",
            "alpha2": "SY",
            "countryCode": "760"
        },
        {
            "name": "Taiwan, Province of China",
            "alpha2": "TW",
            "countryCode": "158"
        },
        {
            "name": "Tajikistan",
            "alpha2": "TJ",
            "countryCode": "762"
        },
        {
            "name": "Tanzania, United Republic of",
            "alpha2": "TZ",
            "countryCode": "834"
        },
        {
            "name": "Thailand",
            "alpha2": "TH",
            "countryCode": "764"
        },
        {
            "name": "Timor-Leste",
            "alpha2": "TL",
            "countryCode": "626"
        },
        {
            "name": "Togo",
            "alpha2": "TG",
            "countryCode": "768"
        },
        {
            "name": "Tokelau",
            "alpha2": "TK",
            "countryCode": "772"
        },
        {
            "name": "Tonga",
            "alpha2": "TO",
            "countryCode": "776"
        },
        {
            "name": "Trinidad and Tobago",
            "alpha2": "TT",
            "countryCode": "780"
        },
        {
            "name": "Tunisia",
            "alpha2": "TN",
            "countryCode": "788"
        },
        {
            "name": "Turkey",
            "alpha2": "TR",
            "countryCode": "792"
        },
        {
            "name": "Turkmenistan",
            "alpha2": "TM",
            "countryCode": "795"
        },
        {
            "name": "Turks and Caicos Islands",
            "alpha2": "TC",
            "countryCode": "796"
        },
        {
            "name": "Tuvalu",
            "alpha2": "TV",
            "countryCode": "798"
        },
        {
            "name": "Uganda",
            "alpha2": "UG",
            "countryCode": "800"
        },
        {
            "name": "Ukraine",
            "alpha2": "UA",
            "countryCode": "804"
        },
        {
            "name": "United Arab Emirates",
            "alpha2": "AE",
            "countryCode": "784"
        },
        {
            "name": "United Kingdom of Great Britain and Northern Ireland",
            "alpha2": "GB",
            "countryCode": "826"
        },
        {
            "name": "United States Minor Outlying Islands",
            "alpha2": "UM",
            "countryCode": "581"
        },
        {
            "name": "Uruguay",
            "alpha2": "UY",
            "countryCode": "858"
        },
        {
            "name": "Uzbekistan",
            "alpha2": "UZ",
            "countryCode": "860"
        },
        {
            "name": "Vanuatu",
            "alpha2": "VU",
            "countryCode": "548"
        },
        {
            "name": "Venezuela (Bolivarian Republic of)",
            "alpha2": "VE",
            "countryCode": "862"
        },
        {
            "name": "Viet Nam",
            "alpha2": "VN",
            "countryCode": "704"
        },
        {
            "name": "Virgin Islands (British)",
            "alpha2": "VG",
            "countryCode": "092"
        },
        {
            "name": "Virgin Islands (U.S.)",
            "alpha2": "VI",
            "countryCode": "850"
        },
        {
            "name": "Wallis and Futuna",
            "alpha2": "WF",
            "countryCode": "876"
        },
        {
            "name": "Western Sahara",
            "alpha2": "EH",
            "countryCode": "732"
        },
        {
            "name": "Yemen",
            "alpha2": "YE",
            "countryCode": "887"
        },
        {
            "name": "Zambia",
            "alpha2": "ZM",
            "countryCode": "894"
        },
        {
            "name": "Zimbabwe",
            "alpha2": "ZW",
            "countryCode": "716"
        }
    ];
}
