<div class="shipping-form-wrapper">
  <form [formGroup]="addressFormGroup" class="shipping-form">
    <div class="form-row">
      <lib-country-select
        placeholder="Shipping Country"
        [inputFormControl]="addressFormGroup.controls.country" />
    </div>

    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Full Name (First and Last Name)</mat-label>
      <input matInput [formControl]="addressFormGroup.controls.fullName" required placeholder="Full Name">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Phone number</mat-label>
      <input matInput [formControl]="addressFormGroup.controls.phoneNumber" placeholder="Phone number">
    </mat-form-field>

    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>Street Address 1</mat-label>
      <input matInput [formControl]="addressFormGroup.controls.street1" required placeholder="Street Address 1">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Street Address 2</mat-label>
      <input matInput [formControl]="addressFormGroup.controls.street2" placeholder="Street Address 2">
    </mat-form-field>

    <mat-form-field hideRequiredMarker appearance="outline">
      <mat-label>City</mat-label>
      <input matInput [formControl]="addressFormGroup.controls.city" required placeholder="City">
    </mat-form-field>

    <div class="form-row">
      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>State</mat-label>
        <input matInput [formControl]="addressFormGroup.controls.state" required placeholder="State">
      </mat-form-field>

      <mat-form-field hideRequiredMarker appearance="outline">
        <mat-label>Postal (Zip) Code</mat-label>
        <input matInput [formControl]="addressFormGroup.controls.postalCode" required placeholder="Postal (Zip) Code">
      </mat-form-field>
    </div>
  </form>

  <div class="action-buttons">
    <md-outlined-button (click)="onCancel()">Cancel</md-outlined-button>
    <md-filled-button
      [disabled]="addressFormGroup.invalid"
      (click)="onUpdate()">Update</md-filled-button>
  </div>
</div>
