@if (order$ | async; as order) {
    <div>
      <lib-cancel-order
        [order]="order"
        [orderVerboseName]="siteSettingsService.orderVerboseName"
        [nextOccurrenceVerboseName]="siteSettingsService.nextOccurrenceVerboseName"
        [cancelSurvey$]="cancelSurvey$"
        (cancel)="onCancel()"
        (confirmCancel)="onConfirm()"
        (answerOffer)="onAnswerOffer($event)"
      />
    </div>
  }
