@if (order$ | async; as order) {
  @if (paymentMethods$ | async; as paymentMethods) {
    <lib-change-payment-method
      [orderStatus]="order.status"
      [orderVerboseName]="orderVerboseName"
      [paymentMethods]="paymentMethods"
      [scheduledOrder]="order"
      [currentPaymentMethodId]="order.paymentMethodId"
      [paymentMethodsUrl]="paymentMethodsUrl"
      (apply)="onApply($event)"
      (applyAndProcess)="onApplyAndProcess($event)"
      (cancel)="onCancel()"
    />
  }
}
