import '@material/web/button/text-button';
import '@material/web/dialog/dialog';
import '@material/web/icon/icon';
import '@material/web/iconbutton/outlined-icon-button';

import { Overlay } from '@angular/cdk/overlay';
import { CurrencyPipe, DecimalPipe, NgClass, NgStyle } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import { QPilotScheduledOrderItem } from './../types/scheduled-order-item';
import { BarTagComponent } from '../bar-tag/bar-tag.component';
import { OrderStatus } from '../types/scheduled-order';
import { QPilotSiteSettings } from '../types/site';

@Component({
  selector: 'lib-scheduled-order-details-product',
  standalone: true,
  imports: [
    DecimalPipe,
    MatBadgeModule,
    NgStyle,
    FormsModule,
    MatDialogModule,
    BarTagComponent,
    NgClass,
    CurrencyPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scheduled-order-details-product.component.html',
  styleUrl: './scheduled-order-details-product.component.scss',
})
export class ScheduledOrderDetailsProductComponent implements OnChanges {
  @Input() orderItem: QPilotScheduledOrderItem;
  @Input() orderVerboseName: string;
  @Input() orderStatus: OrderStatus;
  @Input() siteSettings: QPilotSiteSettings;
  @Output() updateQuantity = new EventEmitter();
  @Output() deleteOrderItem = new EventEmitter();
  @Output() editOrderItem = new EventEmitter();
  @Output() changeQuantity = new EventEmitter();

  showQuantitySelector = false;
  quantityToUpdate: number;
  priceOutcome: number | null;
  salePriceOutcome: number | null;
  savePercentage: number | null;
  saveTotal: number | null;
  isOneTimeOnly: boolean;
  showBars: boolean;
  dialogRef: MatDialogRef<any>;
  isProcessing: boolean;
  styles: { backgroundImage: string };
  isOutOfStock = false;

  constructor(private dialog: MatDialog, private overlay: Overlay) {}

  ngOnInit() {
    const { salePrice, price } = this.orderItem;
    this.savePercentage =
      !!salePrice && !!price ? (100 * price - 100 * salePrice) / price : null;
    this.showBars = this.isOneTimeOnly || !!this.savePercentage;
    this.styles = this.getItemBackgroundImage(this.orderItem);
    this.isOutOfStock = this.orderItem.product?.availability === 'OutOfStock'
  }

  ngOnChanges() {
    this.calculatePriceOutcome();

    this.quantityToUpdate = this.orderItem?.quantity ?? 0;
    this.isOneTimeOnly =
      this.orderItem.minCycles === 0 && this.orderItem.maxCycles === 1;
    this.showBars = this.isOneTimeOnly || !!this.savePercentage;
    this.isProcessing = ['processing', 'queued'].includes(this.orderStatus);

    this.saveTotal =
      !!this.salePriceOutcome && !!this.priceOutcome
        ? this.priceOutcome - this.salePriceOutcome
        : null;
  }

  private calculatePriceOutcome() {
    if (!this.orderItem.quantity) return;

    this.priceOutcome = this.orderItem.price
      ? this.orderItem.quantity * this.orderItem.price
      : null;

    this.salePriceOutcome = this.orderItem.salePrice
      ? this.orderItem.quantity * this.orderItem.salePrice
      : null;
  }

  openQuantitySelector() {
    this.showQuantitySelector = true;
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(templateRef, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
  }

  resetQuantitySelector() {
    this.showQuantitySelector = false;

    if (this.quantityToUpdate == this.orderItem?.quantity) return;

    this.quantityToUpdate = this.orderItem.quantity ?? 0;
    this.emitChangeQuantity();
  }

  incrementQuantityToUpdate() {
    this.quantityToUpdate += 1;
    this.emitChangeQuantity();
  }

  decrementQuantityToUpdate() {
    if (this.quantityToUpdate <= 1) return;
    this.quantityToUpdate -= 1;
    this.emitChangeQuantity();
  }

  onUpdateQuantity() {
    if (this.orderItem.quantity === this.quantityToUpdate) return;
    this.updateQuantity.emit({
      orderItemId: this.orderItem.id,
      quantityToUpdate: this.quantityToUpdate,
    });
    this.showQuantitySelector = false;
  }

  onDeleteOrderItem() {
    this.deleteOrderItem.emit({
      orderItemId: this.orderItem.id,
    });
    this.dialogRef.close();
  }

  onEditOrderItem() {
    this.editOrderItem.emit({
      orderItemId: this.orderItem.id,
    });
  }

  private getItemBackgroundImage({ product }: QPilotScheduledOrderItem) {
    return {
      backgroundImage: `url('${
        product?.imageUrl ??
        product?.metadata?.imageUrl ??
        product?.metadata?.imageThumbUrl ??
        ''
      }')`,
    };
  }

  emitChangeQuantity() {
    this.changeQuantity.emit({
      orderItemId: this.orderItem.id,
      quantityToUpdate: this.quantityToUpdate,
    });
  }
}
