import { Component, EventEmitter, Input, Output } from '@angular/core';

import { QPilotScheduledOrder } from './../types/scheduled-order';
import { QPilotSiteSettings } from './../types/site';
import { DateFormatPipe } from '../pipes/date-format-pipe';

@Component({
  selector: 'lib-scheduled-order-details-status',
  standalone: true,
  imports: [DateFormatPipe],
  templateUrl: './scheduled-order-details-status.component.html',
  styleUrl: './scheduled-order-details-status.component.scss'
})
export class ScheduledOrderDetailsStatusComponent {

  @Input() order: QPilotScheduledOrder
  @Input() orderVerboseName: string;
  @Input() changeLimitDate: Date;
  @Input() hasDeliveryDate: boolean;
  @Input() useNextime: boolean;
  @Input() siteSettings: QPilotSiteSettings;

  @Output() openDeliveryDateDialog = new EventEmitter();
  @Output() rescheduleAndResume = new EventEmitter();
  @Output() resume = new EventEmitter();

  constructor(){}

  emitOpenDeliveryDateDialog() {
    this.openDeliveryDateDialog.emit();
  }

  emitRescheduleAndResume() {
    this.rescheduleAndResume.emit();
  }

  emitResume() {
    this.resume.emit();
  }
}
