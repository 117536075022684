import { QPilotCustomer } from "./customer";
import { QPilotPaymentMethod } from "./payment-method";
import { QPilotScheduledOrderCoupon } from "./scheduled-order-coupon";
import { QPilotScheduledOrderItem } from "./scheduled-order-item";

export type OrderStatus =
  | 'active'
  | 'failed'
  | 'paused'
  | 'processing'
  | 'deleted'
  | 'queued';

export type OrderFrequencyType =
  | 'Days'
  | 'Weeks'
  | 'Months'
  | 'DayOfTheWeek'
  | 'DayOfTheMonth';

export class QPilotScheduledOrder {
  id: number;
  customerId?: number;
  customer?: QPilotCustomer;
  status: OrderStatus;
  scheduledOrderItems?: QPilotScheduledOrderItem[];
  frequency: number;
  frequencyType: OrderFrequencyType;
  frequencyDisplayName: string;
  lastOccurrenceUtc: string;
  nextOccurrenceUtc: string;
  nextOccurrenceOffset?: number;
  paymentMethodId?: number;
  paymentMethod?: QPilotPaymentMethod;
  processingErrorCode: number;
  phoneNumber: string;
  shippingFirstName?: string;
  shippingLastName?: string;
  shippingStreet1?: string;
  shippingStreet2?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingPostcode?: string;
  shippingCountry?: string;
  shippingRateName?: string;
  shippingTotal?: number;
  coupons?: string[];
  couponsHistory?: QPilotScheduledOrderCoupon[];
  currencyIso?: string;
  subtotal: number;
  total: number;
  updatedUtc: string;
  lastChangeToDeleted?: string;
  cycles?: number;
  preferredShippingRateOption?: string;
  dunning?: QPilotScheduledOrderDunning;
  locked: boolean;
  estimatedDeliveryDate: string | undefined;
  siteId: number;
}

export class QPilotScheduledOrderDunning {
  isEnabled: boolean;
  message?: string;
}

export class QPilotScheduledOrderTotals {
  itemsTotals: QPilotScheduledOrderItemsTotals;
  shippingTotals: QPilotScheduledOrderShippingTotals;
  taxTotals: QPilotScheduledOrderTaxesTotals;
  subTotal: number;
  total: number;
}

export class QPilotScheduledOrderItemsTotals {
  total: number;
  subTotal: number;
  discountTotal: number;
  couponDiscounts: QPilotScheduledOrderAmountDetail[];
}

export class QPilotScheduledOrderShippingTotals {
  subTotal: QPilotScheduledOrderAmountDetail;
  couponDiscounts: QPilotScheduledOrderAmountDetail[];
  total: number;
}

export class QPilotScheduledOrderTaxesTotals {
  taxes: QPilotScheduledOrderAmountDetail[];
  total: number;
}

export class QPilotScheduledOrderAmountDetail {
  entityId: number;
  formattedDescription?: string;
  description?: string;
  amount: number;
  subtotalDiscountAppliesToRegularPrice: boolean;
}

export class QPilotCalculateTotalsRequest {
  scheduledOrderId: number;
  customer?: QPilotCustomer;
  scheduledOrderItems?: QPilotScheduledOrderItem[];
  scheduledOrderCoupons?: QPilotScheduledOrderCoupon[];
  coupons?: string[];
  shippingStreet1?: string;
  shippingStreet2?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingPostcode?: string;
  shippingCountry?: string;
  currencyIso?: string;
  cycles: number;
  total: number;
  preferredShippingRateOption?: string;
}

const STATUS_DISPLAY_NAME_MAP = new Map<string, string>([
  ['processing', 'Processing...'],
  ['queued', 'Processing...'],
  ['deleted', 'Canceled'],
]);

const STATUS_FAILED_DISPLAY_NAME_MAP = new Map<number, string>([
  [99, 'Technical Error'],
  [1000, 'Empty'],
  [1001, 'No Items to Ship'],
  [1002, 'Shipping'],
  [1003, 'Payment Unsupported'],
  [1004, 'No Payment Method'],
  [2000, 'Payment'],

]);

export function getStatusDisplayName({
  status
}: QPilotScheduledOrder): string {
  return STATUS_DISPLAY_NAME_MAP.get(status) ?? status.replace(/^\w/, (c) => c.toUpperCase());
}

export function getFailedReason(failCode: number) {
  return STATUS_FAILED_DISPLAY_NAME_MAP.get(failCode) ?? `Processing Error ${failCode}`;
}

export function getChangeLimitDate({ nextOccurrenceUtc }: QPilotScheduledOrder) {
  const nextOccurrenceUtcDate = new Date(nextOccurrenceUtc);
  const changeBefore = new Date(nextOccurrenceUtcDate.getTime());
  changeBefore.setDate(nextOccurrenceUtcDate.getDate() - 1);
  return changeBefore;
}

export function getAddressDisplayName({
  shippingStreet1,
  shippingStreet2,
  shippingCity,
  shippingState,
  shippingPostcode,
  shippingCountry,
}: QPilotScheduledOrder) {
  let address = `${shippingStreet1 ?? ''}`;
  if (shippingStreet2) address += ` ${shippingStreet2 ?? ''}`;
  if (shippingCity) address += `, ${shippingCity}`;
  if (shippingState) address += ` ${shippingState}`;
  if (shippingPostcode) address += `, ${shippingPostcode}`;
  if (shippingCountry) address += `, ${shippingCountry}`;

  return address;
}

