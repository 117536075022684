import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingControl = new BehaviorSubject<number>(0);
  loadingControl$ = this.loadingControl.asObservable();

  isLoading$: Observable<boolean>;

  constructor() {
    this.isLoading$ = this.loadingControl$.pipe(map((x) => x > 0));
  }

  setIsLoading() {
    this.loadingControl.next(this.loadingControl.value + 1);
  }

  setLoaded() {
    this.loadingControl.next(this.loadingControl.value - 1);
  }
}
