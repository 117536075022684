import '@nextime/delivery-date';

import { DatePipe, JsonPipe } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import { QPilotScheduledOrder } from '../types/scheduled-order';

type ShippingAddress = {
  //name: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
};

type OrderItem = {
  id: number;
  productId: string;
  qty: number;
  regularPrice: number;
  salePrice: number;
};

type Order = {
  orderId: string;
  items: OrderItem[];
};

@Component({
  selector: 'lib-nextime-wrapper',
  standalone: true,
  imports: [JsonPipe, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
  templateUrl: './nextime-wrapper.component.html',
  styleUrl: './nextime-wrapper.component.scss',
})
export class NextimeWrapperComponent implements OnChanges {
  @Input() apiReadonlyToken: string;
  @Input() order: QPilotScheduledOrder;
  @Input() nextimeSiteId: number;
  @Output() dateUpdate = new EventEmitter();

  @ViewChild('forDialog') forDialog: ElementRef;

  orderObj: Order;
  siteId: number;
  orderDate: string;
  deliveryDate: string | null;
  shippingAddress: ShippingAddress;
  requestDialog = false;
  dialogData: {
    orderObj: Order;
    orderDate: string;
    deliveryDate: string;
  };

  constructor(private ref: ElementRef, private datePipe: DatePipe) {}

  ngOnChanges() {
    this.orderDate =
      this.datePipe.transform(
        this.order.nextOccurrenceUtc,
        "yyyy-MM-dd'T'HH:mm:ssZZZZZ"
      ) ?? '';
    this.deliveryDate = this.order.estimatedDeliveryDate
      ? this.datePipe.transform(
          this.order.estimatedDeliveryDate,
          "yyyy-MM-dd'T'HH:mm:ssZZZZZ"
        )
      : null;

    this.shippingAddress = {
      street: `${this.order.shippingStreet1 ?? ''} ${
        this.order.shippingStreet2 ?? ''
      }`.trim(),
      city: this.order.shippingCity ?? '',
      state: this.order.shippingState ?? '',
      country: this.order.shippingCountry ?? '',
      postalCode: this.order.shippingPostcode ?? '',
    };
    this.orderObj = {
      orderId: this.order.id.toString(),
      items:
        this.order.scheduledOrderItems?.map((item) => ({
          id: item.id,
          productId: item.productId?.toString() ?? '',
          qty: item.quantity ?? 0,
          salePrice: item.salePrice ?? 0,
          regularPrice: item.price ?? 0,
        })) ?? [],
    };

    const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd') ?? '';
    this.dialogData = {
      orderDate: today + 'T00:00:00Z',
      deliveryDate: today + 'T00:00:00Z',
      orderObj: {
        orderId: this.order.id.toString(),
        items:
          this.order.scheduledOrderItems?.map((item) => ({
            id: item.id,
            productId: item.productId?.toString() ?? '',
            qty: item.quantity ?? 0,
            salePrice: item.salePrice ?? 0,
            regularPrice: item.price ?? 0,
          })) ?? [],
      },
    };
  }

  async openDeliveryDateDialog() {
    const nextimeEl = this.forDialog.nativeElement;
    if (!nextimeEl) return;
    const modalEl = nextimeEl.shadowRoot
      .querySelector('delivery-card')
      .shadowRoot.querySelector('delivery-modal');
    const loadedStyles =
      Array.from<Element>(modalEl.shadowRoot.children).find(
        (el) => el.tagName.toLowerCase() == 'style'
      ) != undefined;

    if (!loadedStyles) {
      const style = document.createElement('style');
      style.innerText = `@media screen and (min-width: 600px) {
        .mdc-dialog.mdc-dialog--open .mdc-dialog__container,
        .mdc-dialog.mdc-dialog--closing .mdc-dialog__container {
          transform: scale(0.85);
        }
        .delivery-modal.mdc-dialog.mdc-dialog--open .mdc-dialog__container .mdc-dialog__surface {
          border-radius: 4px;
        }
      }`;
      modalEl.shadowRoot.appendChild(style);
    }

    await nextimeEl.openDialog();
  }

  handleDateUpdate(event: Event) {
    this.dateUpdate.emit(event);
  }
}
