import { Routes } from '@angular/router';

import { AddProductListComponent } from './add-product-list/add-product-list.component';
import { AddProductComponent } from './add-product/add-product.component';
import { ChangeFrequencyComponent } from './change-frequency/change-frequency.component';
import { OrderDetailViewComponent } from './order-detail-view/order-detail-view.component';
import { OrdersSummaryViewComponent } from './orders-summary-view/orders-summary-view.component';
import { RescheduleOrderComponent } from './reschedule-order/reschedule-order.component';
import { UpdateCouponsComponent } from './update-coupons/update-coupons.component';
import { ShippingViewComponent } from './shipping-view/shipping-view.component';
import { ChangeShippingComponent } from './change-shipping/change-shipping.component';
import { UpdatePaymentComponent } from './update-payment/update-payment.component';
import { ChangeStatusToCancelComponent } from './change-status-to-cancel/change-status-to-cancel.component';
import { ChangeStatusToPauseComponent } from './change-status-to-pause/change-status-to-pause.component';

export const routes: Routes = [
  {
    path: 'orders',
    component: OrdersSummaryViewComponent,
  },
  {
    path: 'order-detail/:orderId',
    component: OrderDetailViewComponent,
  },
  {
    path: 'update-coupons',
    component: UpdateCouponsComponent,
    outlet: 'dialog',
  },
  {
    path: 'cancel-order',
    component: ChangeStatusToCancelComponent,
    outlet: 'dialog',
  },
  {
    path: 'pause-order',
    component: ChangeStatusToPauseComponent,
    outlet: 'dialog',
  },
  {
    path: 'update-payment',
    component: UpdatePaymentComponent,
    outlet: 'dialog',
  },
  {
    path: 'add-products',
    component: AddProductListComponent,
    outlet: 'dialog',
  },
  {
    path: 'add-products/:productId',
    component: AddProductComponent,
    outlet: 'dialog',
  },
  {
    path: 'change-frequency',
    component: ChangeFrequencyComponent,
    outlet: 'dialog',
  },
  {
    path: 'reschedule-order',
    component: RescheduleOrderComponent,
    outlet: 'dialog',
  },
  {
    path: 'shipping-address',
    component: ShippingViewComponent,
    outlet: 'dialog',
  },
  {
    path: 'shipping-address/:orderId',
    component: ChangeShippingComponent,
    outlet: 'dialog',
  },
];
