<div class="product-item">
  <div class="product-item-info">
    <div
      [ngClass]="{ 'is-out-of-stock': isOutOfStock }"
      class="product-image"
      [matBadge]="orderItem.quantity"
      [ngStyle]="styles"
      (click)="onEditOrderItem()"
    ></div>
    <div class="product-details">
      <div class="product-title" [ngClass]="{ 'text-disabled': isOutOfStock }">
        {{ orderItem.product?.title }}
      </div>
      <div class="product-item-price">
        @if(!!salePriceOutcome) {
        <span class="price" [ngClass]="{ 'text-disabled': isOutOfStock }">{{
          salePriceOutcome | number : "1.0-2" | currency : "USD" : "symbol"
        }}</span>
        <span
          [ngClass]="{ 'text-disabled': isOutOfStock }"
          class="striked-price"
          >{{
            priceOutcome | number : "1.0-2" | currency : "USD" : "symbol"
          }}</span
        >
        } @else {
        <span class="price" [ngClass]="{ 'text-disabled': isOutOfStock }">{{
          priceOutcome | number : "1.0-2" | currency : "USD" : "symbol"
        }}</span>
        }
      </div>
      <div class="product-item-actions">
        <div class="product-item-quantity">
          <a
            href="javascript:void(0);"
            [ngClass]="{ disabled: isProcessing }"
            (click)="isProcessing ? null : openQuantitySelector()"
          >
            Quantity: {{ orderItem.quantity }}
          </a>

          @if (showQuantitySelector) {
          <div class="quantity-selector">
            <md-outlined-icon-button
              (click)="decrementQuantityToUpdate()"
              aria-label="Decrement quantity"
            >
              <md-icon>remove</md-icon>
            </md-outlined-icon-button>
            <span>{{ quantityToUpdate }}</span>
            <md-outlined-icon-button
              (click)="incrementQuantityToUpdate()"
              aria-label="Increment quantity"
            >
              <md-icon>add</md-icon>
            </md-outlined-icon-button>
          </div>
          }
        </div>

        <a
          href="javascript:void(0);"
          [ngClass]="{ disabled: isProcessing }"
          (click)="isProcessing ? null : openDialogWithRef(removeItemsDialog)"
        >
          Remove
        </a>

        @if (showQuantitySelector) {
        <div class="action-buttons">
          <md-outlined-button (click)="resetQuantitySelector()"
            >Cancel</md-outlined-button
          >
          <md-filled-button
            (click)="onUpdateQuantity()"
            [disabled]="orderItem.quantity === quantityToUpdate"
          >
            Update
          </md-filled-button>
        </div>
        }
      </div>
    </div>
  </div>
  @if (isOutOfStock) {
  <lib-bar-tag
    [oneLineEllipsis]="false"
    [className]="'item-out-of-stock'"
    [icon]="'Block'"
    text="Out of Stock: This item will not be included until more stock is available."
    [spanStyle]="{ marginLeft: '8px', fontWeight: '500' }"
  />
  } @if (showBars && !isOutOfStock) {
  <div class="bars">
    @if (!!savePercentage) {
    <lib-bar-tag
      [className]="'autoship-save'"
      [icon]="'sell'"
      actionName="-{{
        saveTotal | number : '1.0-2' | currency : 'USD' : 'symbol'
      }}"
      [isActionClickable]="false"
      text="{{
        siteSettings.localization?.autoshipAndSave
          ? siteSettings.localization?.autoshipAndSave
          : 'Autoship & Save'
      }} {{ savePercentage | number : '1.0-2' }}%"
    />
    } @if (isOneTimeOnly) {
    <lib-bar-tag
      [className]="'one-time'"
      [icon]="'restart_alt'"
      text="One-Time Only: Included on next (1) {{ orderVerboseName }}"
      [actionName]="'Change'"
      [isActionClickable]="true"
      (clickedAction)="onEditOrderItem()"
    />
    }
  </div>
  }

  <ng-template #removeItemsDialog>
    <h2 matDialogTitle>Remove Items?</h2>
    <mat-dialog-content>
      <p>
        {{ orderItem.product?.title }} will be removed from your
        {{ orderVerboseName }}.
      </p>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: flex-end">
      <md-text-button form="form-id" value="cancel" matDialogClose
        >Cancel</md-text-button
      >
      <md-text-button
        form="form-id"
        value="confirm"
        (click)="onDeleteOrderItem()"
        >Confirm</md-text-button
      >
    </mat-dialog-actions>
  </ng-template>
</div>
