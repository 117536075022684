import '@material/web/button/outlined-button';

import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import {
  CancelOrderEvent,
  ChangeOrderItemQuantityEvent,
  DeleteCouponEvent,
  DeleteOrderItemEvent,
  OrderStatus,
  PauseOrderEvent,
  QPilotCalculateTotalsRequest,
  QPilotScheduledOrder,
  QPilotScheduledOrderAmountDetail,
  QPilotScheduledOrderItem,
  QPilotScheduledOrderTotals,
  ResumeOrderEvent,
  ScheduledOrderCouponComponent,
  ScheduledOrderDetailsComponent,
  ScheduledOrderDetailsProductComponent,
  ScheduledOrderPaymentMethodComponent,
  ScheduledOrderShippingComponent,
  ScheduledOrderSummaryComponent,
  UpdateOrderItemQuantityEvent,
} from '@qpilot/subscriber-portal-web-component';
import { catchError, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';

import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';
import { DeliveryService } from '../services/delivery.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { DeliveryDateUpdateEvent, errorSnackBar, successSnackBar } from '../utils';
import { DataService } from './../services/data.service';
import { ViewportService } from '../services/viewport.service';
import moment from 'moment';

type Order = QPilotScheduledOrder & {
  getOrderTotals: () => Observable<QPilotScheduledOrderTotals>;
  closestNextDeliveryDate?: string;
};
@Component({
  selector: 'app-order-detail-view',
  standalone: true,
  imports: [
    ScheduledOrderDetailsComponent,
    ScheduledOrderDetailsProductComponent,
    ScheduledOrderShippingComponent,
    ScheduledOrderSummaryComponent,
    RouterModule,
    AsyncPipe,
    MatDialogModule,
    ScheduledOrderCouponComponent,
    ScheduledOrderPaymentMethodComponent,
    NgClass,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './order-detail-view.component.html',
  styleUrl: './order-detail-view.component.scss',
})
export class OrderDetailViewComponent implements OnInit {
  order$: Observable<Order>;
  orderTotals$: Observable<QPilotScheduledOrderTotals>;
  discounts$: Observable<QPilotScheduledOrderAmountDetail[]>;
  itemsCount$: Observable<number>;
  orderVerboseName: string;
  orderVerboseNamePlural: string;
  nextOccurrenceVerboseName: string;
  dialogRef: MatDialogRef<DialogWrapperComponent>;
  alignActionsToRight = true;
  processingStatusList = ['processing', 'queued'];
  showCoupons: boolean = true;
  isProcessing$: Observable<{ value: boolean }>;
  closestNextOccurrenceDate: string;

  @Input() orderId: string;
  @ViewChild(ScheduledOrderDetailsComponent) detailsCoreComponent: ScheduledOrderDetailsComponent;

  constructor(
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    protected siteSettingsService: SiteSettingsService,
    protected deliveryService: DeliveryService,
    protected viewPortService: ViewportService,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private overlay: Overlay,
    private ref: ElementRef,
  ) {
    const {
      orderVerboseName,
      orderVerboseNamePlural,
      nextOccurrenceVerboseName,
      showCoupons
    } = this.siteSettingsService;
    this.orderVerboseName = orderVerboseName;
    this.orderVerboseNamePlural = orderVerboseNamePlural;
    this.nextOccurrenceVerboseName = nextOccurrenceVerboseName;
    this.showCoupons = showCoupons;
    this.closestNextOccurrenceDate = this.router.getCurrentNavigation()?.extras?.state?.['closestNextOccurrenceDate'];
  }

  ngOnInit() {
    this.dataService.setCurrentOrderId(parseInt(this.orderId));
    this.order$ = this.dataService.getOrder(parseInt(this.orderId)).pipe(
      map((order) => {
        const calculateTotalsRequest = this.calculateTotalsRequest(order);

        const useNextime = this.siteSettingsService.useNextime;
        if (useNextime && order.status == 'active' && !order.estimatedDeliveryDate) {
          this.deliveryService.trackOrder(order.id);
        }

        return {
          ...order,
          status: order.locked ? 'processing' : order.status,
          closestNextOccurrenceDate: this.closestNextOccurrenceDate,
          getOrderTotals: () => this.qPilotCloudApiService.calculateScheduledOrderTotals(calculateTotalsRequest)
        }
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    this.isProcessing$ = this.order$.pipe(
      map(order => ({
        value: order.locked || this.processingStatusList.includes(order.status)
      }))
    );

    this.orderTotals$ = this.order$.pipe(
      switchMap(order => {
        const req = this.calculateTotalsRequest(order);
        return this.qPilotCloudApiService
          .calculateScheduledOrderTotals(req)
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    )

    this.discounts$ = this.orderTotals$.pipe(
      map(orderTotals => {
        const couponDiscounts = orderTotals?.itemsTotals?.couponDiscounts ?? [];
        const shippingCouponDiscounts = orderTotals?.shippingTotals?.couponDiscounts ?? [];
        return [...couponDiscounts, ...shippingCouponDiscounts]
      }),
    )

    this.itemsCount$ = this.order$.pipe(
      map(
        (order) =>
          order.scheduledOrderItems?.reduce(
            (a, b) => a + (b.quantity ?? 0),
            0
          ) ?? 0
      )
    );

    this.alignActionsToRight = this.ref.nativeElement.offsetWidth < 600;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.alignActionsToRight = this.ref.nativeElement.offsetWidth < 600;
  }

  onUpdateQuantity(
    { quantityToUpdate }: UpdateOrderItemQuantityEvent,
    orderItem: QPilotScheduledOrderItem
  ) {
    const updateData: QPilotScheduledOrderItem = {
      ...JSON.parse(JSON.stringify(orderItem)),
      quantity: quantityToUpdate,
    };

    this.qPilotCloudApiService
      .updateScheduledOrderItem(updateData)
      .pipe(
        tap(() => {
          if (this.siteSettingsService.useNextime) {
            this.deliveryService.setShouldFetchNextOccurrenceDate(false);
          }

          this.dataService.updateItemOfOrder(
            parseInt(this.orderId),
            updateData,
            this.siteSettingsService.useNextime,
          )
        }),
        tap(() => successSnackBar(this.snackBar, 'Quantity updated')),
        catchError(() => {
          errorSnackBar(this.snackBar, "Failed: Error updating quantity");
          return of(null);
        }),
      )
      .subscribe();
  }

  onChangeQuantity($event: ChangeOrderItemQuantityEvent) {
    this.dataService
      .getCurrentOrder()
      .subscribe((scheduledOrder: QPilotScheduledOrder) => {
        let items = scheduledOrder.scheduledOrderItems!.map((item) => ({
          ...item,
        }));
        items.forEach((item) => {
          if (item.id == $event.orderItemId) {
            item.quantity = $event.quantityToUpdate;
          }
        });
      });
  }

  calculateTotalsRequest(scheduledOrder: QPilotScheduledOrder) {
    const calculateTotalsRequest: QPilotCalculateTotalsRequest = {
      scheduledOrderId: scheduledOrder.id,
      customer: scheduledOrder.customer,
      scheduledOrderItems: scheduledOrder.scheduledOrderItems,
      scheduledOrderCoupons: scheduledOrder.couponsHistory,
      coupons: scheduledOrder.coupons,
      shippingCity: scheduledOrder.shippingCity,
      shippingCountry: scheduledOrder.shippingCountry,
      shippingPostcode: scheduledOrder.shippingPostcode,
      shippingState: scheduledOrder.shippingState,
      shippingStreet1: scheduledOrder.shippingStreet1,
      shippingStreet2: scheduledOrder.shippingStreet2,
      currencyIso: scheduledOrder.currencyIso,
      cycles: scheduledOrder.cycles!,
      total: scheduledOrder.total,
      preferredShippingRateOption: scheduledOrder.preferredShippingRateOption
    };

    return calculateTotalsRequest;
  }

  onDeleteOrderItem({ orderItemId }: DeleteOrderItemEvent) {
    this.qPilotCloudApiService
      .deleteScheduledOrderItem(orderItemId)
      .pipe(
        tap(() => {
          if (this.siteSettingsService.useNextime) {
            this.deliveryService.setShouldFetchNextOccurrenceDate(false);
          }

          this.dataService.deleteItemOfOrder(
            parseInt(this.orderId),
            orderItemId,
            this.siteSettingsService.useNextime,
          )
        }),
        tap(() => successSnackBar(this.snackBar, 'Product deleted')),
        catchError(() => {
          errorSnackBar(this.snackBar, "Failed: Error deleting Product");
          return of(null);
        })
      ).subscribe();
  }

  onEditOrderItem(orderItem: QPilotScheduledOrderItem) {
    this.qPilotCloudApiService
      .getProducts()
      .pipe(
        map((data) =>
          data.items.filter(
            (item) => item.active && item.valid && item.addToScheduledOrder
          )
        ),
        tap((data) => this.dataService.setProducts(data)),
        tap(() => this.openDialog('add-products', false, { productId: orderItem.productId ?? orderItem.product?.id }))
      ).subscribe();
  }

  onProcessOrder(order: Order) {
    const closestNextDeliveryDate = order.closestNextDeliveryDate;
    this.qPilotCloudApiService
      .retryProcessing(order.id)
      .pipe(
        tap((updatedOrder) => this.dataService.updateOrder({
          ...order,
          status: updatedOrder.status.toLowerCase() as OrderStatus,
          lastOccurrenceUtc: updatedOrder.lastOccurrenceUtc,
          nextOccurrenceUtc: updatedOrder.nextOccurrenceUtc,
          estimatedDeliveryDate: closestNextDeliveryDate,
        })),
        tap(() => successSnackBar(this.snackBar, `${this.orderVerboseName} is Processing`)),
        catchError(() => {
          errorSnackBar(this.snackBar, `Failed: Error trying to process ${this.orderVerboseName}`)
          return of(null);
        })
      ).subscribe();
  }

  onPauseOrder({ orderId }: PauseOrderEvent) {
    this.qPilotCloudApiService
      .changeScheduledOrderStatus(orderId, 'paused')
      .pipe(
        tap(() => this.dataService.pauseOrder(orderId)),
        tap(() => successSnackBar(this.snackBar, 'Order paused')),
        catchError(() => {
          errorSnackBar(this.snackBar, "Failed: Error pausing Order");
          return of(null);
        })
      )
      .subscribe();
  }

  onCancelOrder({ orderId }: CancelOrderEvent) {
    this.qPilotCloudApiService
      .deleteScheduledOrder(orderId)
      .pipe(
        tap(() => this.dataService.deleteOrder(orderId)),
        tap(() => successSnackBar(this.snackBar, 'Order deleted')),
        catchError(() => {
          errorSnackBar(this.snackBar, "Failed: Error deleting Order");
          return of(null);
        })
      )
      .subscribe();
  }

  onResumeOrder({ orderId }: ResumeOrderEvent) {
    this.qPilotCloudApiService
      .safeActivateScheduledOrder(orderId)
      .pipe(
        tap(() => this.dataService.resumeOrder(orderId)),
        tap(() => successSnackBar(this.snackBar, 'Order reactivated')),
        catchError(() => {
          errorSnackBar(this.snackBar, "Failed: Error reactivating Order");
          return of(null);
        })
      )
      .subscribe();
  }

  onDeleteCoupon(
    { couponCode }: DeleteCouponEvent,
    scheduledOrder: QPilotScheduledOrder
  ) {
    const updatedScheduledOrder: QPilotScheduledOrder = {
      ...scheduledOrder,
      coupons: [
        ...(scheduledOrder.coupons ?? []).filter(code => code != couponCode)
      ],
    };

    this.qPilotCloudApiService
      .updateScheduledOrder(updatedScheduledOrder)
      .pipe(
        tap(result => this.dataService.updateOrder(result)),
        tap(() => successSnackBar(this.snackBar, 'Coupon deleted')),
        catchError(() => {
          errorSnackBar(this.snackBar, "Failed: Error deleting coupon");
          return of(null);
        }),
      ).subscribe();
  }

  updateClosestDeliveryDate(event: DeliveryDateUpdateEvent, order: Order) {
    const detail = event.detail;
    if (!detail) return;
    const { deliveryDate } = detail;
    if (!deliveryDate) return;

    const deliveryDateUtc = moment(deliveryDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    order.closestNextDeliveryDate = deliveryDateUtc;
  }

  async openDeliveryDateDialog() {
    await this.detailsCoreComponent.openDeliveryDateDialog();
  }

  openDialog(
    routeName: string,
    showCloseButton: boolean,
    args?: {
      productId?: string | number,
      discounts?: QPilotScheduledOrderAmountDetail[],
      resumeAfter?: boolean,
    }
  ) {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const isMobileView = vw < 600;
    const config = isMobileView
      ? {
          maxWidth: '100vw',
          width: '100%',
          maxHeight: '100vh',
          height: '100%',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
        }
      : {
          maxWidth: `min(calc(100vw - 100px), 700px)`,
          maxHeight: `calc(100vh - 150px)`,
          width: '80%',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
        };

    this.dialogRef = this.dialog.open(DialogWrapperComponent, {
      ...config,
      data: {
        showCloseButton,
        orderId: parseInt(this.orderId),
        order: this.order$,
        orderTotals: this.orderTotals$,
        discounts: this.discounts$,
        resumeAfter: args?.resumeAfter,
      },
    });

    this.dialogRef
      .afterOpened()
      .pipe(
        tap(() => {
          this.router.navigate([
            { outlets: { dialog: args?.productId ? [routeName, args?.productId] : [routeName] } }
          ],
          {
            skipLocationChange: true,
          });
        })
      )
      .subscribe();

    this.dialogRef
      .afterClosed()
      .pipe(
        tap(() => {
          this.router.navigate([{ outlets: { dialog: null } }], {
            skipLocationChange: true,
          });
        })
      )
      .subscribe();
  }

  openDialogAddProducts() {
    this.qPilotCloudApiService
      .getProducts()
      .pipe(
        map((data) =>
          data.items.filter(
            (item) => item.active && item.valid && item.addToScheduledOrder
          )
        ),
        tap((data) => this.dataService.setProducts(data)),
        tap(() => this.openDialog('add-products', false))
      )
      .subscribe();
  }

  goBack() {
    this.router.navigate(['orders'], { skipLocationChange: true });
  }
}
