import '@material/web/button/filled-button';

import { NgClass } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'lib-card-with-radio-button',
  standalone: true,
  imports: [
    MatCardModule,
    MatRadioModule,
    NgClass,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './card-with-radio-button.component.html',
  styleUrl: './card-with-radio-button.component.scss'
})
export class CardWithRadioButtonComponent {

  @Input() value: any;
  @Input() ariaLabel: string;
  @Input() selectedOption: any;
  @Input() showConfirmButton: boolean;

  @Output() confirm = new EventEmitter();

  onConfirm() {
    this.confirm.emit();
  }
}
