import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import {
  catchError,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';
import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { errorSnackBar, successSnackBar } from '../utils';
import { DeliveryService } from '../services/delivery.service';
import { PauseOrderComponent } from '../web-components/pause-order/pause-order.component';
import { AnswerOfferEvent } from '../web-components/types/events';
import { QPilotScheduledOrder } from '../web-components/types/scheduled-order';
import {
  QPilotSurvey,
  QPilotOfferStatusTypes,
  QPilotOfferTypes,
  QPilotActionTriggerTypes,
  QPilotSurveyAnswer,
} from '../web-components/types/surveys';

@Component({
  selector: 'app-change-status-to-pause',
  standalone: true,
  imports: [AsyncPipe, MatDialogModule, PauseOrderComponent],
  templateUrl: './change-status-to-pause.component.html',
  styleUrl: './change-status-to-pause.component.scss',
})
export class ChangeStatusToPauseComponent {
  orderId: number;
  order$: Observable<QPilotScheduledOrder>;
  pause$: Observable<unknown>;
  pauseSurvey$: Observable<QPilotSurvey>;

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogWrapperComponent>,
    private qPilotCloudApiService: QPilotCloudApiService,
    protected siteSettingsService: SiteSettingsService,
    private deliveryService: DeliveryService,
    private snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      orderId: number;
      order: Observable<QPilotScheduledOrder>;
    }
  ) {}

  ngOnInit() {
    this.orderId = this.data.orderId;
    this.order$ = this.data.order;
    this.pauseSurvey$ = this.fetchSurvey();

    this.pause$ = this.qPilotCloudApiService
      .changeScheduledOrderStatus(this.orderId, 'paused')
      .pipe(
        tap(() => this.dataService.pauseOrder(this.orderId)),
        tap(() => successSnackBar(this.snackBar, 'Order paused')),
        tap(() => this.onCancel())
      );
  }

  onCancel() {
    this.dialog.closeAll();
  }

  onConfirm() {
    this.pause$.subscribe();
  }

  onAnswerOffer({
    selectedOption,
    surveyId,
    answer,
    comment,
  }: AnswerOfferEvent) {
    const selectedOptionTypesMap = {
      accept: QPilotOfferStatusTypes.Accepted,
      deny: QPilotOfferStatusTypes.Rejected,
    };
    const statusType = selectedOptionTypesMap[selectedOption];
    this.qPilotCloudApiService
      .answerSurvey(this.orderId, surveyId, answer, statusType, comment)
      .pipe(
        switchMap(() => {
          const {
            offerApplicableItem: { itemType },
          } = answer;
          const isNoOffer = itemType === QPilotOfferTypes.NoOffer;
          return isNoOffer || selectedOption === 'deny'
            ? this.pause$
            : of(null).pipe(
                switchMap(() => this.takeActionAfterOffer(answer, statusType))
              );
        })
      )
      .subscribe();
  }

  private fetchSurvey() {
    return this.qPilotCloudApiService
      .getSurveys(QPilotActionTriggerTypes.Pause)
      .pipe(
        map((response) => response?.result?.surveys ?? []),
        map(
          (surveys) =>
            surveys.find((s) => !s.survey.isDeleted && s.survey.isEnabled)
              ?.survey ?? <QPilotSurvey>{}
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
  }

  private takeActionAfterOffer(
    answer: QPilotSurveyAnswer,
    statusType: QPilotOfferStatusTypes
  ): Observable<any> {
    const offerType = answer.offerApplicableItem.itemType;
    const isQueued = [
      QPilotOfferTypes.Discount,
      QPilotOfferTypes.FreeProduct,
    ].includes(offerType);

    if (isQueued) {
      return of(
        successSnackBar(
          this.snackBar,
          'Thank you! Your order will take up to 5min to update.',
          null
        )
      ).pipe(tap(() => this.onCancel()));
    }

    if (offerType == QPilotOfferTypes.Skip) {
      return this.order$.pipe(
        take(1),
        switchMap((order) =>
          this.qPilotCloudApiService.getNextOccurrenceUtc(
            order,
            order.nextOccurrenceUtc
          )
        ),
        switchMap((date) =>
          this.qPilotCloudApiService
            .updateScheduledOrderNextOccurrence(this.orderId, date)
            .pipe(
              tap((order) => {
                if (this.siteSettingsService.useNextime) {
                  this.deliveryService.ignoreEvent(order.id);
                }
                this.dataService.updateOrderNextOccurrenceAndDeliveryInfo(
                  order.id,
                  order.nextOccurrenceUtc,
                  undefined
                );
              }),
              tap(() =>
                successSnackBar(
                  this.snackBar,
                  'Update: Next Order Date Changed'
                )
              ),
              catchError(() => {
                errorSnackBar(
                  this.snackBar,
                  'Update Failed: Error Updating Next Order Date'
                );
                return of(null);
              })
            )
        ),
        tap(() => this.onCancel())
      );
    }

    if (offerType == QPilotOfferTypes.ChangeFrequency) {
      return this.pauseSurvey$.pipe(
        tap(() => {
          this.router.navigate(
            [{ outlets: { dialog: ['change-frequency'] } }],
            { skipLocationChange: true }
          );
        })
      );
    }

    return of();
  }
}
