@if (order$ | async; as order) {
  @if(discounts$ | async; as discounts) {
    <lib-add-coupons
      [discounts]="discounts"
      [orderVerboseName]="orderVerboseName"
      (cancel)="onCancel()"
      (addCoupon)="tryToGetCoupon($event, order)"
      (deleteCoupon)="onDeleteCoupon($event, order)"
    />
  }
}
