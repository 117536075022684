@if (survey$ | async; as survey) {
  <div class="pause-order-dialog-wrapper">

  @if (survey.id) {
    <lib-survey-and-offer
      [survey]="survey"
      [orderVerboseName]="orderVerboseName"
      [nextOccurrenceVerboseName]="nextOccurrenceVerboseName"
      [actionTrigger]="actionTrigger"
      [navigationText]="'Pause'"
      (confirm)="onAnswerOffer($event)"
      (goBack)="resetSurveyAndOffer()"
      (cancel)="onCancel()" />
  } @else {
    <div class="pause-header">
      <h2>Pause</h2>
    </div>

    <h3 class="pause-order-subtitle">Choose to Pause Your {{ orderVerboseName }}</h3>

    <mat-radio-group class="radio-group" [(ngModel)]="selectedOption">
      @for (option of options; track option) {
        <lib-card-with-radio-button
          [value]="option.name"
          [selectedOption]="selectedOption"
          [showConfirmButton]="true"
          [ariaLabel]="option.name"
          (confirm)="onConfirm()">
          <div class="option">
              <p class="title">{{ option.name }}</p>
            @if (option.name == selectedOption) {
              <p class="subtitle">{{ option.description }}</p>
            }
          </div>
        </lib-card-with-radio-button>
      }
    </mat-radio-group>

    <div class="dialog-action-buttons">
      <md-outlined-button class="cancel-action" (click)="onCancel()">Cancel</md-outlined-button>
    </div>
  }
</div>
}
