import '@material/web/button/outlined-button';

import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';

import { CardWithRadioButtonComponent } from '../card-with-radio-button/card-with-radio-button.component';
import { SurveyAndOfferComponent } from '../survey-and-offer/survey-and-offer.component';
import { AnswerOfferEvent } from '../types/events';
import { QPilotActionTriggerTypes, QPilotOfferApplicableItem, QPilotSurvey } from '../types/surveys';

export class RadioOption {
  name: string;
  description: string;
  options: string[];
}
@Component({
  selector: 'lib-pause-order',
  standalone: true,
  imports: [
    MatRadioModule,
    FormsModule,
    AsyncPipe,
    SurveyAndOfferComponent,
    CardWithRadioButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './pause-order.component.html',
  styleUrl: './pause-order.component.scss'
})
export class PauseOrderComponent implements OnInit {
  @Input() orderVerboseName: string;
  @Input() nextOccurrenceVerboseName: string;
  @Input() pauseSurvey$: Observable<QPilotSurvey>;

  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Output() answerOffer = new EventEmitter<AnswerOfferEvent>();

  offerApplicableItem: QPilotOfferApplicableItem | null = null;
  selectedOption?: string;
  options: RadioOption[];
  actionTrigger = QPilotActionTriggerTypes.Pause;

  private confirmedSubject = new BehaviorSubject<boolean>(false);
  confirmed$ = this.confirmedSubject.asObservable();
  survey$: Observable<QPilotSurvey>;

  ngOnInit() {
    this.options = [
      { name: 'Pause',
        description: `Your ${this.orderVerboseName} will remain paused until you choose to resume.`,
        options: ['Remain Paused'],
      }
    ];

    this.survey$ = this.confirmed$.pipe(
      switchMap((confirmed) => {
        if (confirmed) {
          return this.pauseSurvey$.pipe(
            tap(survey => !survey.id ? this.confirm.emit() : null)
          )
        }
        return of(<QPilotSurvey>{})
      }),
    )
  }

  onAnswerOffer(event: AnswerOfferEvent) {
    this.answerOffer.emit(event);
  }

  resetSurveyAndOffer() {
    this.selectedOption = undefined;
    this.confirmedSubject.next(false);
  }

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    this.confirmedSubject.next(true);
  }
}
