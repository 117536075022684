import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import { DataService } from '../services/data.service';
import { DeliveryService } from '../services/delivery.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { errorSnackBar, successSnackBar } from '../utils';
import { RescheduleOrderEvent } from '../web-components/types/events';
import { QPilotScheduledOrder } from '../web-components/types/scheduled-order';
import { QPilotSiteSettings } from '../web-components/types/site';
import { RescheduledOrderLibComponent } from '../web-components/reschedule-order/reschedule-order.component';

@Component({
  selector: 'app-reschedule-order',
  standalone: true,
  imports: [AsyncPipe, RescheduledOrderLibComponent],
  templateUrl: './reschedule-order.component.html',
  styleUrl: './reschedule-order.component.scss',
})
export class RescheduleOrderComponent implements OnInit {
  order$: Observable<QPilotScheduledOrder>;
  siteSettings: QPilotSiteSettings;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: Observable<QPilotScheduledOrder>;
      orderId: number;
      resumeAfter?: boolean;
    },
    private qPilotCloudApiService: QPilotCloudApiService,
    private siteSettingsService: SiteSettingsService,
    private dataService: DataService,
    private deliveryService: DeliveryService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.order$ = this.data.order;
    this.dataService.setCurrentOrderId(this.data.orderId);
    this.siteSettings = this.siteSettingsService.siteSettings;
  }

  onCancel() {
    this.dialog.closeAll();
  }

  onConfirm({ orderId, nextOccurrenceUtc }: RescheduleOrderEvent) {
    this.dialog.closeAll();

    if (this.siteSettingsService.useNextime)
      this.deliveryService.isLoading = true;

    this.qPilotCloudApiService
      .updateScheduledOrderNextOccurrence(orderId, nextOccurrenceUtc)
      .pipe(
        switchMap((order) => {
          if (this.data.resumeAfter) {
            return this.qPilotCloudApiService
              .safeActivateScheduledOrder(order.id)
              .pipe(
                tap(() => this.dataService.resumeOrder(orderId)),
                map(() => order)
              );
          } else return of(order);
        }),
        tap((order) => {
          if (this.siteSettingsService.useNextime) {
            this.deliveryService.setShouldFetchNextOccurrenceDate(false);
          }
          this.dataService.updateOrderNextOccurrenceAndDeliveryInfo(
            order.id,
            order.nextOccurrenceUtc,
            undefined
          );
        }),
        tap(() =>
          successSnackBar(this.snackBar, 'Update: Next Order Date Changed')
        ),
        catchError(() => {
          errorSnackBar(
            this.snackBar,
            'Update Failed: Error Updating Next Order Date'
          );
          return of(null);
        })
      )
      .subscribe();
  }
}
