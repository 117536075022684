<div class="country-select">
  <mat-form-field hideRequiredMarker>
    <mat-label>Country</mat-label>
    <mat-select [placeholder]="placeholder" [formControl]="inputFormControl">
      <mat-option>
        <ngx-mat-select-search ngModel (ngModelChange)="filterCountries($event)" noEntriesFoundLabel="No results found"
        placeholderLabel="Type to search"></ngx-mat-select-search>
      </mat-option>
      @for (country of filteredCountries | async; track country.alpha2) {
        <mat-option [value]="country.alpha2">
          {{ country.alpha2 }} | {{ country.name }}
        </mat-option>
      }
    </mat-select>
    @if(inputFormControl.hasError('required')) {
      <mat-error>This field is required</mat-error>
    }

  </mat-form-field>
</div>
