import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { QPilotPaymentMethod } from '../web-components/types/payment-method';
import { QPilotProduct } from '../web-components/types/product';
import {
  QPilotScheduledOrder,
  OrderStatus,
  OrderFrequencyType,
} from '../web-components/types/scheduled-order';
import { QPilotScheduledOrderItem } from '../web-components/types/scheduled-order-item';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private currentOrderIdSubject = new BehaviorSubject<number>(0);
  private ordersSubject = new BehaviorSubject<QPilotScheduledOrder[]>([]);
  private productsSubject = new BehaviorSubject<QPilotProduct[]>([]);

  currentOrderId$ = this.currentOrderIdSubject.asObservable();
  orders$ = this.ordersSubject.asObservable();
  products$ = this.productsSubject.asObservable();

  constructor() {}

  setCurrentOrderId(orderId: number) {
    this.currentOrderIdSubject.next(orderId);
  }

  getCurrentOrder() {
    return this.getOrder(this.currentOrderIdSubject.value);
  }

  setOrders(orders: QPilotScheduledOrder[]) {
    this.ordersSubject.next(orders);
  }

  pauseOrder(orderId: number) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              status: 'paused' as OrderStatus,
              estimatedDeliveryDate: undefined,
            }
          : order;
      })
    );
  }

  deleteOrder(orderId: number) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              status: 'deleted',
              lastChangeToDeleted: `${new Date().toJSON()}` as OrderStatus,
              estimatedDeliveryDate: undefined,
            }
          : order;
      })
    );
  }

  resumeOrder(orderId: number) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? { ...order, status: 'active' as OrderStatus }
          : order;
      })
    );
  }

  getOrder(orderId: number) {
    return this.orders$.pipe(
      map((items) => items.find((item) => item.id === orderId)!)
    );
  }

  updateOrder(updatedOrder: QPilotScheduledOrder) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === updatedOrder.id
          ? {
              ...updatedOrder,
              status: updatedOrder.status.toLocaleLowerCase() as OrderStatus,
            }
          : order;
      })
    );
  }

  updateOrderPayment(orderId: number, paymentMethod?: QPilotPaymentMethod) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              paymentMethod: paymentMethod,
              paymentMethodId: paymentMethod?.id,
            }
          : order;
      })
    );
  }

  updateOrderFrequency(
    orderId: number,
    frequency: number,
    frequencyType: OrderFrequencyType,
    frequencyDisplayName: string
  ) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              frequency: frequency,
              frequencyType: frequencyType,
              frequencyDisplayName: frequencyDisplayName,
            }
          : order;
      })
    );
  }

  updateOrderNextOccurrence(orderId: number, nextOccurrenceUtc: string) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              nextOccurrenceUtc: nextOccurrenceUtc,
            }
          : order;
      })
    );
  }

  updateOrderDeliveryDate(orderId: number, deliveryDate: string | undefined) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              estimatedDeliveryDate: deliveryDate,
            }
          : order;
      })
    );
  }

  updateOrderClosestNextOccurrenceDate(
    orderId: number,
    nextOccurrenceDate: string | undefined
  ) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              closestNextOccurrenceDate: nextOccurrenceDate,
            }
          : order;
      })
    );
  }

  updateOrderClosestNextDeliveryDate(
    orderId: number,
    deliveryDate: string | undefined
  ) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              closestNextDeliveryDate: deliveryDate,
            }
          : order;
      })
    );
  }

  updateOrderNextOccurrenceAndDeliveryInfo(
    orderId: number,
    nextOccurrenceUtc: string,
    deliveryDate: string | undefined,
    shippingRateName?: string | null,
    shippingTotal?: number
  ) {
    const hasShippingRateName =
      shippingRateName !== null && shippingRateName !== undefined;
    const hasShippingTotal =
      shippingTotal !== null && shippingTotal !== undefined;
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        return order.id === orderId
          ? {
              ...order,
              nextOccurrenceUtc: nextOccurrenceUtc,
              estimatedDeliveryDate: deliveryDate,
              ...(hasShippingRateName
                ? { shippingRateName: shippingRateName }
                : {}),
              ...(hasShippingTotal ? { shippingTotal: shippingTotal } : {}),
            }
          : order;
      })
    );
  }

  addItemToOrder(
    orderId: number,
    orderItem: QPilotScheduledOrderItem,
    useNextime = false
  ) {
    this.setOrders(
      this.ordersSubject.value.map((order) => {
        if (order.id === orderId) {
          return {
            ...order,
            scheduledOrderItems: [
              ...(order.scheduledOrderItems ?? []),
              orderItem,
            ],
            estimatedDeliveryDate: useNextime
              ? undefined
              : order.estimatedDeliveryDate,
          };
        }
        return order;
      })
    );
  }

  updateItemOfOrder(
    orderId: number,
    orderItem: QPilotScheduledOrderItem,
    useNextime = false
  ) {
    this.setOrders(
      this.ordersSubject.value.map((order: QPilotScheduledOrder) => {
        if (order.id === orderId) {
          const updatedOrder: QPilotScheduledOrder = {
            ...order,
            scheduledOrderItems: order.scheduledOrderItems?.map(
              (item: QPilotScheduledOrderItem) =>
                item.id === orderItem.id ? orderItem : item
            ),
            estimatedDeliveryDate: useNextime
              ? undefined
              : order.estimatedDeliveryDate,
          };
          return updatedOrder;
        }
        return order;
      })
    );
  }

  deleteItemOfOrder(orderId: number, orderItemId: number, useNextime = false) {
    this.setOrders(
      this.ordersSubject.value.map((order: QPilotScheduledOrder) => {
        if (order.id === orderId) {
          return {
            ...order,
            scheduledOrderItems: order.scheduledOrderItems?.filter(
              (item: QPilotScheduledOrderItem) => item.id !== orderItemId
            ),
            estimatedDeliveryDate: useNextime
              ? undefined
              : order.estimatedDeliveryDate,
          };
        }
        return order;
      })
    );
  }

  productIsInOrder(orderId: number, productId: string) {
    return this.orders$.pipe(
      map((data) =>
        data.find((order: QPilotScheduledOrder) => order.id === orderId)
      ),
      map((data) => {
        const orderItem = data?.scheduledOrderItems?.find(
          (item: QPilotScheduledOrderItem) => item.product?.id === productId
        );
        return [!!orderItem, orderItem];
      })
    );
  }

  setProducts(products: QPilotProduct[]) {
    this.productsSubject.next(products);
  }

  getProduct(productId: string) {
    return this.products$.pipe(
      map((items) => items.find((item) => item.id === productId)!)
    );
  }
}
