import '@material/web/iconbutton/icon-button';

import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';

import { OfferComponent } from '../offer/offer.component';
import { SurveyComponent } from '../survey/survey.component';
import { AnswerOfferEvent } from '../types/events';
import { QPilotActionTriggerTypes, QPilotOfferTypes, QPilotSurvey, QPilotSurveyAnswer } from '../types/surveys';

@Component({
  selector: 'lib-survey-and-offer',
  standalone: true,
  imports: [SurveyComponent, OfferComponent, AsyncPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './survey-and-offer.component.html',
  styleUrl: './survey-and-offer.component.scss'
})
export class SurveyAndOfferComponent {

  @Input() survey: QPilotSurvey;
  @Input() orderVerboseName: string;
  @Input() nextOccurrenceVerboseName: string;
  @Input() navigationText: string;
  @Input() actionTrigger: QPilotActionTriggerTypes;

  @Output() confirm = new EventEmitter<AnswerOfferEvent>();
  @Output() goBack = new EventEmitter();
  @Output() cancel = new EventEmitter();

  chosenAnswer = <QPilotSurveyAnswer>{};
  answerComment: string | undefined;
  showOffers = false;

  answerSurvey(event: {
    chosenAnswerId: string,
    answerComment: string | undefined,
  }) {
    const { chosenAnswerId, answerComment } = event;
    const chosenAnswer = this.survey.answers.find(
      answer => answer.id == chosenAnswerId
    ) ?? <QPilotSurveyAnswer>{};
    this.chosenAnswer = chosenAnswer;
    this.answerComment = answerComment;

    const isNoOffer = this.chosenAnswer.offerApplicableItem?.itemType === QPilotOfferTypes.NoOffer;
    if (isNoOffer) {
      this.showOffers = false;
      this.onConfirm('accept');
    } else {
      this.showOffers = this.chosenAnswer.offerApplicableItem?.itemType != null;
    }
  }

  onGoBack() {
    if (this.chosenAnswer?.offerApplicableItem?.id) {
      this.showOffers = false;
      return this.chosenAnswer = <QPilotSurveyAnswer>{};
    }

    return this.goBack.emit();
  }

  onConfirm(selectedOption: 'accept' | 'deny') {
    const event: AnswerOfferEvent = {
      selectedOption,
      surveyId: this.survey.id,
      answer: this.chosenAnswer,
      comment: this.answerComment,
    }
    this.confirm.emit(event);
  }

  onCancel() {
    this.cancel.emit();
  }
}
