<mat-form-field>
    <mat-label>Choose a date</mat-label>
    <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="selectedDate"
        [min]="minimumNod"
        (ngModelChange)="selectDate($event)">
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
