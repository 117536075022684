import '@material/web/dialog/dialog';

import { AsyncPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { ScheduledOrderCouponComponent } from '../scheduled-order-coupon/scheduled-order-coupon.component';
import { AddCouponEvent, DeleteCouponEvent } from '../types/events';
import { QPilotScheduledOrderAmountDetail } from '../types/scheduled-order';

@Component({
  selector: 'lib-add-coupons',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIcon,
    AsyncPipe,
    ScheduledOrderCouponComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './add-coupons.component.html',
  styleUrl: './add-coupons.component.scss'
})
export class AddCouponsComponent {
  @Input() discounts: QPilotScheduledOrderAmountDetail[];
  @Input() orderVerboseName: string;
  @Output() cancel = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Output() addCoupon = new EventEmitter<AddCouponEvent>();
  @Output() deleteCoupon = new EventEmitter<DeleteCouponEvent>();

  couponCode: string;

  constructor() {}

  tryToAddCoupon() {
    this.addCoupon.emit({ couponCode: this.couponCode });
    this.couponCode = '';
  }

  onDeleteCoupon($event: DeleteCouponEvent): void {
    this.deleteCoupon.emit($event);
  }

  onCancel() {
    this.cancel.emit();
  }
}
