import { AsyncPipe } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';

import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { errorSnackBar, successSnackBar } from '../utils';
import {
  DeleteCouponEvent,
  AddCouponEvent,
} from '../web-components/types/events';
import {
  QPilotScheduledOrderAmountDetail,
  QPilotScheduledOrder,
} from '../web-components/types/scheduled-order';
import { AddCouponsComponent } from '../web-components/add-coupons/add-coupons.component';

@Component({
  selector: 'app-update-coupons',
  standalone: true,
  imports: [AsyncPipe, AddCouponsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './update-coupons.component.html',
  styleUrl: './update-coupons.component.scss',
})
export class UpdateCouponsComponent implements OnInit {
  discounts$: Observable<QPilotScheduledOrderAmountDetail[]>;
  order$: Observable<QPilotScheduledOrder>;
  orderVerboseName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: Observable<QPilotScheduledOrder>;
      discounts: Observable<QPilotScheduledOrderAmountDetail[]>;
    },
    private dataService: DataService,
    private qPilotCloudApiService: QPilotCloudApiService,
    private siteSettingsService: SiteSettingsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.order$ = this.data.order;
    this.discounts$ = this.data.discounts;
    this.orderVerboseName = this.siteSettingsService.orderVerboseName;
  }

  onCancel() {
    this.dialog.closeAll();
  }

  onDeleteCoupon(
    { couponCode }: DeleteCouponEvent,
    scheduledOrder: QPilotScheduledOrder
  ) {
    const updatedScheduledOrder: QPilotScheduledOrder = {
      ...scheduledOrder,
      coupons: scheduledOrder.coupons?.filter(
        (code: string) => code != couponCode
      ),
    };

    this.qPilotCloudApiService
      .updateScheduledOrder(updatedScheduledOrder)
      .pipe(
        tap((result) => this.dataService.updateOrder(result)),
        tap(() => {
          successSnackBar(this.snackBar, 'Coupon deleted');
        }),
        catchError(() => {
          errorSnackBar(this.snackBar, 'Failed: Error deleting Coupon');
          return of(null);
        })
      )
      .subscribe();
  }

  tryToGetCoupon(
    { couponCode }: AddCouponEvent,
    scheduledOrder: QPilotScheduledOrder
  ) {
    this.qPilotCloudApiService
      .getCouponByCode(scheduledOrder.id, couponCode)
      .pipe(
        map((coupon) => {
          const order: QPilotScheduledOrder = {
            ...scheduledOrder,
            coupons: coupon.code
              ? [...(scheduledOrder.coupons ?? []), coupon.code]
              : scheduledOrder.coupons,
          };
          return order;
        }),
        switchMap((order) =>
          this.qPilotCloudApiService.updateScheduledOrder(order)
        ),
        tap((updatedScheduledOrder) => {
          this.dataService.updateOrder(updatedScheduledOrder);
        }),
        tap(() => successSnackBar(this.snackBar, 'Update: Coupon Applied')),
        catchError((e) => {
          errorSnackBar(
            this.snackBar,
            `Update Failed: Coupon Code Invalid ${
              e.error?.message ? e.error.message : ''
            }`,
            5000
          );
          return of(null);
        })
      )
      .subscribe();
  }
}
