import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  AnswerOfferEvent,
  CancelOrderComponent,
  QPilotActionTriggerTypes,
  QPilotOfferStatusTypes,
  QPilotOfferTypes,
  QPilotScheduledOrder,
  QPilotSurvey,
  QPilotSurveyAnswer,
} from '@qpilot/subscriber-portal-web-component';
import { catchError, filter, map, Observable, of, shareReplay, switchMap, take, tap } from 'rxjs';

import { DialogWrapperComponent } from '../dialog-wrapper/dialog-wrapper.component';
import { DataService } from '../services/data.service';
import { QPilotCloudApiService } from '../services/qpilot-cloud-api.service';
import { SiteSettingsService } from '../services/site-settings.service';
import { errorSnackBar, successSnackBar } from '../utils';
import { DeliveryService } from '../services/delivery.service';

@Component({
  selector: 'app-change-status',
  standalone: true,
  imports: [AsyncPipe, MatDialogModule, CancelOrderComponent],
  templateUrl: './change-status-to-cancel.component.html',
  styleUrl: './change-status-to-cancel.scss',
})
export class ChangeStatusToCancelComponent implements OnInit {
  orderId: number;
  order$: Observable<QPilotScheduledOrder>;
  cancelSurvey$: Observable<QPilotSurvey>;
  cancel$: Observable<unknown>;

  constructor(
    private dataService: DataService,
    protected siteSettingsService: SiteSettingsService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogWrapperComponent>,
    private qPilotCloudApiService: QPilotCloudApiService,
    private deliveryService: DeliveryService,
    private snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {
      orderId: number,
      order: Observable<QPilotScheduledOrder>,
    },
  ) { }

  ngOnInit() {
    this.orderId = this.data.orderId;
    this.order$ = this.data.order;
    this.cancelSurvey$ = this.fetchSurvey();

    this.cancel$ = this.qPilotCloudApiService
      .deleteScheduledOrder(this.orderId)
      .pipe(
        tap(() => this.dataService.deleteOrder(this.orderId)),
        tap(() => successSnackBar(this.snackBar, 'Order canceled')),
        tap(() => this.onCancel())
      );
  }

  onCancel() {
    this.dialog.closeAll();
  }

  onConfirm() {
    this.cancel$.subscribe();
  }

  onAnswerOffer({
    selectedOption,
    surveyId,
    answer,
    comment,
  }: AnswerOfferEvent) {
    const selectedOptionTypesMap = {
      'accept': QPilotOfferStatusTypes.Accepted,
      'deny': QPilotOfferStatusTypes.Rejected,
    };
    const statusType = selectedOptionTypesMap[selectedOption];
    this.qPilotCloudApiService.answerSurvey(
      this.orderId,
      surveyId,
      answer,
      selectedOptionTypesMap[selectedOption],
      comment
    ).pipe(
      switchMap(() => {
        const { offerApplicableItem: { itemType } } = answer;
        const isNoOffer = itemType === QPilotOfferTypes.NoOffer;
        return isNoOffer || selectedOption === 'deny'
          ? this.cancel$
          : of(null).pipe(
              switchMap(() => this.takeActionAfterOffer(answer, statusType)),
            )
        }
      ),
    ).subscribe()
  }

  private fetchSurvey() {
    return this.qPilotCloudApiService.getSurveys(QPilotActionTriggerTypes.Cancel).pipe(
      map((response) => response?.result?.surveys ?? []),
      map((surveys) =>
        surveys.find(
          (s) => !s.survey.isDeleted && s.survey.isEnabled
        )?.survey ?? <QPilotSurvey>{}
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
    )
  }

  private takeActionAfterOffer(
    answer: QPilotSurveyAnswer,
    statusType: QPilotOfferStatusTypes,
  ): Observable<any> {
    const offerType = answer.offerApplicableItem.itemType;
    const isQueued = [
      QPilotOfferTypes.Discount,
      QPilotOfferTypes.FreeProduct
    ].includes(offerType);

    if (isQueued) {
      return of(
        successSnackBar(
          this.snackBar,
          'Thank you! Your order will take up to 5min to update.',
          null
        )
      ).pipe(tap(() => this.onCancel()));
    }

    if (offerType == QPilotOfferTypes.Skip) {
      return this.order$
        .pipe(
          take(1),
          switchMap(
            (order) => this.qPilotCloudApiService.getNextOccurrenceUtc(
              order,
              order.nextOccurrenceUtc
            ),
          ),
          switchMap(
            (date) => this.qPilotCloudApiService
              .updateScheduledOrderNextOccurrence(this.orderId, date)
              .pipe(
                tap((order) => {
                  if (this.siteSettingsService.useNextime) {
                    this.deliveryService.ignoreEvent(order.id);
                  }
                  this.dataService.updateOrderNextOccurrenceAndDeliveryInfo(
                    order.id,
                    order.nextOccurrenceUtc,
                    undefined,
                  );
                }),
                tap(() =>
                  successSnackBar(
                    this.snackBar,
                    'Update: Next Order Date Changed'
                  )
                ),
                catchError(() => {
                  errorSnackBar(
                    this.snackBar,
                    'Update Failed: Error Updating Next Order Date'
                  );
                  return of(null);
                })
              )
          ),
          tap(() => this.onCancel())
        );
    }

    if (offerType == QPilotOfferTypes.ChangeFrequency) {
      return this.cancelSurvey$.pipe(
        tap(() => {
          this.router.navigate(
            [{ outlets: { dialog: ['change-frequency'] } }],
            { skipLocationChange: true },
          );
        }
      ))
    }

    return of();
  }
}
