import { AsyncPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { QPilotScheduledOrder, QPilotScheduledOrderTotals } from '../types/scheduled-order';
import { QPilotSiteSettings } from '../types/site';
import { DateFormatPipe } from '../pipes/date-format-pipe';
import { NextimeWrapperComponent } from '../nextime-wrapper/nextime-wrapper.component';

@Component({
  selector: 'lib-process-now-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    DecimalPipe,
    AsyncPipe,
    CurrencyPipe,
    DateFormatPipe,
    NextimeWrapperComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './process-now-dialog.component.html',
  styleUrl: './process-now-dialog.component.scss'
})
export class ProcessNowDialogComponent implements OnInit, OnChanges {

  @Input() order: QPilotScheduledOrder;
  @Input() orderTotals$: Observable<QPilotScheduledOrderTotals>;
  @Input() orderVerboseName: string;
  @Input() isRetry = false;
  @Input() useNextime: boolean;
  @Input() nextimeToken: string;
  @Input() nextimeSiteId: number;
  @Input() siteSettings: QPilotSiteSettings;
  @Input() closestDeliveryDateUtc: string | undefined;
  @Input() closestNextOccurrenceDate: string | undefined;
  @Output() confirm = new EventEmitter();
  @Output() processNowDeliveryDateFetched = new EventEmitter();

  savings$: Observable<{value: number}>;
  deliverTo: string;
  orderToFetchDeliveryDate: QPilotScheduledOrder;

  constructor() { }

  ngOnInit() {
    this.savings$ = this.orderTotals$.pipe(
      map(totals => ({ value: totals.itemsTotals.subTotal - totals.itemsTotals.total })),
    );

    this.orderToFetchDeliveryDate = {
      ...this.order,
      nextOccurrenceUtc: this.closestNextOccurrenceDate ?? '',
      estimatedDeliveryDate: undefined,
    }
  }

  ngOnChanges() {
    const shippingStreet1 = this.order.shippingStreet1 ?? '';
    const shippingStreet2 = this.order.shippingStreet2 ?? '';
    this.deliverTo = `${shippingStreet1.trim()} ${shippingStreet2.trim()}`.trim();
  }

  onDeliveryDateFetched(event: Event) {
    this.processNowDeliveryDateFetched.emit(event);
  }

  onConfirm() {
    this.confirm.emit();
  }
}
