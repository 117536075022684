import { Component, Input } from '@angular/core';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;
type Color = RGB | RGBA | HEX;

@Component({
  selector: 'lib-loader-dots',
  standalone: true,
  imports: [],
  templateUrl: './loader-dots.component.html',
  styleUrl: './loader-dots.component.scss'
})
export class LoaderDotsComponent {
  @Input() color: Color | undefined;

  constructor() {}
}
