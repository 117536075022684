@if (orders$ | async; as orders) {
  @for (order of orders; track order.id) {
    <lib-scheduled-order-card
      [order]="order"
      [orderVerboseName]="siteSettingsService.orderVerboseName"
      [orderVerbosePluralName]="siteSettingsService.orderVerboseNamePlural"
      [collapsed]="orders.length != 1"
      [siteSettings]="siteSettingsService.siteSettings"
      [useNextime]="siteSettingsService.useNextime"
      [nextimeToken]="siteSettingsService.nextimeToken"
      [nextimeSiteId]="siteSettingsService.nextimeSiteId"
      [closestDeliveryDateUtc]="order.closestNextDeliveryDate"
      (pause)="openDialog('pause-order', order.id)"
      (resume)="onResume($event)"
      (detail)="onDetail($event, order.closestNextOccurrenceDate)"
      (cancel)="openDialog('cancel-order', order.id)"
      (reschedule)="openDialog('reschedule-order', order.id)"
      (rescheduleAndResume)="openDialog('reschedule-order', order.id, { resumeAfter: true })"
      (updatePayment)="openDialog('update-payment', order.id)"
      (processOrder)="onProcessOrder(order)"
      (deliveryDateUpdate)="deliveryService.onDeliveryDateUpdate($event, order)"
      (processNowDeliveryDateFetched)="updateClosestDeliveryDate($event, order)"
    />
  }
}
