<div class="add-product-list-wrapper">

  <div class="navigation">
    <md-icon-button (click)="goBack()" slot="trailing-icon">
      <md-icon>arrow_back</md-icon>
    </md-icon-button>
    <span> Back </span>
  </div>

  <div class="products-search">
    <span class="dialog-title"> Add Products to Your {{ siteSettingsService.orderVerboseName }}</span>

    <div class="search-products-field">
      <md-outlined-text-field
        [value]="searchTerm" type="search"
        (input)="onSearchInput($event)" (keydown.enter)="search($event)"
        placeholder="Search products">
        <md-icon-button (click)="search($event)" slot="trailing-icon">
          <md-icon>search</md-icon>
        </md-icon-button>
      </md-outlined-text-field>
    </div>

    @if (products$ | async; as products) {
      <div>
        @for (product of products; track product.id) {
          <div class="product-list-item">
            <div class="product-image" [ngStyle]="getItemBackgroundImage(product)"></div>
            <div class="product-title"><span> {{ product.title }} </span></div>
            <div class="product-availability"><span>{{ product.availability == 'InStock' ? 'In Stock' : ''}}</span></div>
            <div class="add-product">
              <md-icon-button (click)="operateProduct(product)">
                <md-icon>add</md-icon>
              </md-icon-button>
            </div>
          </div>
          <div class="separator"></div>
        }
      </div>
    }

  </div>
</div>
