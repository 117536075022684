import '@material/web/button/filled-button';
import '@material/web/button/outlined-button';

import { Overlay } from '@angular/cdk/overlay';
import { CommonModule, NgClass, NgStyle } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';

import { NextimeWrapperComponent } from '../nextime-wrapper/nextime-wrapper.component';
import { DateFormatPipe } from '../pipes/date-format-pipe';
import { ProcessNowDialogComponent } from '../process-now-dialog/process-now-dialog.component';
import { ResumeDialogComponent } from '../resume-dialog/resume-dialog.component';
import { QPilotProduct } from '../types/product';
import {
  getAddressDisplayName,
  getChangeLimitDate,
  getFailedReason,
  getStatusDisplayName,
  OrderStatus,
  QPilotScheduledOrder,
  QPilotScheduledOrderTotals,
} from '../types/scheduled-order';
import { QPilotSiteSettings } from './../types/site';
import { ScheduledOrderPaymentMethodComponent } from '../scheduled-order-payment-method/scheduled-order-payment-method.component';
import { RouterModule } from '@angular/router';
import { NgImageSliderModule } from 'ng-image-slider';

const STATUS_ICON_MAP = {
  active: 'cycle',
  failed: 'error',
  paused: 'autopause',
  processing: 'pending',
  queued: 'pending',
  deleted: 'cycle',
};

export interface ImageObject {
  image: string;
  thumbImage: string;
  title?: string;
  price?: string;
}

@Component({
  selector: 'lib-scheduled-order-card',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    HttpClientModule,
    NgStyle,
    NgClass,
    DateFormatPipe,
    ProcessNowDialogComponent,
    ResumeDialogComponent,
    NextimeWrapperComponent,
    ScheduledOrderPaymentMethodComponent,
    RouterModule,
    NgImageSliderModule,
  ],
  providers: [DateFormatPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scheduled-order-card.component.html',
  styleUrl: './scheduled-order-card.component.scss',
})
export class ScheduledOrderCardComponent implements OnChanges {
  @Input() order: QPilotScheduledOrder & {
    getOrderTotals: () => Observable<QPilotScheduledOrderTotals>;
    closestNextOccurrenceDate?: string;
  };
  @Input() orderVerboseName: string = 'Scheduled Order';
  @Input() orderVerbosePluralName: string = 'Scheduled Orders';
  @Input() collapsed = true;
  @Input() useNextime: boolean;
  @Input() nextimeToken: string;
  @Input() nextimeSiteId: number;
  @Input() attachNextimeDialogTo: string | undefined;
  @Input() siteSettings: QPilotSiteSettings;
  @Input() closestDeliveryDateUtc: string | undefined;

  @Output() processOrder = new EventEmitter();
  @Output() detail = new EventEmitter();
  @Output() pause = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() resume = new EventEmitter();
  @Output() reschedule = new EventEmitter();
  @Output() rescheduleAndResume = new EventEmitter();
  @Output() clickResume = new EventEmitter();
  @Output() updatePayment = new EventEmitter();
  @Output() deliveryDateUpdate = new EventEmitter();
  @Output() processNowDeliveryDateFetched = new EventEmitter();

  @ViewChild(NextimeWrapperComponent) nextime: NextimeWrapperComponent;

  statusIcon = '';
  thumbnailStyle: { [property: string]: string };
  itemsCount: number;
  orderStatus: OrderStatus;
  changeLimitDate: Date;
  addressDisplayName: string;
  statusDisplayName: string;
  cardCollapsedDisplayName: string;
  cardExpandedDisplayName: string;
  orderTotals$: Observable<QPilotScheduledOrderTotals>;
  orderDescription: string;
  dialogRef: MatDialogRef<any>;

  imageObject: ImageObject[] = [];

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private dateFormatPipe: DateFormatPipe,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.itemsCount =
      this.order.scheduledOrderItems?.reduce(
        (a, b) => a + (b.quantity ?? 0),
        0
      ) ?? 0;

    this.imageObject = this.getProductImages();
  }

  ngOnChanges() {
    this.orderStatus = this.order.status;
    this.statusIcon = STATUS_ICON_MAP[this.orderStatus];
    this.thumbnailStyle = { backgroundImage: `url(${this.getThumbnail()})` };

    this.changeLimitDate = getChangeLimitDate(this.order);
    this.addressDisplayName = getAddressDisplayName(this.order);
    this.statusDisplayName =
      this.orderStatus === 'failed'
        ? `${getStatusDisplayName(this.order)} (${getFailedReason(
            this.order.processingErrorCode
          )})`
        : getStatusDisplayName(this.order);
    this.cardCollapsedDisplayName = this.getCardCollapsedDisplayName();
    this.cardExpandedDisplayName = this.getCardExpandedDisplayName();
  }

  ngDoCheck() {
    this.orderTotals$ = this.order.getOrderTotals();
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

  getChangeLimitDate(order: QPilotScheduledOrder) {
    return getChangeLimitDate(order);
  }

  onResume() {
    if (!this.isResumable) return;
    this.resume.emit({
      orderId: this.order.id,
    });
  }

  onPause() {
    if (!this.isActive) return;
    this.pause.emit({
      orderId: this.order.id,
    });
  }

  onCancel() {
    this.cancel.emit({
      orderId: this.order.id,
    });
  }

  onDetail() {
    this.detail.emit({
      orderId: this.order.id,
    });
  }

  onReschedule() {
    this.reschedule.emit({
      orderId: this.order.id,
    });
  }

  onRescheduleAndResume() {
    this.rescheduleAndResume.emit({
      orderId: this.order.id,
    });
  }

  onUpdatePayment() {
    this.updatePayment.emit({
      orderId: this.order.id,
    });
  }

  onProcessOrder() {
    this.processOrder.emit({
      orderId: this.order.id,
    });
    this.dialogRef.close();
  }

  onDeliveryDateUpdate(event: Event) {
    this.deliveryDateUpdate.emit(event);
  }

  onProcessNowDeliveryDateFetched(event: Event) {
    this.processNowDeliveryDateFetched.emit(event);
  }

  async openDeliveryDateDialog() {
    await this.nextime.openDeliveryDateDialog();
  }

  openDialogWithRef(templateRef: TemplateRef<any>) {
    this.zone.run(() => {
      this.dialogRef = this.dialog.open(templateRef, {
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      });
    });
  }

  private getCardCollapsedDisplayName() {
    if (this.useNextime && this.hasDeliveryDate && this.isActive) {
      const nextDeliveryDate = `Next Delivery: ${this.dateFormatPipe.transform(
        this.order.estimatedDeliveryDate,
        'MMM d'
      )}`;
      if (this.siteSettings.isDisplayScheduledOrderId) {
        return `#${this.order.id} - ${nextDeliveryDate}`;
      }
      return nextDeliveryDate;
    }

    if (this.isProcessing) return getStatusDisplayName(this.order);
    if (this.isPaused) return `${getStatusDisplayName(this.order)}: Resume Now`;
    if (this.isFailed)
      return `${getStatusDisplayName(this.order)}: ${getFailedReason(
        this.order.processingErrorCode
      )}`;

    return this.getFormattedNextOrderDate(this.order);
  }

  private getCardExpandedDisplayName() {
    if (this.useNextime && this.hasDeliveryDate && this.isActive) {
      const nextDeliveryDate = `Next Delivery: ${this.dateFormatPipe.transform(
        this.order.estimatedDeliveryDate,
        'MMM d'
      )}`;
      if (this.siteSettings.isDisplayScheduledOrderId) {
        return `#${this.order.id} - ${nextDeliveryDate}`;
      }
      return nextDeliveryDate;
    }

    if (this.isProcessing) return `${this.orderVerboseName} is processing`;

    return this.getFormattedNextOrderDate(this.order);
  }

  private get isActive() {
    return this.orderStatus == 'active';
  }

  private get isPaused() {
    return this.orderStatus == 'paused';
  }

  private get isDeleted() {
    return this.orderStatus == 'deleted';
  }

  private get isProcessing() {
    return this.orderStatus == 'processing';
  }

  private get isFailed() {
    return this.orderStatus == 'failed';
  }

  private get isResumable() {
    return this.isPaused || this.isDeleted;
  }

  get hasDeliveryDate() {
    return !!this.order.estimatedDeliveryDate?.trim();
  }

  private getProductImageUrl(product: QPilotProduct | undefined) {
    return (
      product?.imageUrl ??
      product?.metadata?.imageUrl ??
      product?.metadata?.imageThumbUrl ??
      ''
    );
  }

  private getProductImages(): ImageObject[] {
    return (
      this.order.scheduledOrderItems?.map((item) => {
        const title = item.product?.title ?? '';
        return {
          image: this.getProductImageUrl(item.product),
          thumbImage: this.getProductImageUrl(item.product),
          title: `${title}`,
        };
      }) ?? []
    );
  }

  protected getThumbnail() {
    const item = this.order.scheduledOrderItems?.find((item) => {
      const imageUrl = this.getProductImageUrl(item.product);
      return imageUrl.trim() !== '';
    });
    return this.getProductImageUrl(item?.product) ?? '';
  }

  private getFormattedNextOrderDate({
    nextOccurrenceUtc,
  }: QPilotScheduledOrder) {
    const nextOrderDate = `Next Order Date: ${this.dateFormatPipe.transform(
      nextOccurrenceUtc,
      'MMM d'
    )}`;
    if (this.siteSettings.isDisplayScheduledOrderId) {
      return `#${this.order.id} - ${nextOrderDate}`;
    }
    return nextOrderDate;
  }
}
